import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
   MatSnackBar,
   MatSnackBarHorizontalPosition,
   MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { tranlateToLanguage } from '../../shared/helper-functions';
import { SupportedLanguages } from '../../shared/types/language.type';
import { MissingDataService } from './missing-data.service';
import { UuidType } from './uuid-type.enum';

export enum possibleTemplates {
   missingStudentId = 'missingStudentId',
   missingCertificateOfSchoolAttendance = 'missingCertificateOfSchoolAttendance',
   missingParentalAuthorization = 'missingParentalAuthorization',
}

export const missingDataOptionsByTemplate = {
   [possibleTemplates.missingStudentId]: [
      'noStudentId',
      'noStudentIdFront',
      'noStudentIdBack',
      'studentIdNotValidForPeriod',
   ],
   [possibleTemplates.missingCertificateOfSchoolAttendance]: [
      'notACertificate',
      'notValidForPeriod',
      'missingSignature',
      'issuedForOtherStudent',
   ],
   [possibleTemplates.missingParentalAuthorization]: [],
};

const emailFirstTextByTemplate = {
   [possibleTemplates.missingStudentId]: 'admin.missingDataDialog.emailFirstTextStudentId',
   [possibleTemplates.missingCertificateOfSchoolAttendance]:
      'admin.missingDataDialog.emailFirstTextCertificateOfSchoolAttendance',
   [possibleTemplates.missingParentalAuthorization]:
      'admin.missingDataDialog.emailFirstTextParentalAuthorization',
};

const emailSecondTextByTemplate = {
   [possibleTemplates.missingStudentId]: 'admin.missingDataDialog.emailSecondTextStudentId',
   [possibleTemplates.missingCertificateOfSchoolAttendance]:
      'admin.missingDataDialog.emailSecondTextCertificateOfSchoolAttendance',
   [possibleTemplates.missingParentalAuthorization]:
      'admin.missingDataDialog.emailSecondTextParentalAuthorization',
};

@Component({
   selector: 'app-missing-data-dialog',
   templateUrl: './missing-data-dialog.component.html',
   styleUrls: ['./missing-data-dialog.component.css'],
})
export class MissingDataDialogComponent {
   missingDataForm: FormGroup = new FormGroup({
      comment: new FormControl(''),
      selectedTemplate: new FormControl('missingStudentId'),
   });

   teamplateList = Object.keys(possibleTemplates).map(key => key);

   checkBoxList: string[] = [];

   horizontalPosition: MatSnackBarHorizontalPosition = 'center';

   verticalPosition: MatSnackBarVerticalPosition = 'top';

   emailFirstText: string = '';

   emailSecondText: string = '';

   constructor(
      @Inject(MAT_DIALOG_DATA)
      public data: { id: { uuid: string; type: UuidType }; language: SupportedLanguages },
      private snackBar: MatSnackBar,
      private service: MissingDataService,
      private translate: TranslateService,
      private dialogRef: MatDialogRef<MissingDataDialogComponent>,
   ) {
      this.checkBoxList = missingDataOptionsByTemplate['missingStudentId'];
      this.translateEmailTexts(possibleTemplates.missingStudentId);

      this.checkBoxList.forEach(key => {
         this.missingDataForm.addControl(key, new FormControl(false));
      });
   }

   templateChanged() {
      this.checkBoxList.forEach(key => {
         this.missingDataForm.removeControl(key);
      });
      const selectedTemplate: possibleTemplates =
         this.missingDataForm.get('selectedTemplate')?.value;
      this.translateEmailTexts(selectedTemplate);
      this.checkBoxList = missingDataOptionsByTemplate[selectedTemplate];
      this.checkBoxList.forEach(key => {
         this.missingDataForm.addControl(key, new FormControl(false));
      });
   }

   translateEmailTexts(selectedTemplate: possibleTemplates) {
      this.emailFirstText = tranlateToLanguage(
         this.translate,
         emailFirstTextByTemplate[selectedTemplate],
         this.data.language,
      );
      this.emailSecondText = tranlateToLanguage(
         this.translate,
         emailSecondTextByTemplate[selectedTemplate],
         this.data.language,
      );
   }

   onSend() {
      this.service
         .sendMissingDateEmail(this.data.id, this.data.language, this.missingDataForm.value)
         .then(res => {
            res.subscribe({
               next: () => {
                  this.snackBar.open(
                     this.translate.instant('admin.missingDataDialog.emailSent'),
                     'Ok',
                     {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 3000,
                     },
                  );
                  this.dialogRef.close();
               },
               error: () => {
                  this.snackBar.open(
                     this.translate.instant('admin.missingDataDialog.emailError'),
                     'Ok',
                     {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 3000,
                     },
                  );
               },
            });
         });
   }

   isFormValid() {
      return (
         this.checkBoxList.some(key => this.missingDataForm.get(key)?.value) ||
         this.checkBoxList.length === 0
      );
   }
}
