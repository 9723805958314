import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from 'src/app/config/routes.config';
import { removeTrailingSpaceFromObjectProps } from '../../shared/helper-functions';

@Injectable({
   providedIn: 'root',
})
export class StudiesService {
   private readonly personUrl: string;

   private readonly studiesURL: string;

   private readonly schoolURL: string;

   private readonly facultyURL: string;

   private readonly courseURL: string;

   private readonly languageURL: string;

   constructor(private httpClient: HttpClient) {
      this.personUrl = routes.person;
      this.studiesURL = routes.studies;
      this.schoolURL = routes.school;
      this.facultyURL = routes.faculty;
      this.courseURL = routes.course;
      this.languageURL = routes.languageSkill;
   }

   getPerson(uuid: string): Observable<any> {
      const url = `${this.personUrl}/${uuid}`;
      return this.httpClient.get<any>(url, {});
   }

   postStudies(params: Object): Observable<any> {
      removeTrailingSpaceFromObjectProps(params);
      return this.httpClient.post<any>(this.studiesURL, params);
   }

   patchStudies(params: Object): Observable<any> {
      removeTrailingSpaceFromObjectProps(params);
      return this.httpClient.patch<any>(this.studiesURL, params);
   }

   getSchool(search: string, lang: string): Observable<string[]> {
      return this.httpClient.get<any>(this.schoolURL, { params: { name: search, lang } });
   }

   getFaculty(search: string, lang: string): Observable<any> {
      return this.httpClient.get(this.facultyURL, { params: { name: search, lang } });
   }

   getCourse(search: string, lang: string): Observable<{ course_name: string }[]> {
      return this.httpClient.get<any>(this.courseURL, { params: { name: search, lang } });
   }

   getLanguage(search: string, lang: string): Observable<{ lang_name: string }[]> {
      return this.httpClient.get<any>(this.languageURL, {
         params: { name: search, lang },
      });
   }
}
