import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadsService } from './uploads.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'app-uploads',
   templateUrl: './uploads.component.html',
   styleUrls: ['./uploads.component.css'],
})
export class UploadsComponent implements OnDestroy, OnInit {
   destroyed$ = new Subject<void>();

   @Input() uuid!: string;

   @Input() stepper!: MatStepper;

   @Input() admin!: boolean;

   @Input() download?: boolean;

   @Input() noOfCols!: number;

   @Input() documents: { uuid: string; fileName: string }[] | undefined;

   @Input() orientation!: StepperOrientation;

   @Input() uploadUrl!: string;

   @Input() downloadUrl!: string;

   @Output() refresh = new EventEmitter();

   // eslint-disable-next-line @angular-eslint/no-input-rename
   @Input('loadingSpinner') loadingSpinner$!: Subject<boolean>;

   form = new FormGroup({
      file: new FormControl(),
      type: new FormControl('', Validators.required),
   });

   file?: File;

   displayedColumns: string[] = ['docType', 'fileName'];

   fileTooLarge = false;

   constructor(
      private service: UploadsService,
      private snackBar: MatSnackBar,
      private translate: TranslateService,
   ) {}

   ngOnInit(): void {
      if (this.admin && this.download) {
         this.displayedColumns.push('download');
      }
      if (!this.admin && !localStorage.getItem('jwt')) {
         this.displayedColumns.push('delete');
      }
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   onFileSelected() {
      const inputNode: any = document.querySelector('#file');
      this.fileTooLarge = false;
      if (typeof FileReader !== 'undefined') {
         const reader = new FileReader();

         reader.readAsArrayBuffer(inputNode.files[0]);

         if (inputNode.files[0].size < 10 * 1024 * 1024) {
            this.file = inputNode.files[0];
         } else {
            this.fileTooLarge = true;
         }
      }
   }

   onSubmit() {
      if (this.file) {
         this.loadingSpinner$.next(true);
         this.service
            .upload(this.uploadUrl, this.uuid, this.file, this.form.get('type')?.value)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
               () => {
                  this.file = undefined;
                  this.form.reset();
                  this.refresh.emit();
                  this.loadingSpinner$.next(false);
               },
               () => {
                  this.snackBar.open(this.translate.instant('fileUploadError'), 'OK', {
                     duration: 5000,
                  });
                  this.loadingSpinner$.next(false);
               },
            );
      }
   }

   onDownload(uuid: string, filename: string) {
      this.service
         .downloadDoc(this.downloadUrl, uuid)
         .pipe(takeUntil(this.destroyed$))
         .subscribe(r => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(r);
            a.href = objectUrl;
            a.download = filename;
            a.click();
            URL.revokeObjectURL(objectUrl);
         });
   }

   onDelete(uuid: string) {
      this.service
         .deleteDoc(this.downloadUrl, uuid)
         .pipe(takeUntil(this.destroyed$))
         .subscribe(() => {
            this.refresh.emit();
         });
   }
}
