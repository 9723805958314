<div style="margin-left: 10vw; margin-top: 10vh; width: 80vw">
   <button
      mat-flat-button
      class="category"
      (click)="onFilterStatus('waitSupplements')"
      style="margin-right: 2rem"
      [ngClass]="currentFilter === 'waitSupplements' ? 'mat-primary' : 'mat-accent'"
   >
      {{ 'supplements.waitSup' | translate }}
   </button>
   <button
      mat-flat-button
      class="category"
      (click)="onFilterStatus('waitValidation')"
      style="margin-right: 2rem"
      [ngClass]="currentFilter === 'waitValidation' ? 'mat-primary' : 'mat-accent'"
   >
      {{ 'supplements.waitVal' | translate }}
   </button>

   <button
      mat-flat-button
      class="category"
      (click)="onFilterStatus('revSupplements')"
      style="margin-right: 2rem"
      [ngClass]="currentFilter === 'revSupplements' ? 'mat-primary' : 'mat-accent'"
   >
      {{ 'supplements.revSup' | translate }}
   </button>

   <button
      mat-flat-button
      class="category"
      (click)="onFilterStatus('revValidation')"
      style="margin-right: 2rem"
      [ngClass]="currentFilter === 'revValidation' ? 'mat-primary' : 'mat-accent'"
   >
      {{ 'supplements.revVal' | translate }}
   </button>

   <button
      mat-flat-button
      class="category"
      (click)="onFilterStatus('synced')"
      style="margin-right: 2rem"
      [ngClass]="currentFilter === 'synced' ? 'mat-primary' : 'mat-accent'"
   >
      {{ 'supplements.synced' | translate }}
   </button>
   <button
      mat-flat-button
      class="category"
      (click)="onFilterStatus('trash')"
      style="margin-right: 2rem"
      [ngClass]="currentFilter === 'trash' ? 'mat-primary' : 'mat-accent'"
   >
      {{ 'supplements.trash' | translate }}
   </button>
</div>

<form [formGroup]="filterForm">
   <mat-form-field appearance="standard" style="margin-left: 10vw; width: 80vw">
      <mat-label>{{ 'admin.filter' | translate }}</mat-label>
      <input
         matInput
         (keyup)="applyFilter($event)"
         placeholder="{{ 'admin.filter' | translate }}"
         #input
         formControlName="filter"
      />
   </mat-form-field>
</form>

<div class="mat-elevation-z8" style="margin-bottom: 10vh">
   <table
      mat-table
      [dataSource]="dataSource"
      matSort
      multiTemplateDataRows
      (matSortChange)="announceSortChange($event)"
   >
      <ng-container matColumnDef="uuid">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
         <td mat-cell *matCellDef="let element">{{ element.id ? element.id : '' }} </td>
      </ng-container>
      <ng-container matColumnDef="lastname">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'admin.lastname' | translate
         }}</th>
         <td mat-cell *matCellDef="let element">{{ element.name ? element.name?.surname : '' }}</td>
      </ng-container>
      <ng-container matColumnDef="firstname">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'admin.firstname' | translate
         }}</th>
         <td mat-cell *matCellDef="let element">{{
            element.name ? element.name?.firstName : ''
         }}</td>
      </ng-container>
      <ng-container matColumnDef="dateOfBirth">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'admin.birthDate' | translate
         }}</th>
         <td mat-cell *matCellDef="let element">{{
            element.dateOfBirth ? displayBod(element.dateOfBirth) : ''
         }}</td>
      </ng-container>
      <ng-container matColumnDef="taxNumber">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'admin.tax' | translate }}</th>
         <td mat-cell *matCellDef="let element">{{
            element.taxNumber ? element.taxNumber : ''
         }}</td>
      </ng-container>
      <ng-container matColumnDef="email">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'admin.email' | translate }}</th>
         <td mat-cell *matCellDef="let element">{{ element.email ? element.email : '' }}</td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'admin.create-date' | translate
         }}</th>
         <td mat-cell *matCellDef="let element">{{
            element.createdAt ? convertDateAndTime(element.createdAt) : ''
         }}</td>
      </ng-container>
      <ng-container matColumnDef="syncDate">
         <th mat-header-cell *matHeaderCellDef mat-sort-header>{{
            'admin.syncDate' | translate
         }}</th>
         <td mat-cell *matCellDef="let element">{{
            element.syncDate ? convertDateAndTime(element.syncDate) : '-'
         }}</td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
         <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div
               class="example-element-detail"
               [@detailExpand]="
                  expandedElement !== null && expandedElement?.uuid === element.uuid
                     ? 'expanded'
                     : 'collapsed'
               "
               align="center"
            >
               <div
                  *ngIf="expandedElement?.details"
                  class="example-element-description"
                  style="width: 100%"
               >
                  <mat-grid-list cols="1" rowHeight="3rem" style="width: 100%">
                     <mat-grid-tile
                        *ngIf="expandedElement?.details?.enrollmentInProgress"
                        width="100%"
                     >
                        <b style="color: red"> {{ 'admin.enrollmentInProgress' | translate }} </b>
                     </mat-grid-tile>
                  </mat-grid-list>

                  <mat-grid-list cols="4" rowHeight="3rem" style="width: 100%">
                     <mat-grid-tile>
                        <p>
                           {{ 'admin.status' | translate }}:
                           <b>{{ expandedElement?.status ? expandedElement?.status : '' }}</b>
                        </p>
                     </mat-grid-tile>
                  </mat-grid-list>
                  <mat-grid-list cols="3" rowHeight="14rem" style="width: 100%">
                     <mat-grid-tile>
                        <div>
                           <p>{{ 'admin.name' | translate }}:</p>
                           <p
                              >{{ 'admin.lastname' | translate }}:
                              <b>{{ expandedElement?.details?.vezeteknev }}</b></p
                           >
                           <p
                              >{{ 'admin.firstname' | translate }}:
                              <b>{{ expandedElement?.details?.utonev1 }}</b></p
                           >
                           <p
                              >{{ 'admin.middlename' | translate }}:
                              <b>{{ expandedElement?.details?.utonev2 }}</b></p
                           >
                        </div>
                     </mat-grid-tile>

                     <mat-grid-tile>
                        <div>
                           <p>{{ 'admin.mother' | translate }}:</p>
                           <p
                              >{{ 'admin.lastname' | translate }}:
                              <b>{{ expandedElement?.details?.anyjaneve_vezetek }}</b></p
                           >
                           <p
                              >{{ 'admin.firstname' | translate }}:
                              <b>{{ expandedElement?.details?.anyjaneve_uto1 }}</b></p
                           >
                           <p
                              >{{ 'admin.middlename' | translate }}:
                              <b>{{ expandedElement?.details?.anyjaneve_uto2 }}</b></p
                           >
                        </div>
                     </mat-grid-tile>
                     <mat-grid-tile>
                        <div>
                           <p>
                              {{ 'admin.tax' | translate }}:
                              <b>{{ expandedElement?.details?.adoszam }}</b>
                           </p>
                           <p>
                              {{ 'admin.email' | translate }}:
                              <a [href]="'mailto:' + expandedElement?.details?.email">
                                 <b>{{ expandedElement?.details?.email }}</b>
                              </a>
                           </p>
                           <p>
                              {{ 'admin.birth-date' | translate }}:
                              <b>{{
                                 expandedElement?.details?.szuldatum
                                    ? convertDate('' + expandedElement?.details?.szuldatum)
                                    : ''
                              }}</b>
                           </p>
                           <p>
                              {{ 'admin.area' | translate }}:
                              <b>{{
                                 expandedElement?.details?.areaName
                                    ? expandedElement?.details?.areaName
                                    : expandedElement?.details?.teruletid
                              }}</b>
                           </p>
                        </div>
                     </mat-grid-tile>
                  </mat-grid-list>

                  <mat-grid-list cols="2" rowHeight="6rem" style="width: 100%">
                     <ng-container
                        [formGroup]="updateForm"
                        *ngIf="expandedElement !== null && expandedElement?.uuid === element.uuid"
                     >
                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label class="label-color">{{
                                 'admin.valid-date' | translate
                              }}</mat-label>
                              <input
                                 matInput
                                 required
                                 [matDatepicker]="picker"
                                 formControlName="membershipValidDate"
                                 (change)="membershipValidDateChange($event)"
                              />
                              <mat-datepicker-toggle
                                 matSuffix
                                 [for]="picker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker [touchUi]="touchUi$ | async" #picker></mat-datepicker>
                           </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label>{{ 'data.status' | translate }}</mat-label>
                              <select matNativeControl formControlName="studentStatus">
                                 <option value="ACTIVE">{{ 'data.active' | translate }}</option>
                                 <option value="PASSIVE">{{ 'data.passive' | translate }}</option>
                              </select>
                           </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label>{{ 'studies.end-date' | translate }}</mat-label>
                              <select matNativeControl required formControlName="graduationDate">
                                 <option
                                    [value]="graduation"
                                    *ngFor="let graduation of graduations"
                                 >
                                    {{ graduation }}
                                 </option>
                              </select>
                           </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label class="label-color">{{
                                 'studies.name' | translate
                              }}</mat-label>
                              <input
                                 type="text"
                                 matInput
                                 required
                                 [placeholder]="'placeholder.institution' | translate"
                                 [matAutocomplete]="institution"
                                 formControlName="institution"
                                 #institutionInput="matInput"
                                 (change)="institutionChanged($event)"
                              />
                              <mat-progress-spinner
                                 *ngIf="institutionSpinner$ | async"
                                 matSuffix
                                 [diameter]="20"
                                 mode="indeterminate"
                              ></mat-progress-spinner>

                              <ng-template #institutionHelper>
                                 <p>{{ 'studies.institution-helper' | translate }}</p>
                              </ng-template>
                              <mat-autocomplete
                                 autoActiveFirstOption
                                 #institution="matAutocomplete"
                              >
                                 <mat-option
                                    *ngFor="let institution of institutions$ | async"
                                    [value]="institution"
                                 >
                                    {{ institution }}
                                 </mat-option>
                              </mat-autocomplete>
                              <mat-error
                                 >{{ 'fill' | translate
                                 }}{{ 'studies.name' | translate }}</mat-error
                              >
                           </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label class="label-color">{{
                                 'data.email' | translate
                              }}</mat-label>
                              <input
                                 type="email"
                                 matInput
                                 required
                                 formControlName="email"
                                 maxlength="100"
                              />
                              <mat-error
                                 >{{ 'fill' | translate }}{{ 'data.email' | translate }}</mat-error
                              >
                           </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label class="label-color"
                                 >{{ 'studies.course' | translate }} 1</mat-label
                              >
                              <input
                                 type="text"
                                 matInput
                                 [placeholder]="'placeholder.course' | translate"
                                 [matAutocomplete]="course1"
                                 formControlName="course1"
                                 #course1Input="matInput"
                                 (change)="course1Changed($event)"
                              />
                              <mat-progress-spinner
                                 *ngIf="course1Spinner$ | async"
                                 matSuffix
                                 [diameter]="20"
                                 mode="indeterminate"
                              ></mat-progress-spinner>

                              <mat-autocomplete autoActiveFirstOption #course1="matAutocomplete">
                                 <mat-option
                                    *ngFor="let course1 of courses1$ | async"
                                    [value]="course1"
                                 >
                                    {{ course1 }}
                                 </mat-option>
                              </mat-autocomplete>
                           </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label class="label-color"
                                 >{{ 'studies.course' | translate }} 2</mat-label
                              >
                              <input
                                 type="text"
                                 matInput
                                 [placeholder]="'placeholder.course' | translate"
                                 [matAutocomplete]="course2"
                                 formControlName="course2"
                                 #courseInput="matInput"
                                 (change)="course2Changed($event)"
                              />
                              <mat-progress-spinner
                                 *ngIf="course2Spinner$ | async"
                                 matSuffix
                                 [diameter]="20"
                                 mode="indeterminate"
                              ></mat-progress-spinner>

                              <mat-autocomplete autoActiveFirstOption #course2="matAutocomplete">
                                 <mat-option
                                    *ngFor="let course of courses2$ | async"
                                    [value]="course"
                                 >
                                    {{ course }}
                                 </mat-option>
                              </mat-autocomplete>
                           </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label class="label-color"
                                 >{{ 'studies.course' | translate }} 3</mat-label
                              >
                              <input
                                 type="text"
                                 matInput
                                 [placeholder]="'placeholder.course' | translate"
                                 [matAutocomplete]="course3"
                                 formControlName="course3"
                                 #courseInput="matInput"
                                 (change)="course3Changed($event)"
                              />
                              <mat-progress-spinner
                                 *ngIf="course3Spinner$ | async"
                                 matSuffix
                                 [diameter]="20"
                                 mode="indeterminate"
                              ></mat-progress-spinner>

                              <mat-autocomplete autoActiveFirstOption #course3="matAutocomplete">
                                 <mat-option
                                    *ngFor="let course of courses3$ | async"
                                    [value]="course"
                                 >
                                    {{ course }}
                                 </mat-option>
                              </mat-autocomplete>
                           </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label>{{ 'studies.year' | translate }}</mat-label>
                              <select matNativeControl formControlName="grade">
                                 <option [value]="grade" *ngFor="let grade of grades">
                                    {{ PersonGrade[grade] }}
                                 </option>
                              </select>

                              <mat-error
                                 >{{ 'fill' | translate
                                 }}{{ 'studies.year' | translate }}</mat-error
                              >
                           </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label class="label-color">{{
                                 'studies.fac' | translate
                              }}</mat-label>
                              <input
                                 type="text"
                                 matInput
                                 [placeholder]="'placeholder.faculty' | translate"
                                 [matAutocomplete]="faculty"
                                 formControlName="faculty"
                                 #facultyInput="matInput"
                                 (change)="facultyChanged($event)"
                              />
                              <mat-progress-spinner
                                 *ngIf="facultySpinner$ | async"
                                 matSuffix
                                 [diameter]="20"
                                 mode="indeterminate"
                              ></mat-progress-spinner>
                              <button
                                 mat-icon-button
                                 matSuffix
                                 appHelperIcon
                                 [matInput]="facultyInput"
                                 [helperContent]="facultyHelper"
                                 [alwaysVisible]="true"
                                 ><mat-icon>info_outlined</mat-icon></button
                              >
                              <ng-template #facultyHelper>
                                 <p>{{ 'studies.faculty-helper' | translate }}</p>
                              </ng-template>
                              <mat-autocomplete autoActiveFirstOption #faculty="matAutocomplete">
                                 <mat-option
                                    *ngFor="let faculty of faculties$ | async"
                                    [value]="faculty"
                                 >
                                    {{ faculty }}
                                 </mat-option>
                              </mat-autocomplete>
                           </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile>
                           <mat-form-field class="form-field-half" appearance="fill">
                              <mat-label class="label-color">{{
                                 'data.studentIdNumber' | translate
                              }}</mat-label>
                              <input
                                 matInput
                                 formControlName="studentIdNumber"
                                 [placeholder]="'1234567890'"
                                 required
                                 #studentIdNumberInput="matInput"
                              />
                              <mat-error
                                 class="mat-error"
                                 *ngIf="updateForm.errors?.['studentIdNumber'] === 'illegal_date'"
                                 >{{ 'data.errors.studentId.illegal_date' | translate }}</mat-error
                              >
                              <mat-error
                                 class="mat-error"
                                 *ngIf="updateForm.errors?.['studentIdNumber'] === 'range error'"
                                 >{{ 'data.errors.studentId.range' | translate }}</mat-error
                              >
                              <mat-error
                                 >{{ 'fill' | translate
                                 }}{{ 'data.studentIdNumber' | translate }}</mat-error
                              >
                           </mat-form-field>
                        </mat-grid-tile>

                        <mat-grid-tile>
                           <button
                              mat-flat-button
                              color="green"
                              (click)="onUpdate()"
                              [disabled]="updateForm.invalid || isUpdateFormDisabled"
                           >
                              {{ 'admin.update' | translate }}
                           </button>
                        </mat-grid-tile>

                        <mat-grid-tile *ngIf="currentFilter !== Boxes.synced">
                           <button
                              mat-flat-button
                              color="primary"
                              (click)="onSubmit()"
                              [disabled]="
                                 updateForm.invalid ||
                                 !hasResource('supplements.sync') ||
                                 !this.formSynced
                              "
                           >
                              {{ 'admin.submit' | translate }}
                           </button>
                        </mat-grid-tile>

                        <mat-grid-tile *ngIf="isMoveToRevisedShown">
                           <button
                              mat-flat-button
                              color="secondary"
                              (click)="onRevised()"
                              [disabled]="updateForm.invalid || isMoveToRevisedDisabled"
                           >
                              {{ 'supplements.checked' | translate }}
                           </button>
                        </mat-grid-tile>
                        <mat-grid-tile *ngIf="isSendMissingDataEmailShown">
                           <button
                              color="accent"
                              mat-flat-button
                              (click)="onMissingDataClicked()"
                              [disabled]="isSendMissingDataEmailDisabled || !this.formSynced"
                           >
                              {{ 'admin.missingData' | translate }}
                           </button>
                        </mat-grid-tile>
                        <mat-grid-tile *ngIf="isMoveToTrashShown">
                           <button
                              mat-flat-button
                              color="warn"
                              (click)="onTrash()"
                              [disabled]="isMoveToTrashDisabled"
                           >
                              {{ 'admin.delete' | translate }}
                           </button>
                        </mat-grid-tile>
                     </ng-container>
                  </mat-grid-list>
                  <app-uploads
                     *ngIf="expandedElement !== null && expandedElement?.uuid === element.uuid"
                     [uuid]="'' + expandedElement?.uuid"
                     [noOfCols]="2"
                     [orientation]="'horizontal'"
                     [admin]="true"
                     [download]="hasResource('supplements.download')"
                     [downloadUrl]="downloadUrl"
                     [documents]="expandedElement?.documents || []"
                     (refresh)="onRefresh()"
                  ></app-uploads>
               </div>
               <div
                  *ngIf="expandedElement !== null && expandedElement?.details === null"
                  class="example-element-description"
                  style="width: 100%"
               >
                  A Depó progitól nem sikerült elkérni az adatokat!
               </div>
            </div>
         </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
         mat-row
         *matRowDef="let element; columns: displayedColumns"
         class="example-element-row"
         style="height: 2rem !important"
         [class.example-expanded-row]="expandedElement === element"
         (click)="onExpand(element)"
      >
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      <tr class="mat-row" *matNoDataRow>
         <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            {{ 'admin.no-data' | translate }}
         </td>
      </tr>
   </table>
   <mat-paginator [pageSizeOptions]="[50]" showFirstLastButtons> </mat-paginator>
</div>
