import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

const CustomBreakpoints = {
   mobileM: '(max-width: 375px)',
} as const;

type CustomBreakpointName = keyof typeof CustomBreakpoints;

export type BreakpointName =
   | CustomBreakpointName
   | 'phonePortrait'
   | 'phoneLandscape'
   | 'tabletPortrait'
   | 'tabletLandscape'
   | 'webPortrait'
   | 'webLandscape';

@Injectable({
   providedIn: 'root',
})
export class MediaService {
   private screen = new BehaviorSubject<BreakpointName>('webPortrait');

   public screen$;

   private canHover = new BehaviorSubject<boolean>(false);

   public canHover$;

   constructor(private breakpointObserver: BreakpointObserver) {
      this.breakpointObserver
         .observe([
            CustomBreakpoints.mobileM,
            Breakpoints.Handset,
            Breakpoints.Tablet,
            Breakpoints.Web,
         ])
         .pipe(
            map((state: BreakpointState): BreakpointName => {
               if (state.breakpoints[CustomBreakpoints.mobileM]) {
                  return 'mobileM';
               }
               if (state.breakpoints[Breakpoints.HandsetPortrait]) {
                  return 'phonePortrait';
               }
               if (state.breakpoints[Breakpoints.HandsetLandscape]) {
                  return 'phoneLandscape';
               }
               if (state.breakpoints[Breakpoints.TabletPortrait]) {
                  return 'tabletPortrait';
               }
               if (state.breakpoints[Breakpoints.TabletLandscape]) {
                  return 'tabletLandscape';
               }
               if (state.breakpoints[Breakpoints.WebPortrait]) {
                  return 'webPortrait';
               }
               if (state.breakpoints[Breakpoints.WebLandscape]) {
                  return 'webLandscape';
               }
               return 'webPortrait'; // default
            }),
         )
         .subscribe(this.screen);

      this.screen$ = this.screen.asObservable();

      this.breakpointObserver
         .observe('(hover: hover)')
         .pipe(map(({ matches }) => matches))
         .subscribe(this.canHover);

      this.canHover$ = this.canHover.asObservable();
   }
}
