import { StepperOrientation, StepperSelectionEvent } from '@angular/cdk/stepper';
import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { routes } from '../config/routes.config';
import { PersonModel } from '../models/person.model';
import { depoLangToSupportedLang, supportedLangToDepoLang } from '../shared/helper-functions';
import { BreakpointName, MediaService } from '../shared/services/media.service';
import { SupportedLanguages } from '../shared/types/language.type';
import { UploadConfimationFialogComponent } from '../shared/upload-confirmation/upload-confirmation.component';
import { RegistersService } from './register.service';

type Media = {
   noOfCols: 1 | 2;
   orientation: StepperOrientation;
};

@Component({
   selector: 'app-register',
   templateUrl: './register.component.html',
   styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit, OnDestroy {
   destroyed$ = new ReplaySubject<void>(1);

   uuid: string = '';

   user: PersonModel | undefined;

   media$ = new BehaviorSubject<Media>({ noOfCols: 2, orientation: 'horizontal' });

   icons = [
      'person',
      'person_pin',
      'school',
      'attach_file',
      'cloud_upload',
      'done_outline',
      'attach_money',
   ];

   needToPay = false;

   stepper!: MatStepper;

   isAnimationDisabled = false;

   isLinear = true;

   @ViewChild('stepper', { read: MatStepper })
   private set _stepper(stepper: MatStepper) {
      if (stepper) {
         this.stepper = stepper;
         if (this.needToPay) {
            this.loadPaymentImage(() => {
               this.stepper.selectedIndex = 6;
               this.stepper.steps.forEach(step => {
                  step.editable = false;
                  step.interacted = false;
               });
               this.isAnimationDisabled = false;
               this.isLinear = true;
            });
         }
      }
   }

   personalDataCompleted$ = new BehaviorSubject<boolean>(false);

   contactInfoCompleted$ = new BehaviorSubject<boolean>(false);

   studiesCompleted$ = new BehaviorSubject<boolean>(false);

   othersCompleted$ = new BehaviorSubject<boolean>(false);

   uploadsCompleted$ = new BehaviorSubject<boolean>(false);

   entryCompleted$ = new BehaviorSubject<boolean>(false);

   paymentCompleted$ = new BehaviorSubject<boolean>(false);

   uploadSpinner$ = new Subject<boolean>();

   static formLang: SupportedLanguages;

   popupShown = false;

   uploadUrl = routes.upload;

   downloadUrl = routes.documents;

   constructor(
      private route: ActivatedRoute,
      private router: Router,
      private registerService: RegistersService,
      private mediaService: MediaService,
      public translate: TranslateService,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
   ) {
      this.mediaService.screen$
         .pipe(
            takeUntil(this.destroyed$),
            map<BreakpointName, Media>(screen => {
               const small =
                  screen === 'mobileM' ||
                  screen === 'phoneLandscape' ||
                  screen === 'phonePortrait' ||
                  screen === 'tabletPortrait';
               return {
                  noOfCols: small ? 1 : 2,
                  orientation: small ? 'vertical' : 'horizontal',
               };
            }),
         )
         .subscribe(this.media$);
   }

   ngOnInit() {
      RegisterComponent.formLang = AppComponent.currentLang;
      this.route.queryParamMap.subscribe(param => {
         if (param.get('uuid')) {
            this.needToPay = !!param.has('pay');
            this.uuid = param.get('uuid') + '';
            localStorage.setItem('uuid', this.uuid);
            if (this.needToPay) {
               this.isAnimationDisabled = true;
               this.isLinear = false;
               this.user = undefined;
            } else {
               this.getUser(this.uuid);
            }
         } else {
            const localuuid = localStorage.getItem('uuid');
            if (localuuid) {
               this.uuid = localuuid;
               this.getUser(this.uuid);
            } else {
               this.getUser();
            }
         }
      });
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   getUser(uuid?: string) {
      this.registerService
         .getPerson(uuid, supportedLangToDepoLang(AppComponent.currentLang))
         .pipe(takeUntil(this.destroyed$))
         .subscribe({
            next: res => {
               this.user = res;
               if (this.user && AppComponent.homeFormData?.dateOfBirth) {
                  this.user.dateOfBirth = formatDate(
                     AppComponent.homeFormData?.dateOfBirth,
                     'yyyy. MM. dd',
                     'en',
                  );
                  this.user.taxNumber = AppComponent.homeFormData?.taxNumber;
               }
               RegisterComponent.formLang =
                  depoLangToSupportedLang(this.user?.language) || AppComponent.currentLang;
               this.uuid = res.uuid;
               this.onAddUUID(this.uuid);
            },
            error: () => {
               this.snackBar.open(this.translate.instant('generalError'), 'OK', { duration: 5000 });
            },
         });
   }

   onAddUUID(uuid: string) {
      localStorage.setItem('uuid', uuid);
      this.router.navigate([], {
         relativeTo: this.route,
         queryParams: {
            uuid: uuid,
         },
         queryParamsHandling: 'merge',
      });
   }

   onRefresh() {
      this.getUser(this.uuid);
   }

   paymentAnimationDone = false;

   animationDone() {
      const stepId = this.stepper._getStepLabelId(this.stepper.selectedIndex);
      const stepElement = document.getElementById(stepId);
      if (stepElement) {
         stepElement.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
      }
      this.paymentAnimationDone = this.stepper.selectedIndex === 6;
   }

   selectionChange(event: StepperSelectionEvent) {
      setTimeout(() => {
         event.selectedStep.completed = false;
         if (event.previouslySelectedIndex < event.selectedIndex) {
            event.previouslySelectedStep.completed = true;
         }
      });
   }

   private loadPaymentImage(cb: () => void) {
      const paymentImage = new Image();
      paymentImage.src = 'assets/img/card-800.png';
      paymentImage.onload = cb;
      paymentImage.onerror = cb;
   }

   checkPayment() {
      this.registerService
         .getPaymentStatus(this.uuid)
         .pipe(takeUntil(this.destroyed$))
         .subscribe(() => {
            this.registerService
               .getPerson(this.uuid, supportedLangToDepoLang(AppComponent.currentLang))
               .pipe(takeUntil(this.destroyed$))
               .subscribe(user => {
                  this.user = user;
                  if (this.user?.paymentStatus === 'VIDEKI_TAG') {
                     this.router.navigateByUrl('countryside');
                  }
               });
         });
   }

   onNext() {
      if (!this.popupShown) {
         this.popupShown = true;
         const dialogConfig = new MatDialogConfig();

         dialogConfig.disableClose = true;
         dialogConfig.autoFocus = true;

         const dialogRef = this.dialog.open(UploadConfimationFialogComponent, dialogConfig);
         dialogRef.afterClosed().subscribe(data => {
            if (data) {
               this.uploadsCompleted$.next(true);
               setTimeout(() => {
                  this.uploadsCompleted$.next(false);
                  this.stepper.next();
               });
            }
         });
      } else {
         this.uploadsCompleted$.next(true);
         setTimeout(() => {
            this.uploadsCompleted$.next(false);
            this.stepper.next();
         });
      }
   }

   onBack() {
      if (this.stepper) {
         this.stepper.previous();
      }
   }
}
