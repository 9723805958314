import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from 'src/app/config/routes.config';
import { removeTrailingSpaceFromObjectProps } from '../../shared/helper-functions';

@Injectable({
   providedIn: 'root',
})
export class OthersService {
   private readonly questionsUrl: string;

   private readonly questionAnswerUrl: string;

   constructor(private httpClient: HttpClient) {
      this.questionsUrl = routes.questions;
      this.questionAnswerUrl = routes.questionAnswer;
   }

   getAnswers(uuid: string): Observable<{ question: string; answer: string }[]> {
      return this.httpClient.get<any>(this.questionAnswerUrl, {
         params: { personUuid: uuid },
      });
   }

   postAnswer(params: Object): Observable<any> {
      removeTrailingSpaceFromObjectProps(params);
      return this.httpClient.post<any>(this.questionAnswerUrl, params);
   }

   getQuestions(): Observable<any> {
      return this.httpClient.get(this.questionsUrl);
   }
}
