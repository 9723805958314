import { Injectable } from '@angular/core';
import {
   HttpRequest,
   HttpHandler,
   HttpEvent,
   HttpInterceptor,
   HttpEventType,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from './login/login.service';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
   constructor(private service: LoginService) {}

   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      this.service.checkExpiry();
      const jwtCredentials: string | null = localStorage.getItem('jwt');
      let authorizedReq = request;
      if (jwtCredentials) {
         authorizedReq = request.clone({
            setHeaders: {
               'Cache-Control': 'no-cache',
               Pragma: 'no-cache',
               Authorization: `Bearer ${jwtCredentials}`,
            },
         });
      }

      return next.handle(authorizedReq).pipe(
         tap(event => {
            if (event.type === HttpEventType.Response && event.headers.get('jwt')) {
               // console.log(event.headers.get('jwt'));
               localStorage.setItem('jwt', event.headers.get('jwt')!);

               localStorage.setItem(
                  'exp',
                  JSON.parse(atob((event.headers.get('jwt')! as string).split('.')[1])).exp,
               );
            }
         }),
      );
   }
}
