export enum PersonGrade {
   'I' = 'I',
   'II' = 'II',
   'III' = 'III',
   'IV' = 'IV',
   'V' = 'V',
   'VI' = 'VI',
   'grade9' = '9',
   'grade10' = '10',
   'grade11' = '11',
   'grade12' = '12',
   'grade13' = '13',
   'grade14' = '14',
}
