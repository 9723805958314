<div mat-dialog-title style="text-align: left">
   <h2 mat-dialog-title>{{ 'admin.missingDataDialog.header' | translate }}</h2>
</div>
<mat-dialog-content style="width: 60vw; margin-left: 1 rem; margin-top: 1 rem; text-align: left">
   <form [formGroup]="missingDataForm">
      <mat-grid-list cols="1" rowHeight="2rem" style="width: 100%">
         <mat-grid-tile>
            <div
               ><b>{{ 'admin.missingDataDialog.templateSelection' | translate }}</b></div
            >
         </mat-grid-tile>
         <mat-radio-group formControlName="selectedTemplate" (ngModelChange)="templateChanged()">
            <mat-grid-tile *ngFor="let option of teamplateList" style="justify-self: left">
               <mat-radio-button style="padding-left: 20px" [value]="option">{{
                  'admin.missingDataDialog.' + option | translate
               }}</mat-radio-button>
            </mat-grid-tile>
         </mat-radio-group>
      </mat-grid-list>

      <div class="emailBody" [innerHTML]="emailFirstText"></div>

      <mat-grid-list cols="1" rowHeight="2rem" style="width: 100%">
         <mat-grid-tile>
            <div *ngIf="checkBoxList.length > 0"
               ><b>{{ 'admin.missingDataDialog.reasonSelection' | translate }}</b></div
            >
         </mat-grid-tile>
         <mat-grid-tile *ngFor="let option of checkBoxList" style="justify-self: left">
            <mat-checkbox [formControlName]="option" color="primary">{{
               'admin.missingDataDialog.' + option | translate
            }}</mat-checkbox>
         </mat-grid-tile>
      </mat-grid-list>
      <mat-form-field appearance="standard" style="width: 90%">
         <mat-label>{{ 'admin.missingDataDialog.comment' | translate }}</mat-label>
         <textarea matInput formControlName="comment" rows="3"></textarea>
      </mat-form-field>

      <div class="emailBody" [innerHTML]="emailSecondText"></div>
   </form>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: right">
   <button mat-button mat-dialog-close>{{ 'admin.missingDataDialog.cancel' | translate }}</button>
   <button mat-button (click)="onSend()" [disabled]="!isFormValid()">{{ 'admin.missingDataDialog.send' | translate }}</button>
</mat-dialog-actions>
