import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { routes } from 'src/app/config/routes.config';
import { PaymentStatus } from '../../models/person.model';

@Injectable({
   providedIn: 'root',
})
export class PaymentService {
   private readonly url: string;

   // eslint-disable-next-line no-unused-vars
   constructor(private httpClient: HttpClient) {
      this.url = routes.startPayment;
   }

   post(uuid: string): Observable<any> {
      return this.httpClient.post<any>(this.url, { personUuid: uuid, total: 1000 });
   }

   getPaymentStatus(uuid: string): Observable<PaymentStatus> {
      return this.httpClient.get<any>(`api/person/payment-status/${uuid}`).pipe(
         map(response => {
            return response.paymentStatus;
         }),
      );
   }
}
