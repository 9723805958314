import {
   Directive,
   ElementRef,
   HostBinding,
   Input,
   OnDestroy,
   Renderer2,
   TemplateRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { HelperComponent } from './helper/helper.component';
import { MediaService } from './services/media.service';

@Directive({
   selector: '[appHelperIcon]',
})
export class HelperIconDirective implements OnDestroy {
   @Input() matInput?: MatInput;

   @Input() helperContent!: TemplateRef<any>;

   @Input() alwaysVisible = false;

   private subs: (() => void)[] = [];

   private subscription;

   constructor(
      private el: ElementRef,
      private renderer: Renderer2,
      // private bottomSheet: MatBottomSheet,
      public dialog: MatDialog,
      private mediaService: MediaService,
   ) {
      this.subs.push(
         renderer.listen(el.nativeElement, 'click', (event: Event) => {
            event.preventDefault();
            event.stopPropagation();

            dialog.open(HelperComponent, {
               width: '500px',
               data: this.helperContent,
            });
         }),
         // default dolgok megakadályozása, annak érdekében, hogy ne veszítsük
         // el a fókuszt az input elementről
         renderer.listen(el.nativeElement, 'mousedown', (event: Event) => {
            event.preventDefault();
            event.stopPropagation();
         }),
         renderer.listen(el.nativeElement, 'pointerdown', (event: Event) => {
            event.preventDefault();
            event.stopPropagation();
         }),
      );
      this.subscription = this.mediaService.canHover$.subscribe(
         canHover => void (this.alwaysVisible = !canHover),
      );
   }

   ngOnDestroy(): void {
      this.subs.forEach(sub => sub());
      this.subscription.unsubscribe();
   }

   @HostBinding('style.display')
   get getDisplay() {
      if (this.alwaysVisible || !this.matInput) return 'initial';
      return this.matInput.focused ? 'initial' : 'none';
   }

   @HostBinding('type')
   get getType() {
      return 'button';
   }

   @HostBinding('tabindex')
   get tabIndex() {
      return '-1';
   }
}
