<div style="color: var(--dark-puple)">
   <form>
      <h2>{{ 'entry.form' | translate }}</h2>
      <ul>
         <li>
            <mat-checkbox #matCheckBox (change)="onCheck($event.checked, 'goals')">
               {{ 'entry.goals' | translate }}
            </mat-checkbox>
         </li>
         <li>
            <mat-checkbox #matCheckBox (change)="onCheck($event.checked, 'rules')">
               {{ 'entry.rules' | translate }}
            </mat-checkbox>
         </li>
         <li>
            <mat-checkbox #matCheckBox (change)="onCheck($event.checked, 'status')">
               {{ 'entry.status' | translate }}
            </mat-checkbox>
         </li>
         <li>
            <mat-checkbox #matCheckBox (change)="onCheck($event.checked, 'changeStatus')">
               {{ 'entry.change-status' | translate }}
            </mat-checkbox>
         </li>
      </ul>
      <h2>{{ 'entry.declare' | translate }}</h2>
      <ul>
         <li>
            <mat-checkbox #matCheckBox (change)="onCheck($event.checked, 'join')">
               {{ 'entry.join' | translate }}
            </mat-checkbox>
         </li>
         <li>
            <mat-checkbox
               #matCheckBox
               (change)="onCheck($event.checked, 'contract')"
               [disabled]="disableContract"
            >
               <u
                  ><a (click)="onContract()" style="color: blue !important; cursor: pointer">{{
                     'entry.contract' | translate
                  }}</a></u
               >
            </mat-checkbox>
         </li>
         <li>
            <mat-checkbox
               #matCheckBox
               (change)="onCheck($event.checked, 'privacy')"
               [disabled]="disableData"
            >
               <u
                  ><a (click)="onData()" style="color: blue !important; cursor: pointer">{{
                     'entry.privacy' | translate
                  }}</a></u
               >
            </mat-checkbox>
         </li>

         <li>
            <mat-checkbox
               #matCheckBox
               (change)="onCheck($event.checked, 'fireSafety')"
               [disabled]="disableFireSafety"
            >
               <u
                  ><a (click)="onFireSafety()" style="color: blue !important; cursor: pointer">{{
                     'entry.fireSafety' | translate
                  }}</a></u
               >
            </mat-checkbox>
         </li>
         <li>
            <mat-checkbox #matCheckBox (change)="onCheck($event.checked, 'data')">
               {{ 'entry.data' | translate }}
            </mat-checkbox>
         </li>
         <li>
            <mat-checkbox #matCheckBox (change)="onCheck($event.checked, 'attachments')">
               {{ 'entry.attachments' | translate }}
            </mat-checkbox>
         </li>
      </ul>
   </form>
</div>
<app-stepper-navigator
   [nextDisabled]="!onEvaluate()"
   (back)="onBack()"
   (next)="onSubmit()"
></app-stepper-navigator>
