import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from 'src/app/config/routes.config';

@Injectable({
   providedIn: 'root',
})
export class PaymentFinishService {
   private readonly url: string;

   // eslint-disable-next-line no-unused-vars
   constructor(private httpClient: HttpClient) {
      this.url = routes.finishPayment;
   }

   get(s: string, r: string): Observable<any> {
      return this.httpClient.get<any>(this.url, { params: { s: s, r: r } });
   }

   post(s: string, r: string): Observable<any> {
      return this.httpClient.post<any>(this.url, { s: s, r: r });
   }

   finish(uuid: string): Observable<any> {
      return this.httpClient.post<any>('/api/person/finish-application', { uuid: uuid });
   }
}
