<div mat-dialog-title style="text-align: center">
   <h1>{{ 'uploads.confirmationDialogText2' | translate }}</h1>
</div>
<div mat-dialog-content style="margin-left: 1 rem; margin-top: 1 rem; text-align: center">
   <p>{{ 'uploads.confirmationDialogText1' | translate }}</p>
   <p>{{ 'uploads.confirmationDialogText3' | translate }}</p>
</div>

<div mat-dialog-actions style="justify-content: center">
   <button
      mat-raised-button
      color="primary"
      style="min-width: unset; margin-right: 1rem"
      (click)="dismiss($event)"
      >{{ 'uploads.confirmationDialogCancel' | translate }}</button
   >
   <button mat-raised-button color="primary" style="min-width: unset" (click)="confirm($event)">{{
      'uploads.confirmationDialogConfirm' | translate
   }}</button>
</div>
