import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from 'src/app/config/routes.config';

@Injectable({
   providedIn: 'root',
})
export class AdminService {
   private readonly url: string;

   private readonly areaUrl: string;

   private readonly entryFormUrl: string;

   // eslint-disable-next-line no-unused-vars
   constructor(private httpClient: HttpClient) {
      this.url = routes.person;
      this.areaUrl = routes.area;
      this.entryFormUrl = routes.entryForm;
   }

   getList(): Observable<any> {
      return this.httpClient.get<any>(this.url + '/list');
   }

   getOne(uuid: string): Observable<any> {
      return this.httpClient.get<any>(this.url + '/admin/' + uuid);
   }

   getArea(name: string): Observable<any> {
      return this.httpClient.get<any>(this.areaUrl, { params: { name: name } });
   }

   submitAdmin(data: any): Observable<any> {
      return this.httpClient.patch<any>(this.url + '/update-admin', data);
   }

   submitStatus(data: any): Observable<any> {
      return this.httpClient.patch<any>(this.url + '/change-status', data);
   }

   putInTrash(uuid: string): Observable<any> {
      return this.httpClient.patch<any>(this.url + `/trash/${uuid}`, {});
   }

   sync(uuid: string, cash: boolean): Observable<any> {
      let sync = cash ? '/sync-cash' : '/sync';
      return this.httpClient.post<any>(this.url + sync, { uuid: uuid });
   }

   downloadDoc(uuid: string): Observable<any> {
      const headers = new HttpHeaders({
         'Content-Type': 'application/json',
         Accept: 'application/json',
      });
      return this.httpClient.get<any>(this.entryFormUrl, {
         headers: headers,
         responseType: 'blob' as 'json',
         params: { uuid: uuid },
      });
   }

   generateDoc(uuid: string): Observable<any> {
      return this.httpClient.post(this.entryFormUrl, { personUuid: uuid });
   }

   sendPaymentLink(url: string, uuid: string): Observable<any> {
      return this.httpClient.post(routes.paymentLink, { url, uuid });
   }
}
