import { Component, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
   selector: 'app-upload-confirmation-dialog',
   templateUrl: './upload-confirmation.component.html',
   styleUrls: ['./upload-confirmation.component.css'],
})
export class UploadConfimationFialogComponent {
   constructor(
      @Optional() private dialogRef: MatDialogRef<UploadConfimationFialogComponent> | null,
   ) {}

   dismiss(event: Event) {
      this.dialogRef?.close();
      event.preventDefault();
   }

   confirm(event: Event) {
      this.dialogRef?.close(true);
      event.preventDefault();
   }
}
