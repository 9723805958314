<div *ngIf="!admin">
   <form [formGroup]="form">
      <b style="color: red" *ngIf="fileTooLarge">{{ 'uploads.fileSize' | translate }}</b>
      <mat-grid-list cols="{{ noOfCols === 1 ? 1 : 4 }}" rowHeight="5rem">
         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill" style="margin-top: 1rem">
               <mat-label>{{ 'uploads.type' | translate }}</mat-label>
               <select
                  matNativeControl
                  formControlName="type"
                  #typeInput="matInput"
                  #typeNativeInput
               >
                  <option value="STUDENT_ID_FRONT">{{ 'uploads.front' | translate }}</option>
                  <option value="STUDENT_ID_BACK">{{ 'uploads.back' | translate }}</option>
                  <option value="TEMPORARY_STUDENT_ID">{{ 'uploads.temp' | translate }}</option>
                  <option value="PROOF_OF_LEGAL_STATUS">{{ 'uploads.proof' | translate }}</option>
                  <option value="PARENTAL_CONSENT">{{ 'uploads.consent' | translate }}</option>
                  <option value="DATA_AMENDMENT">{{ 'uploads.data' | translate }}</option>
               </select>
               <button
                  mat-icon-button
                  matSuffix
                  appHelperIcon
                  [helperContent]="type"
                  [alwaysVisible]="true"
                  ><mat-icon>info_outlined</mat-icon></button
               >
               <ng-template #type>
                  <p>{{ 'uploads.id-help' | translate }}</p>
               </ng-template>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <button
               type="button"
               [disabled]="!typeNativeInput.value"
               mat-raised-button
               color="secondary"
               (click)="fileInput.click()"
               >{{ 'uploads.choose' | translate }}</button
            >
            <input hidden (change)="onFileSelected()" #fileInput type="file" id="file" />
         </mat-grid-tile>

         <mat-grid-tile *ngIf="this.file">
            {{ this.file.name }}
         </mat-grid-tile>

         <mat-grid-tile *ngIf="this.file">
            <button
               *ngIf="(loadingSpinner$ | async) !== true"
               mat-raised-button
               color="primary"
               (click)="onSubmit()"
               [disabled]="form.invalid"
               >{{ 'uploads.upload' | translate }}</button
            >
            <mat-progress-spinner
               *ngIf="loadingSpinner$ | async"
               matSuffix
               [diameter]="20"
               mode="indeterminate"
            ></mat-progress-spinner>
         </mat-grid-tile>
      </mat-grid-list>
   </form>
   <span style="display: inline-block; padding-bottom: 10px; font-style: italic">
      {{ 'uploads.helpMessage' | translate }}
   </span>
</div>

<div style="padding-bottom: 10rem">
   <table mat-table [dataSource]="documents || []" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="docType">
         <th mat-header-cell *matHeaderCellDef>{{ 'uploads.type' | translate }}</th>
         <td mat-cell *matCellDef="let element"> {{ element.documentType | translate }} </td>
      </ng-container>
      <ng-container matColumnDef="fileName">
         <th mat-header-cell *matHeaderCellDef>{{ 'uploads.name' | translate }}</th>
         <td mat-cell *matCellDef="let element"> {{ element.fileName }} </td>
      </ng-container>
      <ng-container matColumnDef="download">
         <th mat-header-cell *matHeaderCellDef>{{ 'uploads.download' | translate }}</th>
         <td mat-cell *matCellDef="let element">
            <button
               mat-raised-button
               color="secondary"
               style="min-width: unset"
               (click)="onDownload(element.uuid, element.fileName)"
               >{{ 'uploads.download' | translate }}</button
            >
         </td>
      </ng-container>
      <ng-container matColumnDef="delete">
         <th mat-header-cell *matHeaderCellDef>{{ 'uploads.delete' | translate }}</th>
         <td mat-cell *matCellDef="let element">
            <button
               mat-raised-button
               color="warn"
               style="min-width: unset"
               (click)="onDelete(element.uuid)"
               >{{ 'uploads.delete' | translate }}</button
            ></td
         >
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
         <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            {{ 'uploads.no-data' | translate }}
         </td>
      </tr>
   </table>
</div>
