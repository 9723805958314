import { ViewportRuler } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { LanguageService } from './language.service';
import { LoginService } from './login/login.service';
import { RegisterComponent } from './register/register.component';
import { PersonRedirect } from './shared/enums/redirectDecision';
import { SupportedLanguages } from './shared/types/language.type';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnDestroy {
   destroyed$ = new Subject<void>();

   static currentLang: SupportedLanguages;

   get currentLang() {
      return AppComponent.currentLang;
   }

   title = 'Produkteam app';

   bg = '../assets/img/bg-1920.png';

   showClear = false;

   isTest = !environment.production;

   url: string = '';

   isMirrored = false;

   static homeFormData:
      | { taxNumber: string; dateOfBirth: Date; useCase?: PersonRedirect }
      | undefined;

   static self: AppComponent;

   constructor(
      public translate: TranslateService,
      private route: ActivatedRoute,
      private router: Router,
      @Inject(DOCUMENT) private readonly document: Document,
      private readonly viewportRuler: ViewportRuler,
      private loginService: LoginService,
      private service: LanguageService,
   ) {
      translate.setDefaultLang('hu');
      translate.addLangs(['hu', 'en']);
      translate.getTranslation('en'); // we need to load en translation into memmory
      translate.getTranslation('hu'); // we need to load hu translation into memmory

      let language: SupportedLanguages = localStorage.getItem('language') === 'en' ? 'en' : 'hu';
      translate.use(language);
      AppComponent.currentLang = language;

      translate.onLangChange
         .pipe(distinctUntilChanged(undefined as any, _ => _.lang))
         .subscribe((event: LangChangeEvent) => {
            localStorage.setItem('language', event.lang);

            const uuid = localStorage.getItem('uuid');
            if (uuid && !this.loginService.isLoggedIn()) {
               this.service
                  .changeLanguage(event.lang.toUpperCase(), uuid)
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe();
            }
         });

      router.events.subscribe((e: Event) => {
         if (e instanceof NavigationStart) {
            if (e.url.startsWith('/supplements')) {
               this.isMirrored = true;
            } else {
               this.isMirrored = false;
            }

            if (e.url === '/' || e.url === '/info' || e.url === '/login') {
               this.bg = '../assets/img/bg-1920.png';
               this.showClear = false;
            } else {
               this.bg = '../assets/img/hatter_halvany-1920.png';
               this.showClear = true;
            }
         }
         const bg = document.querySelector<HTMLElement>('.bg');
         if (bg) {
            bg.style.backgroundImage = `url(${this.bg})`;
         }

         if (e instanceof NavigationEnd) {
            this.url = e.url;
         }
      });
      this.viewportRuler.change(50).subscribe(() => void this.setSize());
      this.setSize();
      AppComponent.self = this;
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   toggleLang() {
      let language: SupportedLanguages = AppComponent.currentLang === 'en' ? 'hu' : 'en';

      this.translate.use(language);
      AppComponent.currentLang = language;

      if (!this.loginService.isLoggedIn()) {
         RegisterComponent.formLang = language;
      }
   }

   onReset() {
      localStorage.clear();
      this.router.navigateByUrl('/');
   }

   private setSize() {
      const { height } = this.viewportRuler.getViewportSize();
      let vh = height * 0.01;
      this.document.documentElement.style.setProperty('--vh', `${vh}px`);
   }

   isLoggedIn(): boolean {
      return this.loginService.isLoggedIn();
   }

   onEnrollment() {
      this.router.navigateByUrl('admin');
   }

   onSupplements() {
      this.router.navigateByUrl('supplements');
   }

   onEmailAudit() {
      this.router.navigateByUrl('email-log');
   }

   onLogout() {
      this.loginService.logout();
   }
}
