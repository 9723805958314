<mat-card class="example-card shadow" style="margin: 15vh 10vw">
   <mat-card-content>
      <div id="logo-wrapper">
         <img id="logo" src="../../assets/img/logo-600.png" alt="" />
      </div>
      <div style="text-align: center; color: var(--dark-purple)">
         <h2
            ><b>{{ 'info.info' | translate }}</b></h2
         >
         <div style="text-align: start">
            <h3>
               <b>{{ 'info.validation-description' | translate }}</b>
            </h3>
            <ul>
               <li>
                  {{ 'info.permanent-id' | translate }}
               </li>
               <li>
                  {{ 'info.temporary-id' | translate }}
               </li>
               <li>
                  {{ 'info.certificate' | translate }}
               </li>
               <li>
                  {{ 'info.parental-consent' | translate }}
               </li>
            </ul>
            <div><b [innerHTML]="'info.accepted-formats' | translate"></b></div>
         </div>
      </div>
      <div style="text-align: center; padding-top: 1.5em">
         <button mat-flat-button color="primary" (click)="onNext()">{{
            'info.to-form' | translate
         }}</button>
      </div>
   </mat-card-content>
</mat-card>
