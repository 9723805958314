import { Injectable } from '@angular/core';

@Injectable({
   providedIn: 'root',
})
export class AuthService {
   hasResource(resource: string): boolean {
      const resourcesString: string = '' + localStorage.getItem('resources');
      const resources = resourcesString.split(',');
      return resources.findIndex((r: any) => r === resource) > -1;
   }
}
