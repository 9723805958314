import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import {
   AbstractControl,
   FormControl,
   FormGroup,
   FormGroupDirective,
   NgForm,
   ValidationErrors,
   Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {
   MatSnackBar,
   MatSnackBarHorizontalPosition,
   MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, iif, Observable, of, pipe, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PersonModel } from 'src/app/models/person.model';
import { AppComponent } from '../../app.component';
import { BankAccount } from '../../models/bankAccount.model';
import { RegisterComponent } from '../../register/register.component';
import { RegistersService } from '../../register/register.service';
import { checkStringIsDate } from '../../shared/date-helper';
import { findAndSetAutosuggestValue } from '../../shared/helper-functions';
import { distinctUntilChangedAndNotify } from '../../shared/operators/distinct-until-changed-and-notify';
import { MediaService } from '../../shared/services/media.service';
import { BankService } from './bank.service';
import { PersonService } from './personal-data.service';

@Component({
   selector: 'app-personal-data',
   templateUrl: './personal-data.component.html',
   styleUrls: ['./personal-data.component.css'],
})
export class PersonalDataComponent implements OnChanges, OnDestroy {
   destroyed$ = new Subject<void>();

   person!: PersonModel;

   cities$: Observable<string[]>;

   citiesLoadingSpinner$: BehaviorSubject<boolean>;

   nationalities$: Observable<string[]>;

   nationalitiesSpinner$: BehaviorSubject<boolean>;

   personalDataForm: FormGroup;

   studentIdMask = '';

   studentIdPlaceholder = '';

   studentIdMaxLength?: number;

   @Input() uuid!: string;

   @Input() stepper!: MatStepper;

   @Input() user!: any;

   @Input() noOfCols!: number;

   @Input() completed!: BehaviorSubject<boolean>;

   @Output() checkPayment = new EventEmitter();

   horizontalPosition: MatSnackBarHorizontalPosition = 'center';

   verticalPosition: MatSnackBarVerticalPosition = 'top';

   touchUi$ = new BehaviorSubject(false);

   startDate = new Date('2000.01.01');

   showId = false;

   formErrorStateMatcher = new (class implements ErrorStateMatcher {
      isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
         if (
            !control ||
            !form ||
            form.dirty == null ||
            form.invalid == null ||
            form.errors == null
         ) {
            return false;
         }

         let controllerHasFormError;

         if (form.control.errors) {
            controllerHasFormError = Object.keys(form.control.errors).some(err => {
               return form.control.get(err) === control;
            });
         }

         return control.touched && (controllerHasFormError || control.invalid);
      }
   })();

   constructor(
      private personService: PersonService,
      private bankService: BankService,
      private router: Router,
      private _snackBar: MatSnackBar,
      private mediaService: MediaService,
      private registerService: RegistersService,
      private translateService: TranslateService,
   ) {
      if (!AppComponent.homeFormData?.taxNumber) {
         this.router.navigate(['']);
      }
      this.mediaService.screen$
         .pipe(
            takeUntil(this.destroyed$),
            map(screen => {
               return (
                  screen === 'mobileM' ||
                  screen === 'phoneLandscape' ||
                  screen === 'phonePortrait' ||
                  screen === 'tabletPortrait'
               );
            }),
         )
         .subscribe(this.touchUi$);

      this.personalDataForm = new FormGroup(
         {
            firstName: new FormControl('', [Validators.required, Validators.maxLength(40)]),
            surname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
            middleName: new FormControl('', Validators.maxLength(40)),
            bFirstName: new FormControl('', [Validators.maxLength(40)]),
            bSurname: new FormControl('', [Validators.maxLength(50)]),
            bMiddleName: new FormControl('', Validators.maxLength(40)),
            mFirstName: new FormControl('', [Validators.required, Validators.maxLength(40)]),
            mSurname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
            mMiddleName: new FormControl('', Validators.maxLength(40)),
            placeOfBirth: new FormControl('', Validators.required),
            dateOfBirth: new FormControl({ value: null, disabled: true }, [
               Validators.required,
               this.birthdayValidator.bind(this),
            ]),
            sex: new FormControl('', Validators.required),
            nationality: new FormControl('', Validators.required),
            taxNumber: new FormControl('', [
               Validators.required,
               this.taxnumberValidator.bind(this),
            ]),
            socialSecurityNumber: new FormControl('', [
               this.socialSecurityNumberValidator.bind(this),
            ]),
            studentIdNumber: new FormControl(''),
            studentIdType: new FormControl('', Validators.required),
            studentStatus: new FormControl(''),
            email: new FormControl('', [
               Validators.required,
               Validators.email,
               Validators.maxLength(100),
            ]),
            phone: new FormControl('', [Validators.required, this.phoneValidator.bind(this)]),
            idNumber: new FormControl('', [Validators.maxLength(15)]),
            bankDetails: new FormGroup({
               // hidden input field
               uuid: new FormControl(this.uuid),
               bankName: new FormControl({ value: '', disabled: true }),
               accountNumber: new FormControl('', undefined, [
                  this.accountNumberValidator.bind(this),
               ]),
            }),
            hasHealthCard: new FormControl(false),
            healthCardValidDate: new FormControl(null, [
               Validators.required,
               this.healthCardValidator.bind(this),
            ]),
         },
         [
            this.studentIdValidator.bind(this),
            this.passiveValidator.bind(this),
            this.idNumberValidator.bind(this),
         ],
      );

      const formControls = this.personalDataForm.controls;
      formControls.taxNumber.disable();
      formControls.healthCardValidDate.disable();
      formControls.studentIdNumber.disable();

      formControls.hasHealthCard.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
         if (value) {
            formControls.healthCardValidDate.enable();
         } else {
            formControls.healthCardValidDate.disable();
            formControls.healthCardValidDate.setValue(undefined);
         }
      });

      this.citiesLoadingSpinner$ = new BehaviorSubject<boolean>(false);

      this.cities$ = formControls.placeOfBirth.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.citiesLoadingSpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.citiesLoadingSpinner$, false),
         switchMap(name =>
            iif(
               () => name && name.length > 1,
               this.personService.getCity(name, RegisterComponent.formLang),
               of([]),
            ),
         ),
         map(cities => [...new Set(cities.map(c => c.city_name))]),
         startSpinner(this.citiesLoadingSpinner$, false),
      );

      this.nationalitiesSpinner$ = new BehaviorSubject<boolean>(false);

      this.nationalities$ = formControls.nationality.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.nationalitiesSpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.nationalitiesSpinner$, false),
         switchMap(nationality =>
            iif(
               () => nationality && nationality.length > 1,
               this.personService.getNationality(nationality, RegisterComponent.formLang),
               of([]),
            ),
         ),
         startSpinner(this.nationalitiesSpinner$, false),
      );

      formControls.studentIdType.valueChanges
         .pipe(
            takeUntil(this.destroyed$),
            filter(value => !!value),
         )
         .subscribe(value => {
            if (value === 'PERMANENT') {
               this.studentIdPlaceholder = '1234567890';
               this.studentIdMaxLength = 10;
               this.studentIdMask = '0000000000';
            } else if (value === 'TEMPORARY') {
               this.studentIdPlaceholder = '2202080006410';
               this.studentIdMaxLength = 13;
               this.studentIdMask = '0000000000000';
            } else {
               this.studentIdPlaceholder = '1234567890';
               this.studentIdMaxLength = 10;
               this.studentIdMask = '0000000000';
            }
            formControls.studentIdNumber.enable();
            formControls.studentIdNumber.setValue(null);
            // formControls.studentIdNumber.markAsUntouched();
         });
   }

   ngOnChanges(): void {
      this.person = this.user;
      if (this.person) {
         this.personalDataForm.get('firstName')!.setValue(this.person.name?.firstName);
         this.personalDataForm.get('surname')!.setValue(this.person.name?.surname);
         this.personalDataForm.get('middleName')!.setValue(this.person.name?.middleName);

         this.personalDataForm.get('bFirstName')!.setValue(this.person.birthName?.firstName);
         this.personalDataForm.get('bSurname')!.setValue(this.person.birthName?.surname);
         this.personalDataForm.get('bMiddleName')!.setValue(this.person.birthName?.middleName);

         this.personalDataForm.get('mFirstName')!.setValue(this.person.motherName?.firstName);
         this.personalDataForm.get('mSurname')!.setValue(this.person.motherName?.surname);
         this.personalDataForm.get('mMiddleName')!.setValue(this.person.motherName?.middleName);

         this.personalDataForm.get('placeOfBirth')!.setValue(this.person.placeOfBirth);
         if (this.person.dateOfBirth) {
            this.personalDataForm.get('dateOfBirth')!.setValue( new Date(this.person.dateOfBirth));
         }
         this.personalDataForm.get('sex')!.setValue(this.person.sex);
         this.personalDataForm.get('idNumber')!.setValue(this.person.idNumber);
         this.personalDataForm.get('nationality')!.setValue(this.person.nationality);
         this.personalDataForm.get('taxNumber')!.setValue(this.person.taxNumber);
         const socSecNum = this.person.socialSecurityNumber;
         this.personalDataForm.get('socialSecurityNumber')!.setValue(socSecNum);
         this.personalDataForm.get('studentIdType')!.setValue(this.person.studentIdType);
         this.personalDataForm.get('studentIdNumber')!.setValue(this.person.studentIdNumber);
         this.personalDataForm.get('studentStatus')!.setValue(this.person.studentStatus);
         this.personalDataForm.get('email')!.setValue(this.person.email);
         const phone = this.person.phone;
         if (phone) {
            this.personalDataForm.get('phone')!.setValue(phone.district + phone.number);
         } else {
            this.personalDataForm.get('phone')!.setValue('');
         }
         const healthcardValue = this.person.healthCardValidDate;
         this.personalDataForm.get('hasHealthCard')!.setValue(!!healthcardValue);
         if (healthcardValue) {
            const bod = new Date(healthcardValue);
            this.personalDataForm.get('healthCardValidDate')!.setValue(
               // set timezone offset back
               new Date(bod.setUTCMinutes(bod.getTimezoneOffset())),
            );
         }

         this.personalDataForm.patchValue({
            bankDetails: this.person.bankAccounts?.[0],
         });
      }
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   goBack() {
      this.router.navigate(['info']);
   }

   onSubmit() {
      if (this.personalDataForm.valid && AppComponent.homeFormData?.dateOfBirth) {
         const timezoneOffset: number = new Date(
            AppComponent.homeFormData?.dateOfBirth,
         ).getTimezoneOffset();
         const newDate: Date = new Date(AppComponent.homeFormData?.dateOfBirth);
         newDate.setTime(newDate.valueOf() - timezoneOffset * 60000);
         let newHealthCardValidDate: Date | undefined = undefined;
         if (this.personalDataForm.get('healthCardValidDate')?.value) {
            const healthCardTimezoneOffset: number = new Date(
               this.personalDataForm.get('healthCardValidDate')?.value,
            ).getTimezoneOffset();

            newHealthCardValidDate = new Date(
               this.personalDataForm.get('healthCardValidDate')?.value,
            );
            newHealthCardValidDate.setTime(
               newHealthCardValidDate.valueOf() - healthCardTimezoneOffset * 60000,
            );
         }
         const phone = this.personalDataForm.get('phone')?.value as string;
         let country = '+36';
         let district: string | undefined;
         let number: string | undefined;

         if (phone) {
            if (phone.length > 1) {
               district = phone.slice(0, 2);
               number = phone.slice(2);
            }
         }

         let params = {
            uuid: this.uuid,

            name: {
               firstName: this.personalDataForm.get('firstName')?.value,
               surname: this.personalDataForm.get('surname')?.value,
               middleName: this.personalDataForm.get('middleName')?.value
                  ? this.personalDataForm.get('middleName')?.value
                  : '',
            },

            birthName: {
               firstName: this.personalDataForm.get('bFirstName')?.value
                  ? this.personalDataForm.get('bFirstName')?.value
                  : this.personalDataForm.get('firstName')?.value,
               surname: this.personalDataForm.get('bSurname')?.value
                  ? this.personalDataForm.get('bSurname')?.value
                  : this.personalDataForm.get('surname')?.value,
               middleName: this.personalDataForm.get('bMiddleName')?.value
                  ? this.personalDataForm.get('bMiddleName')?.value
                  : this.personalDataForm.get('middleName')?.value
                  ? this.personalDataForm.get('middleName')?.value
                  : '',
            },

            motherName: {
               firstName: this.personalDataForm.get('mFirstName')?.value,
               surname: this.personalDataForm.get('mSurname')?.value,
               middleName: this.personalDataForm.get('mMiddleName')?.value
                  ? this.personalDataForm.get('mMiddleName')?.value
                  : '',
            },

            placeOfBirth: this.personalDataForm.get('placeOfBirth')?.value,

            dateOfBirth: newDate,
            sex: this.personalDataForm.get('sex')?.value,
            nationality: this.personalDataForm.get('nationality')?.value,
            taxNumber: this.personalDataForm.get('taxNumber')?.value,
            socialSecurityNumber: this.personalDataForm.get('socialSecurityNumber')?.value,
            studentIdNumber: this.personalDataForm.get('studentIdNumber')?.value,
            studentIdType: this.personalDataForm.get('studentIdType')?.value,
            studentStatus: this.personalDataForm.get('studentStatus')?.value,
            email: this.personalDataForm.get('email')?.value,
            idNumber: this.personalDataForm.get('idNumber')?.value,
            healthCardValidDate: newHealthCardValidDate ? newHealthCardValidDate : undefined,
            phone: {
               country,
               district,
               number,
            },
         };

         const patchPerson$ = this.personService
            .patchPerson(params)
            .pipe(takeUntil(this.destroyed$));

         const bankUuid: string | undefined = this.personalDataForm.get('bankDetails.uuid')?.value;
         const bankName: string | undefined =
            this.personalDataForm.get('bankDetails.bankName')?.value;
         const accountNumber: string | undefined = this.personalDataForm.get(
            'bankDetails.accountNumber',
         )?.value;

         if (bankUuid) {
            if (bankName === '' && accountNumber === '') {
               this.bankService.delete({ uuid: bankUuid }).subscribe();
            } else {
               this.bankService.update({ uuid: bankUuid, accountNumber, bankName }).subscribe();
            }
         } else {
            if (bankName && bankName !== '' && accountNumber && accountNumber !== '') {
               this.bankService
                  .create({ personUuid: this.uuid, accountNumber, bankName })
                  .subscribe(bank => {
                     this.personalDataForm.patchValue(
                        {
                           bankDetails: bank,
                        },
                        { emitEvent: false },
                     );
                  });
            }
         }

         patchPerson$.subscribe(
            person => {
               if (person.affected && person.affected > 0) {
                  this.checkPaymentStatus().subscribe(
                     () => {
                        this.completed.next(true);
                        this.checkPayment.emit();
                        setTimeout(() => {
                           this.completed.next(false);
                           this.stepper.next();
                        });
                     },
                     err => {
                        this._snackBar.open(
                           this.translateService.instant(err.error.message),
                           'Ok',
                           {
                              horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,
                              duration: 5000,
                           },
                        );
                     },
                  );
               } else {
                  this.completed.next(false);
               }
            },
            err => {
               if (err.error.statusCode === 409) {
                  this.router.navigateByUrl('countryside');
               } else {
                  this.completed.next(false);
                  this._snackBar.open(
                     'HTTP Error: ' + err.error.error + ': ' + err.error.message[0],
                     'Ok',
                     {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 5000,
                     },
                  );
               }
            },
         );
      } else {
         this.personalDataForm.markAllAsTouched();
         this.personalDataForm.updateValueAndValidity();
      }
   }

   dateOfBirtOnChange($event: any) {
      const result = checkStringIsDate($event);
      if (result) {
         this.personalDataForm.controls.dateOfBirth.setValue(result);
      }
   }

   dateOfHealthCardOnChange($event: any) {
      const result = checkStringIsDate($event);
      if (result) {
         this.personalDataForm.controls.healthCardValidDate.setValue(result);
      }
   }

   private temporaryIdRegex = /^2\d{12}$/;

   private permanentIdRegex = /^1\d{9}$/;

   studentIdValidator(control: AbstractControl): ValidationErrors | null {
      const type: string | undefined = control.get('studentIdType')?.value;
      const id: string | undefined = control.get('studentIdNumber')?.value;

      if (!type) {
         return null;
      }

      if (!id) {
         return { studentIdNumber: 'required' };
      }

      if (type === 'PERMANENT') {
         if (!this.permanentIdRegex.test(id)) {
            return { studentIdNumber: 'invalid pattern permanent' };
         }
      } else if (type === 'TEMPORARY') {
         if (!this.temporaryIdRegex.test(id)) {
            return { studentIdNumber: 'invalid pattern temporary' };
         }
      } else {
         return null;
      }

      if (type === 'TEMPORARY') {
         const year = +id.slice(0, 2);
         const month = +id.slice(2, 4);
         const day = +id.slice(4, 6);
         const isoDate = `20${year}-${month}-${day}`;
         const date = new Date(isoDate);

         if (date.toString() === 'Invalid Date') {
            return { studentIdNumber: 'illegal_date' };
         }

         const now = new Date();
         now.setHours(0, 0, 0, 0);
         //A mai naphoz képest az ideiglenes diák lehet -60 akkor lejárt és +60 akkor viszont érvénytelen a szám
         const diffInDays = (date.getTime() - now.getTime()) / 86400000;
         if (diffInDays < -60) {
            return { studentIdNumber: 'range error' };
         }

         if (diffInDays > 60) {
            return { studentIdNumber: 'illegal_date' };
         }
      }

      return null;
   }

   phoneRegex = /^(20|30|31|50|70)\d{7}$/;

   phoneValidator(control: AbstractControl): ValidationErrors | null {
      let errors: any = null;

      const phoneNumber: string | null = control.value;
      if (!phoneNumber) {
         return errors;
      }

      if (phoneNumber.length > 2 && !/20|30|31|50|70/.test(phoneNumber.slice(0, 2))) {
         errors = { ...errors, areaCode: true };
      }

      if (phoneNumber.length !== 9) {
         errors = { ...errors, length: true };
      }

      return errors;
   }

   taxnumberValidator(control: AbstractControl): ValidationErrors | null {
      let errors: any = null;

      const value: string | null = control.value;
      if (!value) {
         return errors;
      }

      const length = value.length;

      if (length > 0 && value.charAt(0) !== '8') {
         errors = { ...errors, leadingEight: true };
      }

      if (length > 6) {
         const days = +value.slice(1, 6);
         const bod: Date = new Date(this.personalDataForm.controls.dateOfBirth.value);
         bod.setHours(0);
         bod.setUTCMinutes(-bod.getTimezoneOffset(), 0, 0);

         const referenceDate = new Date('1867-01-01');
         const diffInDays = (bod.getTime() - referenceDate.getTime()) / 86400000;

         if (diffInDays !== days) {
            errors = { ...errors, badDays: true };
         }
      }

      if (length === 10) {
         const checkSum = [...value.slice(0, -1)].reduce((acc, val, i) => {
            return acc + (i + 1) * +val;
         }, 0);

         if (checkSum % 11 !== +value.charAt(9)) {
            errors = { ...errors, badCheckSum: true };
         }
      } else {
         errors = { ...errors, length: true };
      }

      return errors;
   }

   socialSecurityNumberValidator(control: AbstractControl): ValidationErrors | null {
      let errors: any = null;
      const value: string | null = control.value;
      if (!value) {
         return errors;
      }

      if (value === '000000000') {
         errors = { ...errors, badCheckSum: true };
      }

      if (value.length === 9) {
         const checkSum = [...value.slice(0, -1)].reduce((acc, val, i) => {
            if (i % 2 === 0) {
               return acc + 3 * +val;
            }
            return acc + 7 * +val;
         }, 0);

         if (checkSum % 10 !== +value.charAt(8)) {
            errors = { ...errors, badCheckSum: true };
         }
      } else {
         errors = { ...errors, length: true };
      }

      return errors;
   }

   idNumberValidator(control: AbstractControl): ValidationErrors | null {
      let errors: any = null;
      const nationality = control.get('nationality')?.value;
      const idNumber = control.get('idNumber')?.value;

      if (
         nationality?.trim().toLowerCase() !== 'magyar' &&
         nationality?.trim().toLowerCase() !== 'hungarian'
      ) {
         if (!idNumber) {
            errors = { ...errors, idNumber: 'required' };
         }
      }

      return errors;
   }

   birthdayValidator(control: AbstractControl): ValidationErrors | null {
      let errors: any = null;
      let bod: Date | null = control.value;

      if (!bod) {
         return errors;
      }

      bod = new Date(bod);
      bod.setUTCMinutes(-bod.getTimezoneOffset(), 0, 0);

      const now = new Date();
      now.setUTCHours(0, 0, 0, 0);

      const diffInYears = new Date(now.valueOf() - bod.valueOf()).getUTCFullYear() - 1970;

      if (diffInYears < 15) {
         errors = { ...errors, '15': true };
      }

      return errors;
   }

   // Az egészségügyi kiskönyv érvényességének dátuma nem lehet kissebb mint a mai nap.
   healthCardValidator(control: AbstractControl): ValidationErrors | null {
      let errors: any = null;
      let bod: Date | null = control.value;

      if (!bod) {
         return errors;
      }

      bod = new Date(bod);
      const today = new Date();

      if (bod < today) {
         errors = { ...errors, invalid: true };
      }

      return errors;
   }

   accountNumberValidator(
      control: AbstractControl,
   ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
      let errors: any = null;
      if (control.value.length > 0 && control.value.length < 26) {
         this.personalDataForm.get('bankDetails.bankName')?.setValue('');
         errors = { ...errors, invalid: true };
      }
      if (control.value.length === 26) {
         return this.personService.isAccountNumberValid(control.value).pipe(
            map((bankAccount: BankAccount) => {
               if (!bankAccount.valid) {
                  this.personalDataForm.get('bankDetails.bankName')?.setValue('');
                  errors = { ...errors, invalid: true };
                  return errors;
               } else {
                  this.personalDataForm
                     .get('bankDetails.bankName')
                     ?.setValue(bankAccount.branch?.branchName);
               }
               return errors;
            }),
         );
      }
      return of(errors);
   }

   /** Passzív hallgatói státusz esetén a felhasználó nem lehet idősebb, mint 25. */
   passiveValidator(form: AbstractControl): ValidationErrors | null {
      let errors: any = null;

      let bod: Date | null = form.get('dateOfBirth')?.value;
      const studentStatus: 'ACTIVE' | 'PASSIVE' | undefined = form.get('studentStatus')?.value;

      if (!studentStatus) {
         errors = { ...errors, studentStatus: 'required' };
         return errors;
      }

      if (!bod || studentStatus === 'ACTIVE') {
         return errors;
      }

      bod = new Date(bod);
      bod.setUTCMinutes(-bod.getTimezoneOffset(), 0, 0);

      const now = new Date();
      now.setUTCHours(0, 0, 0, 0);

      const diffInYears = new Date(now.valueOf() - bod.valueOf()).getUTCFullYear() - 1970;

      if (diffInYears > 24) {
         errors = { ...errors, studentStatus: '25' };
      }

      return errors;
   }

   onNationality(event: any) {
      if (event.target) {
         if (
            event.target.value.trim().toLowerCase() == 'magyar' ||
            event.target.value.trim().toLowerCase() == 'hungarian'
         ) {
            this.personalDataForm.get('socialSecurityNumber')?.addValidators(Validators.required);
            this.personalDataForm.get('socialSecurityNumber')?.updateValueAndValidity();
            this.showId = false;
         } else {
            this.personalDataForm
               .get('socialSecurityNumber')
               ?.removeValidators(Validators.required);
            this.personalDataForm.get('socialSecurityNumber')?.updateValueAndValidity();
            this.showId = true;
         }
         this.personService
            .getNationality(event.target.value.trim(), RegisterComponent.formLang)
            .subscribe(data => {
               findAndSetAutosuggestValue(
                  data,
                  event.target.value.trim(),
                  true,
                  this.personalDataForm,
                  'nationality',
               );
            });
      } else {
         if (
            event.option.value.trim().toLowerCase() == 'magyar' ||
            event.option.value.trim().toLowerCase() == 'hungarian'
         ) {
            this.personalDataForm.get('socialSecurityNumber')?.addValidators(Validators.required);
            this.personalDataForm.get('socialSecurityNumber')?.updateValueAndValidity();
            this.showId = false;
         } else {
            this.personalDataForm
               .get('socialSecurityNumber')
               ?.removeValidators(Validators.required);
            this.personalDataForm.get('socialSecurityNumber')?.updateValueAndValidity();
            this.showId = true;
         }
      }
   }

   checkPaymentStatus() {
      return this.registerService.getPaymentStatus(this.uuid).pipe(takeUntil(this.destroyed$));
   }

   onPlaceOfBirthChange(event: any) {
      if (event.target) {
         this.personService
            .getCity(event.target.value, RegisterComponent.formLang)
            .subscribe(data => {
               findAndSetAutosuggestValue(
                  data,
                  event.target.value,
                  false,
                  this.personalDataForm,
                  'placeOfBirth',
                  'city_name',
               );
            });
      }
   }
}

function startSpinner(subject: Subject<boolean>, ends = true) {
   return pipe(
      tap<any>(
         value => {
            if (value) {
               subject.next(ends);
            }
         },
         () => {
            subject.next(false);
         },
         () => {
            subject.next(false);
         },
      ),
   );
}
