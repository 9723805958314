<div id="container">
   <h2
      style="color: var(--dark-purple)"
      *ngIf="!(this.status === 'ACTIVE' || this.status === 'OLD_PAYED')"
   >
      {{ 'payment.forward' | translate }}
   </h2>
   <h2 style="color: var(--dark-purple)" *ngIf="this.status === 'ACTIVE'">
      {{ 'payment.done' | translate }}
   </h2>
   <h2 style="color: var(--dark-purple)" *ngIf="this.status === 'OLD_PAYED'">
      {{ 'payment.old' | translate }}
   </h2>
   <div
      *ngIf="!(this.status === 'ACTIVE' || this.status === 'OLD_PAYED')"
      style="align-items: center"
   >
      <a href="{{ 'OTPlink' | translate }}" target="_blank">
         <img src="../../../assets/img/simplepay.png" alt=""
      /></a>
   </div>
   <div>
      <h2
         *ngIf="!(this.status === 'ACTIVE' || this.status === 'OLD_PAYED')"
         mat-icon-button
         matSuffix
         appHelperIcon
         [helperContent]="idNumberHelper"
      >
         {{ 'payment.accept' | translate }}
      </h2>
      <ng-template #idNumberHelper>
         <div>{{ 'payment.1' | translate }}</div>
         <div>{{ 'payment.2' | translate }}</div>
      </ng-template>
   </div>
</div>
<app-stepper-navigator
   [hasPay]="!(this.status === 'ACTIVE' || this.status === 'OLD_PAYED')"
   [hasNext]="this.status === 'ACTIVE' || this.status === 'OLD_PAYED'"
   [hasBack]="!needToPay"
   [nextDisabled]="paymentDisabled"
   (pay)="onSubmit()"
   (next)="onSubmit()"
   (back)="onBack()"
></app-stepper-navigator>
<div id="blank-space"></div>
<div class="animation-wrapper">
   <img
      *ngIf="animationDone"
      [@enterAnimation]
      src="../../../assets/img/card-800.png"
      alt=""
      id="pay-img"
   />
</div>
