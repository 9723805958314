<mat-card
   class="example-card shadow"
   style="margin-top: 15vh; margin-left: 15vw; width: 70vw; height: 70vh"
>
   <mat-card-header>
      <mat-card-title style="margin-top: -11.5vh; margin-left: 2vw">
         <img src="../../assets/img/logo-600.png" alt="" style="max-height: 20vh" />
      </mat-card-title>
   </mat-card-header>
   <mat-card-content *ngIf="(suc && !uuid) || old">
      <div style="padding-left: 2vw; padding-top: 5vh; text-align: center; word-wrap: break-word">
         <h1 style="color: var(--dark-purple); font-size: 4vh">
            <b>{{ 'payment.success' | translate }}</b>
         </h1>
         <h5> {{ 'payment.trId' | translate }}{{ trId }}</h5>
         <img
            src="../../assets/img/payment_suc.png"
            style="max-height: 30vh; max-width: 60vw"
            alt=""
         />
         <div style="padding-top: 5vh; color: var(--dark-purple)">
            <div [innerHtml]="'payment.more' | translate"></div>
         </div>
      </div>
   </mat-card-content>
   <mat-card-content *ngIf="suc && uuid && !old">
      <div style="padding-left: 2vw; padding-top: 5vh; text-align: center; word-wrap: break-word">
         <h1 style="color: var(--dark-purple); font-size: 4vh">
            <b>{{ 'payment.update' | translate }}</b>
         </h1>
         <img
            src="../../assets/img/payment_suc.png"
            style="max-height: 30vh; max-width: 60vw"
            alt=""
         />
         <div style="padding-top: 5vh; color: var(--dark-purple)">
            <div [innerHtml]="'payment.more' | translate"></div>
         </div>
      </div>
   </mat-card-content>
   <mat-card-content *ngIf="fail">
      <div style="padding-left: 2vw; padding-top: 5vh; text-align: center; word-wrap: break-word">
         <h1 style="color: var(--dark-purple); font-size: 4vh">
            <b>{{ 'payment.error' | translate }}</b>
         </h1>
         <h3 *ngIf="paymentError !== 'CANCEL' && paymentError !== 'TIMEOUT'">{{
            ('payment.error-msg' | translate) + ': ' + paymentError
         }}</h3>
         <h3 *ngIf="paymentError === 'CANCEL'">{{ 'payment.cancel' | translate }}</h3>
         <h3 *ngIf="paymentError === 'TIMEOUT'">{{ 'payment.timeout' | translate }}</h3>
         <h4 *ngIf="paymentError !== 'CANCEL' && paymentError !== 'TIMEOUT'">{{
            'payment.check' | translate
         }}</h4>
         <h5> {{ 'payment.trId' | translate }}{{ trId }}</h5>
         <div style="padding-top: 20vh; color: var(--dark-purple)">
            <button mat-flat-button color="secondary" (click)="onRetry()">
               {{ 'payment.retry' | translate }}
            </button>
         </div>
      </div>
   </mat-card-content>
</mat-card>
