<div style="margin-left: 10vw; margin-top: 10vh; width: 80vw">
   <form
      style="margin-bottom: 3vh"
      [formGroup]="filterForm"
      fxLayout="row"
      fxLayoutAlign="space-around center"
   >
      <mat-form-field>
         <input
            matInput
            [matDatepicker]="picker"
            placeholder="{{ 'emaillog.from' | translate }}"
            formControlName="from"
         />
         <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
         <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
         <input
            matInput
            [matDatepicker]="picker2"
            placeholder="{{ 'emaillog.to' | translate }}"
            formControlName="to"
         />
         <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
         <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
         <input
            matInput
            placeholder="{{ 'emaillog.sender' | translate }}"
            formControlName="sender"
         />
      </mat-form-field>
      <mat-form-field>
         <input
            matInput
            placeholder="{{ 'emaillog.recipientName' | translate }}"
            formControlName="recipientName"
         />
      </mat-form-field>
      <mat-form-field>
         <input
            matInput
            placeholder="{{ 'emaillog.recipient' | translate }}"
            formControlName="recipient"
         />
      </mat-form-field>
      <mat-form-field>
         <mat-label>{{ 'emaillog.type' | translate }}</mat-label>
         <mat-select formControlName="type" style="width: 30% !important">
            <mat-option *ngFor="let type of logTypes" [value]="type">
               {{ type | translate }}
            </mat-option>
         </mat-select>
      </mat-form-field>

      <div>
         <button mat-raised-button color="primary" (click)="onGetEmailLog(true)">{{
            'emaillog.search' | translate
         }}</button>
      </div>
   </form>
   <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="sender">
         <th mat-header-cell *matHeaderCellDef>{{ 'emaillog.sender' | translate }}</th>
         <td mat-cell *matCellDef="let element">{{ element.sender }}</td>
      </ng-container>

      <ng-container matColumnDef="recipientName">
         <th mat-header-cell *matHeaderCellDef>{{ 'emaillog.recipientName' | translate }}</th>
         <td mat-cell *matCellDef="let element">{{ element.recipientName }}</td>
      </ng-container>

      <ng-container matColumnDef="recipient">
         <th mat-header-cell *matHeaderCellDef>{{ 'emaillog.recipient' | translate }}</th>
         <td mat-cell *matCellDef="let element">{{ element.recipient }}</td>
      </ng-container>

      <ng-container matColumnDef="type">
         <th mat-header-cell *matHeaderCellDef>{{ 'emaillog.type' | translate }}</th>
         <td mat-cell *matCellDef="let element">{{ element.type | translate }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
         <th mat-header-cell *matHeaderCellDef>{{ 'emaillog.createdAt' | translate }}</th>
         <td mat-cell *matCellDef="let element">{{ element.createdAt }}</td>
      </ng-container>

      <ng-container matColumnDef="body">
         <th mat-header-cell *matHeaderCellDef>{{ 'emaillog.body' | translate }}</th>
         <td mat-cell *matCellDef="let element">
            <button mat-mini-fab color="secondary" (click)="showBody(element.body)">
               <mat-icon>visibility</mat-icon>
            </button>
         </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
   </table>
   <mat-paginator [length]="listSize" [pageSize]="50" [pageSizeOptions]="[50]" (page)="onPageChange($event)" aria-label="Select page">
   </mat-paginator>
</div>
