<div>
   <form [formGroup]="personalDataForm" [ngClass]="{ compact: noOfCols === 2 }">
      <div class="row">
         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.surname' | translate }}</mat-label>
            <input matInput formControlName="surname" #surnameInput="matInput" maxlength="50" />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="surnameInput"
               [helperContent]="surnameHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #surnameHelper>
               <p>{{ 'data.surnameHelper' | translate }}</p>
            </ng-template>
            <mat-error>{{ 'fill' | translate }}{{ 'data.surname' | translate }}</mat-error>
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.firstName' | translate }}</mat-label>
            <input matInput formControlName="firstName" #firstNameInput="matInput" maxlength="40" />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="firstNameInput"
               [helperContent]="firstNameHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #firstNameHelper>
               <p>{{ 'data.firstNameHelper' | translate }}</p>
            </ng-template>
            <mat-error>{{ 'fill' | translate }}{{ 'data.firstName' | translate }}</mat-error>
         </mat-form-field>
      </div>

      <div class="row">
         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.middleName' | translate }}</mat-label>
            <input
               matInput
               formControlName="middleName"
               #middleNameInput="matInput"
               maxlength="40"
            />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="middleNameInput"
               [helperContent]="middleNameHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #middleNameHelper>
               <p>{{ 'data.middleNameHelper' | translate }}</p>
            </ng-template>
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.bSurname' | translate }}</mat-label>
            <input matInput formControlName="bSurname" #bSurnameInput="matInput" maxlength="50" />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="bSurnameInput"
               [helperContent]="bSurnameHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #bSurnameHelper>
               <p>{{ 'data.bSurnameHelper' | translate }}</p>
            </ng-template>
         </mat-form-field>
      </div>

      <div class="row">
         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.bFirstName' | translate }}</mat-label>
            <input
               matInput
               formControlName="bFirstName"
               #bFirstNameInput="matInput"
               maxlength="40"
            />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="bFirstNameInput"
               [helperContent]="bFirstNameHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #bFirstNameHelper>
               <p>{{ 'data.bFirstNameHelper' | translate }}</p>
            </ng-template>
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.bMiddleName' | translate }}</mat-label>
            <input
               matInput
               formControlName="bMiddleName"
               #bMiddleNameInput="matInput"
               maxlength="40"
            />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="bMiddleNameInput"
               [helperContent]="bMiddleNameHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #bMiddleNameHelper>
               <p>{{ 'data.bMiddleNameHelper' | translate }}</p>
            </ng-template>
         </mat-form-field></div
      >

      <div class="row">
         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.mSurname' | translate }}</mat-label>
            <input matInput formControlName="mSurname" #mSurnameInput="matInput" maxlength="50" />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="mSurnameInput"
               [helperContent]="mSurnameHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #mSurnameHelper>
               <p>{{ 'data.mSurnameHelper' | translate }}</p>
            </ng-template>
            <mat-error>{{ 'fill' | translate }}{{ 'data.mSurname' | translate }}</mat-error>
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.mFirstName' | translate }}</mat-label>
            <input
               matInput
               formControlName="mFirstName"
               #mFirstNameInput="matInput"
               maxlength="40"
            />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="mFirstNameInput"
               [helperContent]="mFirstNameHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #mFirstNameHelper>
               <p>{{ 'data.mFirstNameHelper' | translate }}</p>
            </ng-template>
            <mat-error>{{ 'fill' | translate }}{{ 'data.mFirstName' | translate }}</mat-error>
         </mat-form-field>
      </div>

      <div class="row">
         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.mMiddleName' | translate }}</mat-label>
            <input
               matInput
               formControlName="mMiddleName"
               #mMiddleNameInput="matInput"
               maxlength="40"
            />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="mMiddleNameInput"
               [helperContent]="mMiddleNameHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #mMiddleNameHelper>
               <p>{{ 'data.mMiddleNameHelper' | translate }}</p>
            </ng-template>
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.dateOfBirth' | translate }}</mat-label>
            <input
               matInput
               [matDatepicker]="picker"
               formControlName="dateOfBirth"
               placeholder="19971231"
               (change)="dateOfBirtOnChange($event)"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker
               startView="year"
               [startAt]="startDate"
               [touchUi]="touchUi$ | async"
               #picker
            ></mat-datepicker>
            <button mat-icon-button matSuffix appHelperIcon [helperContent]="dateOfBirthHelper"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #dateOfBirthHelper>
               <p>{{ 'data.dateOfBirthHelper' | translate }}</p>
            </ng-template>
            <mat-error *ngIf="personalDataForm.controls.dateOfBirth.hasError('15')">
               {{ 'data.errors.dateOfBirth.15' | translate }}
            </mat-error>
            <mat-error>{{ 'fill' | translate }}{{ 'data.dateOfBirth' | translate }}</mat-error>
         </mat-form-field>
      </div>

      <div class="row" align="left">
         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.placeOfBirth' | translate }}</mat-label>
            <input
               matInput
               [matAutocomplete]="cit"
               formControlName="placeOfBirth"
               #placeOfBirthInput="matInput"
               (change)="onPlaceOfBirthChange($event)"
            />
            <mat-progress-spinner
               *ngIf="citiesLoadingSpinner$ | async"
               matSuffix
               [diameter]="20"
               mode="indeterminate"
            ></mat-progress-spinner>
            <button
               matSuffix
               mat-icon-button
               appHelperIcon
               [matInput]="placeOfBirthInput"
               [helperContent]="placeOfBirthHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #placeOfBirthHelper>
               <p>{{ 'data.placeOfBirthHelper' | translate }}</p>
            </ng-template>
            <mat-autocomplete autoActiveFirstOption #cit="matAutocomplete">
               <mat-option *ngFor="let city of cities$ | async" [value]="city">
                  {{ city }}
               </mat-option>
            </mat-autocomplete>
            <mat-error>{{ 'fill' | translate }}{{ 'data.placeOfBirth' | translate }}</mat-error>
            <mat-hint>{{ 'hint' | translate }}</mat-hint>
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.nationality' | translate }}</mat-label>
            <input
               name="nationality"
               matInput
               [matAutocomplete]="nat"
               formControlName="nationality"
               #nationalityInput="matInput"
               [placeholder]="'placeholder.nationality' | translate"
               (change)="onNationality($event)"
            />
            <mat-progress-spinner
               *ngIf="nationalitiesSpinner$ | async"
               matSuffix
               [diameter]="20"
               mode="indeterminate"
            ></mat-progress-spinner>
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="nationalityInput"
               [helperContent]="nationalityHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #nationalityHelper>
               <p>{{ 'data.nationalityHelper' | translate }}</p>
            </ng-template>
            <mat-autocomplete
               autoActiveFirstOption
               #nat="matAutocomplete"
               (optionSelected)="onNationality($event)"
            >
               <mat-option *ngFor="let nationality of nationalities$ | async" [value]="nationality">
                  {{ nationality }}
               </mat-option>
            </mat-autocomplete>
            <mat-hint>{{ 'hint' | translate }}</mat-hint>
            <mat-error>{{ 'fill' | translate }}{{ 'data.nationality' | translate }}</mat-error>
         </mat-form-field></div
      >

      <div class="row">
         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.socialSecurityNumber' | translate }}</mat-label>
            <input
               matInput
               formControlName="socialSecurityNumber"
               mask="000 000 000"
               #socialSecurityNumberInput="matInput"
               [validation]="false"
            />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="socialSecurityNumberInput"
               [helperContent]="socialSecurityNumberHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #socialSecurityNumberHelper>
               <div [outerHTML]="'data.socialSecurityNumberHelper' | translate"></div>
            </ng-template>
            <mat-error
               *ngIf="personalDataForm.controls.socialSecurityNumber.hasError('badCheckSum')"
            >
               {{ 'data.errors.socialSecurityNumber.badCheckSum' | translate }}
            </mat-error>
            <mat-error *ngIf="personalDataForm.controls.socialSecurityNumber.hasError('length')">
               {{ 'data.errors.socialSecurityNumber.length' | translate }}
            </mat-error>
            <mat-error
               >{{ 'fill' | translate }}{{ 'data.socialSecurityNumber' | translate }}</mat-error
            >
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.taxNumber' | translate }}</mat-label>
            <input
               matInput
               formControlName="taxNumber"
               placeholder="8123456789"
               #taxNumberInput="matInput"
               maxlength="10"
            />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="taxNumberInput"
               [helperContent]="taxNumberHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #taxNumberHelper>
               <p>{{ 'data.taxNumberHelper' | translate }}</p>
            </ng-template>

            <mat-error *ngIf="personalDataForm.controls.taxNumber.hasError('leadingEight')">
               {{ 'data.errors.taxNumber.leadingEight' | translate }}
            </mat-error>
            <mat-error *ngIf="personalDataForm.controls.taxNumber.hasError('badDays')">
               {{ 'data.errors.taxNumber.badDays' | translate }}
            </mat-error>
            <mat-error *ngIf="personalDataForm.controls.taxNumber.hasError('badCheckSum')">
               {{ 'data.errors.taxNumber.badCheckSum' | translate }}
            </mat-error>
            <mat-error *ngIf="personalDataForm.controls.taxNumber.hasError('length')">
               {{ 'data.errors.taxNumber.badLength' | translate }}
            </mat-error>
            <mat-error>{{ 'fill' | translate }}{{ 'data.taxNumber' | translate }}</mat-error>
         </mat-form-field>
      </div>

      <div class="row" *ngIf="showId">
         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.idNumber' | translate }}</mat-label>
            <input
               matInput
               [errorStateMatcher]="formErrorStateMatcher"
               formControlName="idNumber"
               #idNumberInput="matInput"
            />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="idNumberInput"
               [helperContent]="idNumberHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #idNumberHelper>
               <div [outerHTML]="'data.idNumberHelper' | translate"></div>
            </ng-template>
            <mat-error *ngIf="personalDataForm.errors?.idNumber === 'required'">
               {{ 'data.errors.passport_number.required' | translate }}
            </mat-error>
            <mat-error>{{ 'fill' | translate }}{{ 'data.idNumber' | translate }}</mat-error>
         </mat-form-field>
      </div>

      <div class="row">
         <mat-form-field appearance="fill">
            <mat-label>{{ 'data.sex' | translate }}</mat-label>
            <select matNativeControl formControlName="sex">
               <option value="MALE">{{ 'data.male' | translate }}</option>
               <option value="FEMALE">{{ 'data.female' | translate }}</option>
            </select>
            <mat-error>{{ 'fill' | translate }}{{ 'data.sex' | translate }}</mat-error>
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label>{{ 'data.status' | translate }}</mat-label>
            <select
               matNativeControl
               formControlName="studentStatus"
               [errorStateMatcher]="formErrorStateMatcher"
            >
               <option value="ACTIVE">{{ 'data.active' | translate }}</option>
               <option value="PASSIVE">{{ 'data.passive' | translate }}</option>
            </select>
            <mat-error
               class="mat-error"
               *ngIf="personalDataForm.errors?.['studentStatus'] === '25'"
               >{{ 'data.errors.studentStatus.25' | translate }}</mat-error
            >
            <mat-error>{{ 'fill' | translate }}{{ 'data.status' | translate }}</mat-error>
         </mat-form-field>
      </div>

      <div class="row"
         ><mat-form-field appearance="fill">
            <mat-label>{{ 'data.stud-id-type' | translate }}</mat-label>
            <select matNativeControl formControlName="studentIdType">
               <option value="TEMPORARY">{{ 'data.temp' | translate }}</option>
               <option value="PERMANENT">{{ 'data.perm' | translate }}</option>
            </select>
            <mat-error>{{ 'fill' | translate }}{{ 'data.stud-id-type' | translate }}</mat-error>
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.studentIdNumber' | translate }}</mat-label>
            <input
               matInput
               formControlName="studentIdNumber"
               [placeholder]="studentIdPlaceholder"
               [errorStateMatcher]="formErrorStateMatcher"
               [mask]="studentIdMask"
               [validation]="false"
               [maxLength]="studentIdMaxLength"
               #studentIdNumberInput="matInput"
            />
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="studentIdNumberInput"
               [helperContent]="studentIdNumberHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #studentIdNumberHelper>
               <div [outerHTML]="'data.studentIdNumberHelper' | translate"></div>
            </ng-template>

            <mat-error
               class="mat-error"
               *ngIf="personalDataForm.errors?.['studentIdNumber'] === 'illegal_date'"
               >{{ 'data.errors.studentId.illegal_date' | translate }}</mat-error
            >
            <mat-error
               class="mat-error"
               *ngIf="personalDataForm.errors?.['studentIdNumber'] === 'range error'"
               >{{ 'data.errors.studentId.range' | translate }}</mat-error
            >
            <mat-error
               class="mat-error"
               *ngIf="personalDataForm.errors?.['studentIdNumber'] === 'invalid pattern permanent'"
               >{{ 'data.errors.studentId.permanent' | translate }}</mat-error
            >
            <mat-error
               class="mat-error"
               *ngIf="personalDataForm.errors?.['studentIdNumber'] === 'invalid pattern temporary'"
               >{{ 'data.errors.studentId.temporary' | translate }}</mat-error
            >
            <mat-error>{{ 'fill' | translate }}{{ 'data.studentIdNumber' | translate }}</mat-error>
         </mat-form-field></div
      >

      <div class="row">
         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.email' | translate }}</mat-label>
            <input type="email" matInput formControlName="email" maxlength="100" />
            <mat-error>{{ 'fill' | translate }}{{ 'data.email' | translate }}</mat-error>
         </mat-form-field>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.phone' | translate }}</mat-label>
            <input
               matInput
               formControlName="phone"
               mask="00/0000000"
               prefix="+36/"
               [showMaskTyped]="true"
               [validation]="false"
            />
            <mat-error *ngIf="personalDataForm.controls.phone.hasError('areaCode')">
               {{ 'data.errors.phone.areaCode' | translate }}
            </mat-error>
            <mat-error>{{ 'fill' | translate }}{{ 'data.phone' | translate }}</mat-error>
         </mat-form-field>
      </div>

      <div class="row">
         <mat-checkbox formControlName="hasHealthCard">
            {{ 'data.hasShealthCard' | translate }}
         </mat-checkbox>

         <mat-form-field appearance="fill">
            <mat-label class="label-color">{{ 'data.healthCardValidDate' | translate }}</mat-label>
            <input
               matInput
               [matDatepicker]="healthCardpicker"
               formControlName="healthCardValidDate"
               (change)="dateOfHealthCardOnChange($event)"
            />
            <mat-datepicker-toggle matSuffix [for]="healthCardpicker"></mat-datepicker-toggle>
            <mat-datepicker
               startView="year"
               [touchUi]="touchUi$ | async"
               #healthCardpicker
            ></mat-datepicker>
            <button mat-icon-button matSuffix appHelperIcon [helperContent]="healthCardHelper"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #healthCardHelper>
               <p>{{ 'data.dateOfHealthCardValidHelper' | translate }}</p>
            </ng-template>
            <mat-error *ngIf="personalDataForm.controls.healthCardValidDate.hasError('invalid')">
               {{ 'data.errors.healthCardValidDate.invalid' | translate }}
            </mat-error>
            <mat-error
               >{{ 'fill' | translate }}{{ 'data.healthCardValidDate' | translate }}</mat-error
            >
         </mat-form-field>
      </div>

      <ng-container formGroupName="bankDetails">
         <div class="row">
            <input type="hidden" formControlName="uuid" />
            <mat-form-field appearance="fill">
               <mat-label class="label-color">{{ 'data.accountNumber' | translate }}</mat-label>
               <input
                  matInput
                  formControlName="accountNumber"
                  mask="00000000-00000000-00000000"
                  placeholder="11223344-55667788-00000000"
                  [validation]="false"
                  [dropSpecialCharacters]="false"
                  #accountNumberInput="matInput"
               />
               <button
                  mat-icon-button
                  matSuffix
                  appHelperIcon
                  [matInput]="accountNumberInput"
                  [helperContent]="accountNumberHelper"
                  [alwaysVisible]="true"
                  ><mat-icon>info_outlined</mat-icon></button
               >
               <ng-template #accountNumberHelper>
                  <div [outerHTML]="'data.accountNumberHelper' | translate"></div>
               </ng-template>
               <mat-error
                  *ngIf="personalDataForm.get('bankDetails.accountNumber')?.hasError('mask')"
               >
                  {{ 'data.errors.accountNumber.invalidAccountNumberFormat' | translate }}
               </mat-error>
               <mat-error
                  *ngIf="personalDataForm.get('bankDetails.accountNumber')?.hasError('invalid')"
               >
                  {{ 'data.errors.accountNumber.invalidAccountNumber' | translate }}
               </mat-error>
               <mat-error>{{ 'fill' | translate }}{{ 'data.accountNumber' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
               <mat-label class="label-color">{{ 'data.bankName' | translate }}</mat-label>
               <input matInput formControlName="bankName" [disabled]="true" />
            </mat-form-field>
         </div>
      </ng-container>
   </form>
</div>

<app-stepper-navigator [hasBack]="false" (next)="onSubmit()"></app-stepper-navigator>
