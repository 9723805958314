<img id="logo" src="../../assets/img/logo-600.png" alt="" />
<div
   [ngClass]="media.orientation === 'horizontal' ? 'web' : 'phone'"
   *ngIf="media$ | async as media; else loading"
>
   <mat-stepper
      [linear]="isLinear"
      [@.disabled]="isAnimationDisabled"
      labelPosition="bottom"
      [orientation]="media.orientation"
      style="background-color: rgba(255, 255, 255, 0)"
      #stepper
      *ngIf="user || needToPay"
      (animationDone)="animationDone()"
      (selectionChange)="selectionChange($event)"
   >
      <ng-template matStepperIcon="number" let-index="index">
         <mat-icon>{{ icons[index] }}</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="edit" let-index="index">
         <mat-icon>{{ icons[index] }}</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done" let-index="index">
         <mat-icon>{{ icons[index] }}</mat-icon>
      </ng-template>

      <mat-step
         label="{{ 'register.data' | translate }}"
         [completed]="personalDataCompleted$ | async"
      >
         <app-personal-data
            *ngIf="user"
            [uuid]="uuid"
            [stepper]="stepper"
            [user]="user"
            [noOfCols]="media.noOfCols"
            [completed]="personalDataCompleted$"
            (checkPayment)="checkPayment()"
         >
         </app-personal-data>
      </mat-step>
      <mat-step
         label="{{ 'register.contact' | translate }}"
         [completed]="contactInfoCompleted$ | async"
      >
         <app-contact-info
            *ngIf="user"
            [uuid]="uuid"
            [stepper]="stepper"
            [user]="user"
            [noOfCols]="media.noOfCols"
            (refresh)="onRefresh()"
            [completed]="contactInfoCompleted$"
         ></app-contact-info>
      </mat-step>
      <mat-step
         label="{{ 'register.studies' | translate }}"
         [completed]="studiesCompleted$ | async"
      >
         <app-studies
            *ngIf="user"
            [uuid]="uuid"
            [stepper]="stepper"
            [user]="user"
            [noOfCols]="media.noOfCols"
            [orientation]="media.orientation"
            [completed]="studiesCompleted$"
         ></app-studies>
      </mat-step>
      <mat-step label="{{ 'register.info' | translate }}" [completed]="othersCompleted$ | async">
         <app-others
            *ngIf="user"
            [uuid]="uuid"
            [stepper]="stepper"
            [user]="user"
            [noOfCols]="media.noOfCols"
            [orientation]="media.orientation"
            [completed]="othersCompleted$"
         ></app-others>
      </mat-step>
      <mat-step
         label="{{ 'register.documents' | translate }}"
         [completed]="uploadsCompleted$ | async"
      >
         <app-uploads
            *ngIf="user"
            [uuid]="uuid"
            [stepper]="stepper"
            [noOfCols]="media.noOfCols"
            [orientation]="media.orientation"
            [admin]="false"
            [documents]="user.documents"
            [uploadUrl]="uploadUrl"
            [downloadUrl]="downloadUrl"
            (refresh)="onRefresh()"
            [loadingSpinner]="uploadSpinner$"
         ></app-uploads>
         <app-stepper-navigator
            (back)="onBack()"
            (next)="onNext()"
            [nextDisabled]="(uploadSpinner$ | async) || false"
            [backDisabled]="(uploadSpinner$ | async) || false"
         ></app-stepper-navigator>
      </mat-step>
      <mat-step label="{{ 'register.form' | translate }}" [completed]="entryCompleted$ | async">
         <app-entry
            *ngIf="user"
            [uuid]="uuid"
            [user]="user"
            [stepper]="stepper"
            [noOfCols]="media.noOfCols"
            [completed]="entryCompleted$"
         ></app-entry>
      </mat-step>
      <mat-step label="{{ 'register.pay' | translate }}">
         <app-payment
            [uuid]="uuid"
            [stepper]="stepper"
            [noOfCols]="media.noOfCols"
            [animationDone]="paymentAnimationDone"
            [status]="user?.paymentStatus"
            [needToPay]="needToPay"
         >
         </app-payment>
      </mat-step>
   </mat-stepper>
</div>
<ng-template #loading>TODO: loading</ng-template>
