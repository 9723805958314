<mat-card class="example-card shadow" style="margin: 15vh 10vw">
   <mat-card-content>
      <div id="logo-wrapper">
         <img id="logo" src="../../assets/img/logo-600.png" alt="" />
      </div>
      <div style="text-align: center; color: var(--dark-purple)">
         <h2
            ><b>{{ 'info.info' | translate }}</b></h2
         >
         <div style="text-align: start">
            <h3>
               <b>{{ 'info.req' | translate }}</b>
            </h3>
            <ul>
               <li>
                  {{ 'info.15' | translate }}
               </li>
               <li>
                  {{ 'info.active' | translate }}
               </li>
               <li>
                  {{ 'info.passive' | translate }}
               </li>
            </ul>
         </div>
         <h2><b [innerHTML]="'info.need' | translate"></b></h2>
         <div>
            <b>
               <ul style="text-align: start">
                  <li
                     ><span style="color: var(--magenta)">{{
                        'info.student-card' | translate
                     }}</span>
                  </li>
                  <li
                     ><span style="color: var(--magenta)">{{ 'info.id' | translate }}</span
                     >{{ 'info.only-foreign' | translate }}</li
                  >
                  <li
                     ><span style="color: var(--magenta)">{{ 'info.tax' | translate }}</span
                     >{{ 'info.opcional' | translate }}</li
                  >
                  <li
                     ><span style="color: var(--magenta)">{{
                        'info.optional-need' | translate
                     }}</span>
                  </li>
                  <li>
                     {{ 'info.18' | translate }}
                     <span style="color: var(--magenta)">{{ 'info.parent' | translate }}</span>
                  </li>
               </ul>
               <span [innerHTML]="'info.pay' | translate"></span>
               <span style="color: var(--orange)">SimplePay</span>
               {{ 'info.end' | translate }}
            </b>
         </div>
      </div>
      <div style="text-align: center; padding-top: 1.5em">
         <button mat-flat-button color="primary" (click)="onNext()">{{
            'info.to-form' | translate
         }}</button>
      </div>
   </mat-card-content>
</mat-card>
