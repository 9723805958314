import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from 'src/app/config/routes.config';
import { AppComponent } from './app.component';
import { PersonRedirect } from './shared/enums/redirectDecision';

@Injectable({
   providedIn: 'root',
})
export class LanguageService {
   constructor(private httpClient: HttpClient) {}

   changeLanguage(lang: string, uuid: string): Observable<any> {
      if (!AppComponent.homeFormData) {
         return new Observable();
      }
      const url =
         AppComponent.homeFormData?.useCase === PersonRedirect.REGISTER
            ? routes.person
            : routes.membershipValidation;
      return this.httpClient.patch<any>(url + '/change-language', {
         uuid: uuid,
         language: lang,
      });
   }
}
