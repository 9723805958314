/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentService } from '../modules/payment/payment.service';
import { PaymentFinishService } from './payment-finish.service';

@Component({
   selector: 'app-payment-finish',
   templateUrl: './payment-finish.component.html',
   styleUrls: ['./payment-finish.component.css'],
})
export class PaymentFinishComponent implements OnInit, OnDestroy {
   destroyed$ = new Subject<void>();

   currentLang = 'hu';

   uuid: string = '';

   r: string = '';

   s: string = '';

   wait = true;

   suc = false;

   fail = false;

   old = false;

   trId = '';

   paymentError = '';

   constructor(
      public translate: TranslateService,
      private route: ActivatedRoute,
      private service: PaymentFinishService,
      private paymentService: PaymentService,
   ) {}

   ngOnInit() {
      this.currentLang = this.translate.currentLang;
      this.route.queryParamMap.subscribe(p => {
         if (p.has('uuid')) {
            this.suc = true;
            this.old = true;
            this.uuid = '' + p.get('uuid');
            this.finishApplication();
            if (p.has('suc')) {
               this.old = false;
            }
         } else if (p.has('r') && p.has('s')) {
            this.r = decodeURI(this.r + p.get('r'))
               .split(' ')
               .join('+');
            this.s = decodeURI(this.s + p.get('s'))
               .split(' ')
               .join('+');
            this.testPayment();
         }
      });
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   finishApplication() {
      this.service.finish(this.uuid).pipe(takeUntil(this.destroyed$)).subscribe();
   }

   testPayment() {
      this.wait = false;
      this.suc = false;
      this.fail = false;
      this.service
         .post(this.s, this.r)
         .pipe(takeUntil(this.destroyed$))
         .subscribe(
            r => {
               this.paymentError = r.e;
               if (r.e === 'SUCCESS') {
                  this.wait = false;
                  this.suc = true;
                  this.fail = false;
                  this.trId = r.t;
                  localStorage.removeItem('uuid');
               } else {
                  this.wait = false;
                  this.suc = false;
                  this.fail = true;
                  this.trId = r.t;
               }
            },
            e => {
               this.getPayment();
            },
         );
   }

   getPayment() {
      this.service
         .get(this.s, this.r)
         .pipe(takeUntil(this.destroyed$))
         .subscribe(
            r => {
               if (r.affected > 0) {
                  this.wait = false;
                  this.suc = true;
                  this.fail = false;
               } else {
                  this.wait = false;
                  this.suc = false;
                  this.fail = true;
               }
            },
            e => {
               this.wait = false;
               this.suc = false;
               this.fail = true;
            },
         );
   }

   onRetry() {
      this.paymentService
         .post('' + localStorage.getItem('uuid'))
         .pipe(takeUntil(this.destroyed$))
         .subscribe(r => {
            window.location.href = r.paymentUrl;
         });
   }
}
