import { ExtendedStudentDetails } from './student-details.interface';

export interface MembershipValidationData {
   id: number;
   uuid: string;
   name: { firstName: string; surname: string; middleName: string };
   taxNumber: string;
   dateOfBirth: Date;
   email: string;
   createDate: string;
   documents: {
      id: number;
      uuid: string;
      fileName: string;
      createdAt: string;
      updatedAt: string;
      status: string;
      documentType: string; //enum anyway..
   }[];
   language: 'EN' | 'HU';
   type: FormType;
   status: ValidationStatus;
   details?: ExtendedStudentDetails | null; //only attached on app side...
}

export enum ValidationStatus {
   APPLICATION_STARTED = 'APPLICATION_STARTED',
   APPLICATION_IN_PROGRESS = 'APPLICATION_IN_PROGRESS',
   FINISHED = 'FINISHED',
   REVISED_WAITING_FOR_SYNC = 'REVISED_WAITING_FOR_SYNC',
   SYNCED = 'SYNCED',
   TRASH = 'TRASH',
}

export enum FormType {
   DATA_SUPPLEMENTATION = 'DATA_SUPPLEMENTATION',
   MEMBERSHIP_VALIDATION = 'MEMBERSHIP_VALIDATION',
}
