<button
   *ngIf="hasBack"
   mat-flat-button
   color="primary"
   [disabled]="backDisabled"
   (click)="back.emit()"
   >{{ 'navigation.back' | translate | uppercase }}</button
>
<button *ngIf="showCancel" mat-flat-button color="accent" (click)="cancel.emit()">{{
   'navigation.cancel' | translate | uppercase
}}</button>
<button
   *ngIf="hasNext"
   mat-flat-button
   [disabled]="nextDisabled"
   color="primary"
   (click)="next.emit()"
   >{{ 'navigation.next' | translate | uppercase }}</button
>
<button
   *ngIf="hasPay"
   mat-flat-button
   [disabled]="nextDisabled"
   color="secondary"
   (click)="pay.emit()"
   >{{ 'navigation.pay' | translate | uppercase }}</button
>
