import { Directive, HostListener, Input } from '@angular/core';

@Directive({
   selector: '[restrictInput]',
})
export class RestrictInputDirective {
   @Input('restrictInput')
   restrictedChars?: string;

   constructor() {}

   @HostListener('keydown', ['$event'])
   onKeyDown(event: KeyboardEvent) {
      if (this.restrictedChars?.includes(event.key)) {
         event.preventDefault();
      }
   }
}
