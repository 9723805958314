<mat-card
   class="example-card shadow"
   style="margin-top: 25vh; margin-left: 30vw; width: 40vw; height: 50vh"
>
   <mat-card-header>
      <mat-card-title style="margin-top: -11.5vh; margin-left: 2vw">
         <img src="../../assets/img/logo-600.png" alt="" style="max-height: 20vh" />
      </mat-card-title>
   </mat-card-header>
   <mat-card-content>
      <div align="center">
         <h1>{{ 'login.login' | translate }}</h1>
         <mat-grid-list cols="1" rowHeight="5.5rem">
            <form [formGroup]="loginForm">
               <mat-grid-tile>
                  <mat-form-field class="form-field-half" appearance="fill">
                     <mat-label class="label-color">{{ 'login.username' | translate }}</mat-label>
                     <input matInput formControlName="username" />
                  </mat-form-field>
               </mat-grid-tile>
               <mat-grid-tile>
                  <mat-form-field class="form-field-half" appearance="fill">
                     <mat-label class="label-color">{{ 'login.password' | translate }}</mat-label>
                     <input type="password" matInput formControlName="password" />
                  </mat-form-field>
               </mat-grid-tile>
               <mat-grid-tile>
                  <button
                     mat-flat-button
                     color="primary"
                     (click)="onLogin()"
                     [disabled]="loginForm.invalid"
                  >
                     {{ 'login.login' | translate }}
                  </button>
               </mat-grid-tile>
            </form>
         </mat-grid-list>
      </div>
   </mat-card-content>
</mat-card>
