<mat-card
   class="example-card shadow"
   style="margin-top: 15vh; margin-left: 15vw; width: 70vw; height: 70vh"
>
   <mat-card-header>
      <mat-card-title style="margin-top: -11.5vh; margin-left: 2vw">
         <img src="../../assets/img/logo-600.png" alt="" style="max-height: 20vh" />
      </mat-card-title>
   </mat-card-header>
   <mat-card-content>
      <div style="padding-left: 2vw; padding-top: 5vh; text-align: center">
         <h1 style="color: var(--dark-purple); font-size: 4vh">
            <b>{{ 'country.done' | translate }}</b>
         </h1>
         <img
            src="../../assets/img/payment_suc.png"
            style="max-height: 30vh; max-width: 60vw"
            alt=""
         />
         <div style="padding-top: 5vh; color: var(--dark-purple)">
            <div [innerHtml]="'payment.more' | translate"></div>
         </div>
      </div>
   </mat-card-content>
</mat-card>
