<mat-card class="example-card shadow" style="margin: 15vh 10vw">
   <mat-card-content>
      <div id="logo-wrapper">
         <img id="logo" src="../../assets/img/logo-600.png" alt="" />
      </div>
      <h2 style="color: var(--dark-purple); text-align: center"
         ><b [innerHTML]="'home.join' | translate"></b
      ></h2>
      <b>{{ 'home.fill-form' | translate }}</b>
      <br /><br />
      <form [formGroup]="form">
         <div class="row">
            <mat-form-field appearance="fill">
               <mat-label class="label-color">{{ 'data.dateOfBirth' | translate }}</mat-label>
               <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="dateOfBirth"
                  placeholder="19971231"
                  (change)="dateOfBirtOnChange($event)"
               />
               <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
               <mat-datepicker
                  display="anchored"
                  startView="year"
                  [startAt]="startDate"
                  [touchUi]="touchUi$ | async"
                  #picker
               ></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
               <mat-label class="label-color">{{ 'data.taxNumber' | translate }}</mat-label>
               <input
                  matInput
                  formControlName="taxNumber"
                  placeholder="8123456789"
                  #taxNumberInput="matInput"
                  maxlength="10"
               />
               <mat-error *ngIf="form.controls.taxNumber.hasError('leadingEight')">
                  {{ 'data.errors.taxNumber.leadingEight' | translate }}
               </mat-error>
               <mat-error *ngIf="form.controls.taxNumber.hasError('badDays')">
                  {{ 'data.errors.taxNumber.badDays' | translate }}
               </mat-error>
               <mat-error *ngIf="form.controls.taxNumber.hasError('badCheckSum')">
                  {{ 'data.errors.taxNumber.badCheckSum' | translate }}
               </mat-error>
               <mat-error *ngIf="form.controls.taxNumber.hasError('length')">
                  {{ 'data.errors.taxNumber.badLength' | translate }}
               </mat-error>
            </mat-form-field>
         </div>
      </form>
      <br />
      <button id="sign-up" mat-flat-button color="secondary" (click)="onNext()" [disabled]="form.invalid">{{
         'home.next' | translate
      }}</button>
   </mat-card-content>
</mat-card>
