import { Observable } from 'rxjs';
import { UuidType } from './uuid-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { routes } from '../../config/routes.config';

@Injectable({
   providedIn: 'root',
})
export class MissingDataService {
   constructor(private translate: TranslateService, private httpClient: HttpClient) {}

   async sendMissingDateEmail(
      id: {
         uuid: string;
         type: UuidType;
      },
      language: string,
      data: { comment: string; selectedTemplate: string } & { [key: string]: boolean },
   ): Promise<Observable<any>> {
      const missingData: string[] = [];

      const translations = await this.translate
         .getTranslation(language.toLowerCase() === 'hu' ? 'hu' : 'en')
         .toPromise();
      Object.keys(data).forEach(key => {
         if (key !== 'comment' && key != 'selectedTemplate' && data[key] === true) {
            missingData.push(translations.admin.missingDataDialog[key]);
         }
      });

      return this.httpClient.post<any>(routes.missingDataSend, {
         id,
         comment: data.comment,
         selectedTemplate: data.selectedTemplate,
         missingData: missingData,
      });
   }
}
