import { StepperOrientation, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { supportedLangToDepoLang } from '../shared/helper-functions';
import { BreakpointName, MediaService } from '../shared/services/media.service';
import { MembershipValidationService } from './membership-validation.service';
import { MatSnackBar } from '@angular/material/snack-bar';

type Media = {
   noOfCols: 1 | 2;
   orientation: StepperOrientation;
};

@Component({
   selector: 'app-membership-validation',
   templateUrl: './membership-validation.component.html',
   styleUrls: ['./membership-validation.component.css'],
})
export class MembershipValidationComponent implements OnInit, OnDestroy {
   destroyed$ = new ReplaySubject<void>(1);

   uuid: string = '';

   media$ = new BehaviorSubject<Media>({ noOfCols: 2, orientation: 'horizontal' });

   icons = ['person', 'attach_file', 'done_outline'];

   stepper!: MatStepper;

   isAnimationDisabled = false;

   isLinear = true;

   @ViewChild('stepper', { read: MatStepper })
   private set _stepper(stepper: MatStepper) {
      if (stepper) {
         this.stepper = stepper;
      }
   }

   personalDataCompleted$ = new BehaviorSubject<boolean>(false);

   uploadsCompleted$ = new BehaviorSubject<boolean>(false);

   constructor(
      private route: ActivatedRoute,
      private router: Router,
      private mediaService: MediaService,
      public translate: TranslateService,
      private validationService: MembershipValidationService,
      private snackBar: MatSnackBar,
   ) {
      this.mediaService.screen$
         .pipe(
            takeUntil(this.destroyed$),
            map<BreakpointName, Media>(screen => {
               const small =
                  screen === 'mobileM' ||
                  screen === 'phoneLandscape' ||
                  screen === 'phonePortrait' ||
                  screen === 'tabletPortrait';
               return {
                  noOfCols: small ? 1 : 2,
                  orientation: small ? 'vertical' : 'horizontal',
               };
            }),
         )
         .subscribe(this.media$);
   }

   ngOnInit() {
      this.getMembershipData();
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   onAddUUID(uuid: string) {
      localStorage.setItem('uuid', uuid);
      this.router.navigate([], {
         relativeTo: this.route,
         queryParams: {
            uuid: uuid,
         },
         queryParamsHandling: 'merge',
      });
   }

   getMembershipData() {
      this.validationService
         .initMembershipValidation(supportedLangToDepoLang(AppComponent.currentLang))
         .pipe(takeUntil(this.destroyed$))
         .subscribe({
            next: res => {
               this.uuid = res.uuid;
               this.onAddUUID(this.uuid);
            },
            error: () => {
               this.snackBar.open(this.translate.instant('generalError'), 'OK', {
                  duration: 5000,
               });
            },
         });
   }

   animationDone() {
      const stepId = this.stepper._getStepLabelId(this.stepper.selectedIndex);
      const stepElement = document.getElementById(stepId);
      if (stepElement) {
         stepElement.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
      }
   }

   selectionChange(event: StepperSelectionEvent) {
      setTimeout(() => {
         event.selectedStep.completed = false;
         if (event.previouslySelectedIndex < event.selectedIndex) {
            event.previouslySelectedStep.completed = true;
         }
      });
   }
}
