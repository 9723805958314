import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
   AbstractControl,
   AsyncValidatorFn,
   FormControl,
   FormGroup,
   ValidationErrors,
   Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import {
   MatSnackBar,
   MatSnackBarHorizontalPosition,
   MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject, forkJoin, iif, Observable, of, pipe, Subject, Subscription } from 'rxjs';
import {
   concatMap,
   debounceTime,
   delay,
   finalize,
   switchMap,
   take,
   takeUntil,
   tap,
} from 'rxjs/operators';
import { PersonModel } from '../../models/person.model';
import { RegisterComponent } from '../../register/register.component';
import { findAndSetAutosuggestValue } from '../../shared/helper-functions';
import { distinctUntilChangedAndNotify } from '../../shared/operators/distinct-until-changed-and-notify';
import { ContactService } from './contact-info.service';

interface City {
   city_name: string;
   zip_number: string;
   country_name: string;
}

@Component({
   selector: 'app-contact-info',
   templateUrl: './contact-info.component.html',
   styleUrls: ['./contact-info.component.css'],
})
export class ContactInfoComponent implements OnInit, OnDestroy {
   destroyed$ = new Subject<void>();

   @Input() uuid!: string;

   @Input() stepper!: MatStepper;

   @Input() user!: PersonModel;

   @Input() noOfCols!: number;

   @Input() completed!: BehaviorSubject<boolean>;

   @Output() refresh = new EventEmitter();

   pCities$: Observable<City[]>;

   pCitiesLoadingSpinner$: BehaviorSubject<boolean>;

   pZipLoadingSpinner$: BehaviorSubject<boolean>;

   /** a zip szám szerinti város, aminek a validátor lefutása után lesz értéke */
   pCityAccordingToZip = new BehaviorSubject('');

   pCountries$: Observable<string[]>;

   pCountrySpinner$: BehaviorSubject<boolean>;

   pStreetTypes$: Observable<string[]>;

   pStreetTypeSpinner$: BehaviorSubject<boolean>;

   mCities$: Observable<City[]>;

   mCitiesLoadingSpinner$: BehaviorSubject<boolean>;

   mZipLoadingSpinner$: BehaviorSubject<boolean>;

   mCityAccordingToZip = new BehaviorSubject('');

   mCountries$: Observable<string[]>;

   mCountrySpinner$: BehaviorSubject<boolean>;

   mStreetTypes$: Observable<string[]>;

   mStreetTypeSpinner$: BehaviorSubject<boolean>;

   permAddressForm: FormGroup;

   mailAddressForm: FormGroup;

   horizontalPosition: MatSnackBarHorizontalPosition = 'center';

   verticalPosition: MatSnackBarVerticalPosition = 'top';

   lettersRegexp = '^[a-zA-ZáÁéÉíÍóÓüÜöÖűŰúÚőŐ]*$';

   constructor(private contactService: ContactService, private _snackBar: MatSnackBar) {
      this.pZipLoadingSpinner$ = new BehaviorSubject<boolean>(false);
      this.mZipLoadingSpinner$ = new BehaviorSubject<boolean>(false);

      this.permAddressForm = new FormGroup({
         // hidden field
         addressType: new FormControl('PERMANENT', Validators.required),
         // hidden field
         uuid: new FormControl(''),
         country: new FormControl('Magyarország', [
            Validators.required,
            Validators.pattern(this.lettersRegexp),
         ]),
         zipCode: new FormControl(
            '',
            [Validators.required, Validators.pattern('^[0-9]{4}')],
            [this.zipCodeValidator(this.pZipLoadingSpinner$, this.pCityAccordingToZip).bind(this)],
         ),
         city: new FormControl('', [Validators.required, this.cityValidator.bind(this)]),
         street: new FormControl('', Validators.required),
         streetType: new FormControl('', [Validators.required, Validators.maxLength(50)]),
         number: new FormControl('', [Validators.required, Validators.maxLength(5)]),
         floor: new FormControl('', Validators.maxLength(3)),
         door: new FormControl('', Validators.maxLength(4)),
         staircase: new FormControl('', Validators.maxLength(2)),
      });

      this.mailAddressForm = new FormGroup({
         addressType: new FormControl('MAILING', Validators.required),
         uuid: new FormControl(''),
         country: new FormControl('Magyarország', [
            Validators.required,
            Validators.pattern(this.lettersRegexp),
         ]),
         zipCode: new FormControl(
            '',
            [Validators.required, Validators.pattern('^[0-9]{4}')],
            [this.zipCodeValidator(this.mZipLoadingSpinner$, this.mCityAccordingToZip).bind(this)],
         ),
         city: new FormControl('', [Validators.required, this.cityValidator.bind(this)]),
         street: new FormControl('', Validators.required),
         streetType: new FormControl('', [Validators.required, Validators.maxLength(50)]),
         number: new FormControl('', [Validators.required, Validators.maxLength(5)]),
         floor: new FormControl('', Validators.maxLength(3)),
         door: new FormControl('', Validators.maxLength(4)),
         staircase: new FormControl('', Validators.maxLength(2)),
      });

      const pFormControls = this.permAddressForm.controls;

      this.pCitiesLoadingSpinner$ = new BehaviorSubject<boolean>(false);

      this.pCities$ = pFormControls.city.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.pCitiesLoadingSpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.pCitiesLoadingSpinner$, false),
         switchMap(city => {
            if (typeof city === 'string' && city.length > 1) {
               return this.contactService.getCity(city, RegisterComponent.formLang);
            } else if (city && city.city_name && city.city_name.length > 1) {
               return this.contactService.getCity(city.city_name, RegisterComponent.formLang);
            }

            return of([]);
         }),
         startSpinner(this.pCitiesLoadingSpinner$, false),
      );

      this.pCountrySpinner$ = new BehaviorSubject<boolean>(false);

      this.pCountries$ = pFormControls.country.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.pCountrySpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.pCountrySpinner$, false),
         switchMap(name =>
            iif(() => name && name.length > 1, this.contactService.getCountry(name), of([])),
         ),
         startSpinner(this.pCountrySpinner$, false),
      );

      this.pStreetTypeSpinner$ = new BehaviorSubject<boolean>(false);

      this.pStreetTypes$ = pFormControls.streetType.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.pStreetTypeSpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.pStreetTypeSpinner$, false),
         switchMap(name =>
            iif(
               () => name && name.length > 1,
               this.contactService.getStreetType(name, RegisterComponent.formLang),
               of([]),
            ),
         ),
         startSpinner(this.pStreetTypeSpinner$, false),
      );

      // same for mailing address form
      const mFormControls = this.mailAddressForm.controls;

      this.mCitiesLoadingSpinner$ = new BehaviorSubject<boolean>(false);

      this.mCities$ = mFormControls.city.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.mCitiesLoadingSpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.mCitiesLoadingSpinner$, false),
         switchMap(city => {
            if (typeof city === 'string' && city.length > 1) {
               return this.contactService.getCity(city, RegisterComponent.formLang);
            } else if (city && city.city_name && city.city_name.length > 1) {
               return this.contactService.getCity(city.city_name, RegisterComponent.formLang);
            }

            return of([]);
         }),
         startSpinner(this.mCitiesLoadingSpinner$, false),
      );

      this.mCountrySpinner$ = new BehaviorSubject<boolean>(false);

      this.mCountries$ = mFormControls.country.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.mCountrySpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.mCountrySpinner$, false),
         switchMap(name =>
            iif(() => name && name.length > 1, this.contactService.getCountry(name), of([])),
         ),
         startSpinner(this.mCountrySpinner$, false),
      );

      this.mStreetTypeSpinner$ = new BehaviorSubject<boolean>(false);

      this.mStreetTypes$ = mFormControls.streetType.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.mStreetTypeSpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.mStreetTypeSpinner$, false),
         switchMap(name =>
            iif(
               () => name && name.length > 1,
               this.contactService.getStreetType(name, RegisterComponent.formLang),
               of([]),
            ),
         ),
         startSpinner(this.mStreetTypeSpinner$, false),
      );
   }

   ngOnInit(): void {
      this.permAddressForm.get('country')?.disable();
      this.mailAddressForm.get('country')?.disable();
      if (this.user?.addresses) {
         for (let i = 0; i < this.user.addresses.length; i++) {
            if (this.user.addresses[i].addressType === 'PERMANENT') {
               this.permAddressForm.patchValue(this.user.addresses[i]);
            } else {
               this.mailAddressForm.patchValue(this.user.addresses[i]);
            }
         }
      }
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   onSubmit() {
      if (this.permAddressForm.invalid || this.mailAddressForm.invalid) {
         this.permAddressForm.markAllAsTouched();
         this.permAddressForm.updateValueAndValidity();
         this.mailAddressForm.markAllAsTouched();
         this.mailAddressForm.updateValueAndValidity();
      } else {
         this.permAddressForm.get('country')?.enable();
         this.mailAddressForm.get('country')?.enable();
         const handleError = (err: any) => {
            this.completed.next(false);
            this._snackBar.open(
               'HTTP Error: ' + err.error.error + ': ' + err.error.message[0],
               'Ok',
               {
                  horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                  duration: 5000,
               },
            );
         };

         const submitForm = (form: FormGroup): Observable<any> => {
            const uuid = form.get('uuid')?.value;

            let params = { ...form.value };
            if (params.city && params.city.city_name !== undefined) {
               params.city = params.city.city_name;
            }

            if (!params.floor) {
               params.floor = '';
            }
            if (!params.door) {
               params.door = '';
            }

            if (!uuid) {
               params.personUuid = this.uuid;
               delete params.uuid;

               return this.contactService.postAddress(params).pipe(
                  tap(res => {
                     form.patchValue({
                        uuid: res.uuid,
                     });
                  }),
               );
            } else {
               return this.contactService.patchAddress(params);
            }
         };

         forkJoin([submitForm(this.permAddressForm), submitForm(this.mailAddressForm)]).subscribe(
            _ => {
               this.completed.next(true);
               setTimeout(() => {
                  this.permAddressForm.get('country')?.disable();
                  this.mailAddressForm.get('country')?.disable();
                  this.completed.next(false);
                  this.stepper.next();
               });
            },
            handleError,
         );
      }
   }

   onBack() {
      this.stepper.previous();
   }

   private isSameSub?: Subscription;

   private isSameSubReverse?: Subscription;

   isAddressesSameChange(isSame: boolean) {
      if (isSame) {
         // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
         const { addressType, uuid, ...rest } = this.permAddressForm.value;
         this.mailAddressForm.patchValue(rest);
         this.isSameSub = this.permAddressForm.valueChanges.subscribe(formValue => {
            Object.keys(formValue).forEach(key => {
               if (key !== 'addressType' && key !== 'uuid') {
                  this.mailAddressForm.get(key)?.setValue(formValue[key], {
                     emitEvent: false,
                  });
               }
            });
         });
         this.isSameSubReverse = this.mailAddressForm.valueChanges.subscribe(formValue => {
            Object.keys(formValue).forEach(key => {
               if (key !== 'addressType' && key !== 'uuid') {
                  this.permAddressForm.get(key)?.setValue(formValue[key], {
                     emitEvent: false,
                  });
               }
            });
         });
      } else {
         this.isSameSub?.unsubscribe();
         this.isSameSubReverse?.unsubscribe();
      }
   }

   displayCity(option: City | string | undefined) {
      if (typeof option === 'string') {
         return option;
      }
      return option ? option.city_name : '';
   }

   private cityValidator(control: AbstractControl): ValidationErrors | null {
      const city: City | string | undefined = control.value;
      let errors: any = null;

      if (typeof city === 'string' && !city.match(this.lettersRegexp)) {
         errors = { ...errors, pattern: true };
      }
      if (city && (city as City).city_name && !(city as City).city_name.match(this.lettersRegexp)) {
         errors = { ...errors, pattern: true };
      }

      return errors;
   }

   citySelected(event: MatAutocompleteSelectedEvent, input: MatInput) {
      input.ngControl.control?.setValue((event.option.value as City).zip_number);
   }

   zipCodeValidator(
      loadingSpinner: BehaviorSubject<boolean>,
      cityAccordingToZip: BehaviorSubject<string>,
   ): AsyncValidatorFn {
      /** mindig nullt ad vissza, így a form nem lesz invalid */
      return (control: AbstractControl): Observable<ValidationErrors | null> => {
         return of(null).pipe(
            tap(() => {
               loadingSpinner.next(true);
            }),
            delay(2000),
            concatMap(() => {
               const value = control.value;
               if (value)
                  return this.contactService.getCityForZip(value, RegisterComponent.formLang);
               else return of(null).pipe(take(1));
            }),
            concatMap((city: City | null) => {
               if (city === null) {
                  cityAccordingToZip.next('');
               } else {
                  setTimeout(() => {
                     cityAccordingToZip.next(city.city_name);
                  }, 0);
               }

               return of(null).pipe(take(1));
            }),
            finalize(() => {
               loadingSpinner.next(false);
            }),
         );
      };
   }

   permCityChanged(event: any) {
      if (event.target) {
         this.contactService
            .getCity(event.target.value.trim(), RegisterComponent.formLang)
            .subscribe((city: City[]) => {
               findAndSetAutosuggestValue(
                  city,
                  event.target.value.trim(),
                  false,
                  this.permAddressForm,
                  'city',
                  'city_name',
               );
            });
      }
   }

   mailCityChanged(event: any) {
      if (event.target) {
         this.contactService
            .getCity(event.target.value.trim(), RegisterComponent.formLang)
            .subscribe((city: City[]) => {
               findAndSetAutosuggestValue(
                  city,
                  event.target.value.trim(),
                  false,
                  this.mailAddressForm,
                  'city',
                  'city_name',
               );
            });
      }
   }

   permStreetTypeChanged(event: any) {
      if (event.target) {
         this.contactService
            .getStreetType(event.target.value.trim(), RegisterComponent.formLang)
            .subscribe((streetTypes: string[]) => {
               findAndSetAutosuggestValue(
                  streetTypes,
                  event.target.value.trim(),
                  true,
                  this.permAddressForm,
                  'streetType',
               );
            });
      }
   }

   mailStreetTypeChanged(event: any) {
      if (event.target) {
         this.contactService
            .getStreetType(event.target.value.trim(), RegisterComponent.formLang)
            .subscribe((streetTypes: string[]) => {
               findAndSetAutosuggestValue(
                  streetTypes,
                  event.target.value.trim(),
                  true,
                  this.mailAddressForm,
                  'streetType',
               );
            });
      }
   }
}

function startSpinner(subject: Subject<boolean>, ends = true) {
   return pipe(
      tap<any>(
         value => {
            if (value) {
               subject.next(ends);
            }
         },
         () => {
            subject.next(false);
         },
         () => {
            subject.next(false);
         },
      ),
   );
}
