<div style="margin-bottom: 2em">
   <form [formGroup]="permAddressForm">
      <h1 class="justify-center"
         >{{ 'contact.permanent-address' | translate
         }}<button
            mat-icon-button
            matSuffix
            appHelperIcon
            [helperContent]="permAddressHelper"
            [alwaysVisible]="true"
            ><mat-icon>info_outlined</mat-icon></button
         >
         <ng-template #permAddressHelper>
            <p>{{ 'contact.permanent-address-helper' | translate }}</p>
         </ng-template></h1
      >
      <mat-grid-list [cols]="noOfCols" rowHeight="5rem">
         <input type="hidden" formControlName="addressType" />
         <input type="hidden" formControlName="uuid" />

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.country' | translate }}</mat-label>
               <input
                  matInput
                  placeholder="Magyarország"
                  [matAutocomplete]="country"
                  formControlName="country"
               />
               <mat-progress-spinner
                  *ngIf="pCountrySpinner$ | async"
                  matSuffix
                  [diameter]="20"
                  mode="indeterminate"
               ></mat-progress-spinner>
               <mat-autocomplete autoActiveFirstOption #country="matAutocomplete">
                  <mat-option *ngFor="let country of pCountries$ | async" [value]="country">
                     {{ country }}
                  </mat-option>
               </mat-autocomplete>
               <mat-error>{{ 'fill' | translate }}{{ 'contact.country' | translate }}</mat-error>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.city' | translate }}</mat-label>
               <input
                  matInput
                  placeholder="Budapest"
                  [matAutocomplete]="cit"
                  formControlName="city"
                  #pCity="matInput"
                  (change)="permCityChanged($event)"
               />
               <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
               <mat-progress-spinner
                  *ngIf="pCitiesLoadingSpinner$ | async"
                  matSuffix
                  [diameter]="20"
                  mode="indeterminate"
               ></mat-progress-spinner>
               <mat-autocomplete
                  autoActiveFirstOption
                  #cit="matAutocomplete"
                  [displayWith]="displayCity"
                  (optionSelected)="citySelected($event, pZipCode)"
               >
                  <mat-option *ngFor="let city of pCities$ | async" [value]="city">
                     {{ city.city_name }} -- {{ city.zip_number }}
                  </mat-option>
               </mat-autocomplete>
               <mat-error>{{ 'fill' | translate }}{{ 'contact.city' | translate }}</mat-error>
               <mat-hint>{{ 'hint' | translate }}</mat-hint>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.zip' | translate }}</mat-label>
               <input matInput placeholder="1111" formControlName="zipCode" #pZipCode="matInput" />
               <mat-progress-spinner
                  *ngIf="pZipLoadingSpinner$ | async"
                  matSuffix
                  [diameter]="20"
                  mode="indeterminate"
               ></mat-progress-spinner>
               <mat-error>{{ 'fill' | translate }}{{ 'contact.zip' | translate }}</mat-error>
               <!-- mat hint, hogy form error nélkül is megjelenjen.. -->
               <mat-hint class="mat-error" *ngIf="(pCityAccordingToZip | async) !== pCity.value">
                  {{ 'contact.errors.nonexisting_zipcode' | translate }}
               </mat-hint>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.street' | translate }}</mat-label>
               <input
                  matInput
                  [placeholder]="'placeholder.street' | translate"
                  formControlName="street"
               />
               <mat-error>{{ 'fill' | translate }}{{ 'contact.street' | translate }}</mat-error>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.street-type' | translate }}</mat-label>
               <input
                  matInput
                  [placeholder]="'placeholder.streetType' | translate"
                  [matAutocomplete]="type"
                  formControlName="streetType"
                  maxlength="50"
                  (change)="permStreetTypeChanged($event)"
               />
               <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
               <mat-progress-spinner
                  *ngIf="pStreetTypeSpinner$ | async"
                  matSuffix
                  [diameter]="20"
                  mode="indeterminate"
               ></mat-progress-spinner>
               <mat-autocomplete autoActiveFirstOption #type="matAutocomplete">
                  <mat-option *ngFor="let type of pStreetTypes$ | async" [value]="type">
                     {{ type }}
                  </mat-option>
               </mat-autocomplete>
               <mat-error
                  >{{ 'fill' | translate }}{{ 'contact.street-type' | translate }}</mat-error
               >
               <mat-hint>{{ 'hint' | translate }}</mat-hint>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.number' | translate }}</mat-label>
               <input
                  matInput
                  placeholder="111"
                  formControlName="number"
                  maxlength="5"
                  [restrictInput]="'.!?,:;'"
               />
               <mat-error>{{ 'fill' | translate }}{{ 'contact.number' | translate }}</mat-error>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.staircase' | translate }}</mat-label>
               <input
                  matInput
                  placeholder="A"
                  formControlName="staircase"
                  maxlength="2"
                  [restrictInput]="'.!?,:;'"
               />
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.floor' | translate }}</mat-label>
               <input
                  matInput
                  placeholder="1"
                  formControlName="floor"
                  maxlength="3"
                  [restrictInput]="'.!?,:;'"
               />
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.door' | translate }}</mat-label>
               <input
                  [restrictInput]="'.!?,:;'"
                  matInput
                  placeholder="11"
                  formControlName="door"
                  maxlength="4"
               />
            </mat-form-field>
         </mat-grid-tile>
      </mat-grid-list>
   </form>
   <mat-checkbox
      id="same-address-checkbox"
      style="margin: 1rem 0"
      (change)="isAddressesSameChange($event.checked)"
      >{{ 'contact.addresses-same' | translate }}</mat-checkbox
   >
   <form [formGroup]="mailAddressForm">
      <h1 style="margin-top: 1rem" class="justify-center"
         >{{ 'contact.mailing-address' | translate
         }}<button
            mat-icon-button
            matSuffix
            appHelperIcon
            [helperContent]="mailAddressHelper"
            [alwaysVisible]="true"
            ><mat-icon>info_outlined</mat-icon></button
         >
         <ng-template #mailAddressHelper>
            <p [innerHTML]="'contact.mailing-address-helper' | translate"></p> </ng-template
      ></h1>
      <mat-grid-list [cols]="noOfCols" rowHeight="5rem">
         <input type="hidden" formControlName="addressType" />
         <input type="hidden" formControlName="uuid" />

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.country' | translate }}</mat-label>
               <input matInput [matAutocomplete]="mCountry" formControlName="country" />
               <mat-progress-spinner
                  *ngIf="mCountrySpinner$ | async"
                  matSuffix
                  [diameter]="20"
                  mode="indeterminate"
               ></mat-progress-spinner>
               <mat-autocomplete autoActiveFirstOption #mCountry="matAutocomplete">
                  <mat-option *ngFor="let country of mCountries$ | async" [value]="country">
                     {{ country }}
                  </mat-option>
               </mat-autocomplete>
               <mat-error>{{ 'fill' | translate }}{{ 'contact.country' | translate }}</mat-error>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.city' | translate }}</mat-label>
               <input
                  matInput
                  [matAutocomplete]="mCit"
                  formControlName="city"
                  #mCity="matInput"
                  (change)="mailCityChanged($event)"
               />
               <mat-progress-spinner
                  *ngIf="mCitiesLoadingSpinner$ | async"
                  matSuffix
                  [diameter]="20"
                  mode="indeterminate"
               ></mat-progress-spinner>
               <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
               <mat-autocomplete
                  autoActiveFirstOption
                  #mCit="matAutocomplete"
                  [displayWith]="displayCity"
                  (optionSelected)="citySelected($event, mZipCode)"
               >
                  <mat-option *ngFor="let city of mCities$ | async" [value]="city">
                     {{ city.city_name }} -- {{ city.zip_number }}
                  </mat-option>
               </mat-autocomplete>
               <mat-error>{{ 'fill' | translate }}{{ 'contact.city' | translate }}</mat-error>
               <mat-hint>{{ 'hint' | translate }}</mat-hint>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.zip' | translate }}</mat-label>
               <input matInput formControlName="zipCode" #mZipCode="matInput" />
               <mat-error>{{ 'fill' | translate }}{{ 'contact.zip' | translate }}</mat-error>
               <!-- mat hint, hogy form error nélkül is megjelenjen.. -->
               <mat-hint class="mat-error" *ngIf="(mCityAccordingToZip | async) !== mCity.value">
                  {{ 'contact.errors.nonexisting_zipcode' | translate }}
               </mat-hint>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.street' | translate }}</mat-label>
               <input matInput formControlName="street" />
               <mat-error>{{ 'fill' | translate }}{{ 'contact.street' | translate }}</mat-error>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.street-type' | translate }}</mat-label>
               <input
                  matInput
                  [matAutocomplete]="mType"
                  formControlName="streetType"
                  maxlength="50"
                  (change)="mailStreetTypeChanged($event)"
               />
               <mat-progress-spinner
                  *ngIf="mStreetTypeSpinner$ | async"
                  matSuffix
                  [diameter]="20"
                  mode="indeterminate"
               ></mat-progress-spinner>
               <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
               <mat-autocomplete autoActiveFirstOption #mType="matAutocomplete">
                  <mat-option *ngFor="let type of mStreetTypes$ | async" [value]="type">
                     {{ type }}
                  </mat-option>
               </mat-autocomplete>
               <mat-error
                  >{{ 'fill' | translate }}{{ 'contact.street-type' | translate }}</mat-error
               >
               <mat-hint>{{ 'hint' | translate }}</mat-hint>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.number' | translate }}</mat-label>
               <input matInput formControlName="number" maxlength="5" [restrictInput]="'.!?,:;'" />
               <mat-error>{{ 'fill' | translate }}{{ 'contact.number' | translate }}</mat-error>
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.staircase' | translate }}</mat-label>
               <input
                  matInput
                  placeholder="B"
                  formControlName="staircase"
                  maxlength="2"
                  [restrictInput]="'.!?,:;'"
               />
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.floor' | translate }}</mat-label>
               <input matInput formControlName="floor" maxlength="3" [restrictInput]="'.!?,:;'" />
            </mat-form-field>
         </mat-grid-tile>

         <mat-grid-tile>
            <mat-form-field class="form-field-half" appearance="fill">
               <mat-label class="label-color">{{ 'contact.door' | translate }}</mat-label>
               <input matInput formControlName="door" maxlength="4" [restrictInput]="'.!?,:;'" />
            </mat-form-field>
         </mat-grid-tile>
      </mat-grid-list>
   </form>
</div>
<app-stepper-navigator (back)="onBack()" (next)="onSubmit()"></app-stepper-navigator>
