import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from 'src/app/config/routes.config';
import { AppComponent } from '../app.component';
import { removeTrailingSpaceFromObjectProps } from '../shared/helper-functions';

@Injectable({
   providedIn: 'root',
})
export class MembershipValidationService {
   private readonly apiURL: string;

   constructor(private httpClient: HttpClient) {
      this.apiURL = routes.membershipValidation;
   }

   initMembershipValidation(language?: 'HU' | 'EN'): Observable<any> {
      return this.httpClient.post<any>(this.apiURL, {
         type: AppComponent.homeFormData?.useCase,
         language,
      });
   }

   patchMembershipValidation(params: Object): Observable<any> {
      removeTrailingSpaceFromObjectProps(params);

      return this.httpClient.patch<any>(this.apiURL, params);
   }
}
