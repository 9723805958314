import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from 'src/app/config/routes.config';
import { removeTrailingSpaceFromObjectProps } from '../../shared/helper-functions';
import { SupportedLanguages } from '../../shared/types/language.type';

@Injectable({
   providedIn: 'root',
})
export class PersonService {
   private readonly apiURL: string;

   private readonly cityURL: string;

   private readonly nationalityURL: string;

   private readonly bankAccountURL: string;

   constructor(private httpClient: HttpClient) {
      this.apiURL = routes.person;
      this.cityURL = routes.city;
      this.nationalityURL = routes.nationality;
      this.bankAccountURL = routes.bankAccount;
   }

   initPerson(uuid?: string): Observable<any> {
      const url = uuid ? `${this.apiURL}/${uuid}` : `${this.apiURL}`;
      return this.httpClient.get<any>(url, {});
   }

   patchPerson(params: Object): Observable<any> {
      removeTrailingSpaceFromObjectProps(params);

      return this.httpClient.patch<any>(this.apiURL, params);
   }

   getCity(
      search: string,
      lang: string,
   ): Observable<{ city_name: string; zip_number: string; country_name: string }[]> {
      return this.httpClient.get<any>(this.cityURL, { params: { name: search, lang } });
   }

   getNationality(search: string, lang: SupportedLanguages): Observable<any> {
      return this.httpClient.get(this.nationalityURL, {
         params: { name: search, lang },
      });
   }

   isAccountNumberValid(accountNumber: string): any {
      return this.httpClient.get(`${this.bankAccountURL}/valid/${accountNumber}`);
   }
}
