import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routes } from 'src/app/config/routes.config';

@Injectable({
   providedIn: 'root',
})
export class LoginService implements OnDestroy {
   destroyed$ = new Subject<void>();

   private readonly url: string;

   // eslint-disable-next-line no-unused-vars
   constructor(
      private httpClient: HttpClient,
      private router: Router,
      private route: ActivatedRoute,
   ) {
      this.url = routes.auth;
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   post(params: { username: string; password: string; url: string }): Observable<any> {
      return this.httpClient.post<any>(this.url, params);
   }

   refreshToken() {
      this.httpClient
         .patch<any>(this.url, { token: localStorage.getItem('jwt') })
         .pipe(takeUntil(this.destroyed$))
         .subscribe(r => {
            localStorage.setItem('jwt', r.token);
            localStorage.setItem('exp', JSON.parse(atob((r.token as string).split('.')[1])).exp);
         });
   }

   logout() {
      localStorage.removeItem('jwt');
      localStorage.removeItem('exp');
      localStorage.removeItem('resources');
      localStorage.removeItem('uuid');
      this.router.navigateByUrl('');
   }

   checkExpiry() {
      // const date = new Date();
      // date.setTime(parseFloat('' + localStorage.getItem('exp')) * 1000);
      // console.log(date);
      if (new Date().valueOf() > parseFloat('' + localStorage.getItem('exp')?.toString()) * 1000) {
         this.logout();
      }
   }

   isLoggedIn(): boolean {
      return (
         localStorage.getItem('jwt') !== undefined &&
         new Date().valueOf() < parseFloat('' + localStorage.getItem('exp')?.toString()) * 1000
      );
   }
}
