<form
   [formGroup]="form"
   [ngStyle]="{ 'flex-direction': orientation === 'vertical' ? 'column' : 'row' }"
>
   <div>
      <h2 style="padding-right: 2em">{{ 'others.know' | translate }}</h2>
      <ul>
         <li *ngFor="let question of answers">
            <mat-checkbox
               *ngIf="question.type === 'BOOLEAN' && question.column === 1"
               [checked]="question.answer === true"
               (change)="onAdd($event.checked, question.id, question.value, true)"
            >
               {{ currentLang === 'hu' ? question.value : question.english }}
            </mat-checkbox>
            <mat-form-field
               *ngIf="question.type === 'TEXT' && question.column === 1"
               appearance="fill"
            >
               <mat-label class="label-color">{{
                  currentLang === 'hu' ? question.value : question.english
               }}</mat-label>
               <input
                  matInput
                  formControlName="{{ 'c' + question.id }}"
                  (change)="onAdd(null, question.id, question.value, false)"
               />
            </mat-form-field>
         </li>
      </ul>
   </div>
   <div>
      <div style="flex-basis: 35%">
         <h2>{{ 'others.own' | translate }}</h2>
         <ul>
            <li *ngFor="let question of answers">
               <mat-checkbox
                  *ngIf="question.type === 'BOOLEAN' && question.column === 2"
                  [checked]="question.answer === true"
                  (change)="onAdd($event.checked, question.id, question.value, true)"
               >
                  {{ currentLang === 'hu' ? question.value : question.english }}
               </mat-checkbox>
               <mat-form-field
                  *ngIf="question.type === 'TEXT' && question.column === 2"
                  appearance="fill"
               >
                  <mat-label class="label-color">{{
                     currentLang === 'hu' ? question.value : question.english
                  }}</mat-label>
                  <input
                     matInput
                     formControlName="{{ 'c' + question.id }}"
                     (change)="onAdd(null, question.id, question.value, false)"
                  />
               </mat-form-field>
            </li>
         </ul>
      </div>
   </div>
</form>
<app-stepper-navigator (back)="onBack()" (next)="onSubmit()"></app-stepper-navigator>
