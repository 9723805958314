import { formatDate } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {
   MatSnackBar,
   MatSnackBarHorizontalPosition,
   MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PersonModel } from 'src/app/models/person.model';
import { AppComponent } from '../../app.component';
import { MediaService } from '../../shared/services/media.service';
import { MembershipValidationService } from '../membership-validation.service';

@Component({
   selector: 'app-validation-personal-data',
   templateUrl: './validation-personal-data.component.html',
   styleUrls: ['./validation-personal-data.component.css'],
})
export class ValidationPersonalDataComponent implements OnDestroy {
   destroyed$ = new Subject<void>();

   person!: PersonModel;

   validationData: any;

   personalDataForm: FormGroup;

   @Input() uuid!: string;

   @Input() stepper!: MatStepper;

   @Input() noOfCols!: number;

   @Input() completed!: BehaviorSubject<boolean>;

   horizontalPosition: MatSnackBarHorizontalPosition = 'center';

   verticalPosition: MatSnackBarVerticalPosition = 'top';

   touchUi$ = new BehaviorSubject(false);

   startDate = new Date('2000.01.01');

   formErrorStateMatcher = new (class implements ErrorStateMatcher {
      isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
         if (
            !control ||
            !form ||
            form.dirty == null ||
            form.invalid == null ||
            form.errors == null
         ) {
            return false;
         }

         let controllerHasFormError;

         if (form.control.errors) {
            controllerHasFormError = Object.keys(form.control.errors).some(err => {
               return form.control.get(err) === control;
            });
         }

         return control.touched && (controllerHasFormError || control.invalid);
      }
   })();

   constructor(
      private membershipValidationService: MembershipValidationService,
      private route: ActivatedRoute,
      private router: Router,
      private _snackBar: MatSnackBar,
      private mediaService: MediaService,
      private validationService: MembershipValidationService,
      private translateService: TranslateService,
   ) {
      if (!AppComponent.homeFormData?.taxNumber) {
         this.router.navigate(['']);
      }
      this.mediaService.screen$
         .pipe(
            takeUntil(this.destroyed$),
            map(screen => {
               return (
                  screen === 'mobileM' ||
                  screen === 'phoneLandscape' ||
                  screen === 'phonePortrait' ||
                  screen === 'tabletPortrait'
               );
            }),
         )
         .subscribe(this.touchUi$);

      this.personalDataForm = new FormGroup({
         firstName: new FormControl('', [Validators.required, Validators.maxLength(40)]),
         surname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
         middleName: new FormControl('', Validators.maxLength(40)),
         dateOfBirth: new FormControl(null, []),
         taxNumber: new FormControl('', []),
         email: new FormControl('', [
            Validators.required,
            Validators.email,
            Validators.maxLength(100),
         ]),
      });

      const formControls = this.personalDataForm.controls;
      formControls.taxNumber.disable();
      formControls.taxNumber.setValue(AppComponent.homeFormData?.taxNumber);
      formControls.dateOfBirth.disable();
      if (AppComponent.homeFormData?.dateOfBirth)
         formControls.dateOfBirth.setValue(
            formatDate(AppComponent.homeFormData?.dateOfBirth, 'yyyy. MM. dd', 'en'),
         );
   }

   onAddUUID(uuid: string) {
      localStorage.setItem('uuid', uuid);
      this.router.navigate([], {
         relativeTo: this.route,
         queryParams: {
            uuid: uuid,
         },
         queryParamsHandling: 'merge',
      });
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   goBack() {
      this.router.navigate(['membership-validation-info']);
   }

   onSubmit() {
      if (this.personalDataForm.valid && AppComponent.homeFormData?.dateOfBirth) {
         const timezoneOffset: number = new Date(
            AppComponent.homeFormData?.dateOfBirth,
         ).getTimezoneOffset();
         const newDate: Date = new Date(
            formatDate(AppComponent.homeFormData?.dateOfBirth, 'yyyy-MM-dd', 'en'),
         );
         newDate.setTime(newDate.valueOf() - timezoneOffset * 60000);
         let params = {
            uuid: this.uuid,

            name: {
               firstName: this.personalDataForm.get('firstName')?.value,
               surname: this.personalDataForm.get('surname')?.value,
               middleName: this.personalDataForm.get('middleName')?.value
                  ? this.personalDataForm.get('middleName')?.value
                  : '',
            },

            dateOfBirth: newDate,
            taxNumber: this.personalDataForm.get('taxNumber')?.value,
            email: this.personalDataForm.get('email')?.value,
         };

         const patchMembershipValidation$ = this.membershipValidationService
            .patchMembershipValidation(params)
            .pipe(takeUntil(this.destroyed$));

         patchMembershipValidation$.subscribe(
            () => {
               this.completed.next(true);
               setTimeout(() => {
                  this.completed.next(false);
                  this.stepper.next();
               });
            },
            err => {
               this.completed.next(false);
               this._snackBar.open(
                  'HTTP Error: ' + err.error.error + ': ' + err.error.message[0],
                  'Ok',
                  {
                     horizontalPosition: this.horizontalPosition,
                     verticalPosition: this.verticalPosition,
                     duration: 5000,
                  },
               );
            },
         );
      } else {
         this.personalDataForm.markAllAsTouched();
         this.personalDataForm.updateValueAndValidity();
      }
   }
}
