import {
   AfterViewInit,
   Component,
   Input,
   OnDestroy,
   OnInit,
   QueryList,
   ViewChildren,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginService } from '../../login/login.service';
import { PersonModel } from '../../models/person.model';
import { RegisterComponent } from '../../register/register.component';
import { Checks } from './entry.checks';
import { EntryService } from './entry.service';

@Component({
   selector: 'app-entry',
   templateUrl: './entry.component.html',
   styleUrls: ['./entry.component.css'],
})
export class EntryComponent implements OnInit, AfterViewInit, OnDestroy {
   destroyed$ = new Subject<void>();

   @Input() uuid!: string;

   @Input() stepper!: MatStepper;

   @Input() noOfCols!: number;

   @Input() completed!: BehaviorSubject<boolean>;

   @Input() user!: PersonModel;

   entryUuid: string = '';

   checkboxes: {
      goals: boolean;
      rules: boolean;
      status: boolean;
      changeStatus: boolean;
      join: boolean;
      contract: boolean;
      privacy: boolean;
      data: boolean;
      attachments: boolean;
      fireSafety: boolean;
   } = {
      goals: false,
      rules: false,
      status: false,
      changeStatus: false,
      join: false,
      contract: false,
      privacy: false,
      data: false,
      attachments: false,
      fireSafety: false,
   };

   isAdmin = false;

   disableData = true;

   disableContract = true;

   disableFireSafety = true;

   @ViewChildren('matCheckBox') matCheckboxes?: QueryList<MatCheckbox>;

   constructor(
      private service: EntryService,
      private router: Router,
      private loginService: LoginService,
   ) {}

   ngOnInit(): void {
      this.isAdmin = this.loginService.isLoggedIn();
   }

   ngAfterViewInit(): void {
      if (this.matCheckboxes && this.isAdmin) {
         setTimeout(() => {
            this.matCheckboxes!.forEach(cb => {
               cb.checked = true;
               cb.setDisabledState(true);
            });
         });
      }
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   onBack() {
      this.stepper.previous();
   }

   /** előre letöltjük a képet navigálás előtt,
    *  hogy szép legyen az animáció */
   loadPaymentImage(cb: () => void) {
      const paymentImage = new Image();
      paymentImage.src = 'assets/img/card-800.png';
      paymentImage.onload = cb;
      paymentImage.onerror = cb;
   }

   onSubmit() {
      if (this.user.status === 'APPLICATION_IN_PROGRESS') {
         this.service
            .register(this.uuid)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
               this.completed.next(true);
               setTimeout(() => {
                  this.loadPaymentImage(() => {
                     this.completed.next(false);
                     this.stepper.next();
                  });
               });
            });
      } else {
         this.completed.next(true);
         setTimeout(() => {
            this.loadPaymentImage(() => {
               this.completed.next(false);
               this.stepper.next();
            });
         });
      }
   }

   onCheck(status: boolean, which: keyof typeof Checks) {
      this.checkboxes[which] = status;
   }

   onEvaluate(): boolean {
      return (
         (this.checkboxes.changeStatus &&
            this.checkboxes.contract &&
            this.checkboxes.data &&
            this.checkboxes.goals &&
            this.checkboxes.join &&
            this.checkboxes.privacy &&
            this.checkboxes.rules &&
            this.checkboxes.attachments &&
            this.checkboxes.status &&
            this.checkboxes.fireSafety) ||
         this.isAdmin
      );
   }

   onData() {
      if (RegisterComponent.formLang == 'hu') {
         window.open('https://melo-depo.hu/adatvedelem', '_blank');
      } else {
         window.open('https://melo-depo.hu/privacy-policy', '_blank');
      }
      if (!this.isAdmin) {
         this.disableData = false;
      }
   }

   onContract() {
      window.open('contract', '_blank');
      if (!this.isAdmin) {
         this.disableContract = false;
      }
   }

   onFireSafety() {
      if (RegisterComponent.formLang == 'hu') {
         window.open('https://melo-depo.hu/hu/munkavedelemi-oktatas', '_blank');
      } else {
         window.open('https://melo-depo.hu/en/occupational-safety-training', '_blank');
      }

      if (!this.isAdmin) {
         this.disableFireSafety = false;
      }
   }
}
