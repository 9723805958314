import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from 'src/app/config/routes.config';

@Injectable({
   providedIn: 'root',
})
export class ValidationUploadsService {
   private readonly url: string;

   constructor(private httpClient: HttpClient) {
      this.url = routes.membershipValidationDocuments;
   }

   listDocs(uuid: string): Observable<any> {
      return this.httpClient.get<any>(routes.membershipValidationDocumentList + '/' + uuid);
   }

   finishApplication(uuid: string): Observable<any> {
      return this.httpClient.post<any>(routes.membershipValidationFinish, { uuid });
   }
}
