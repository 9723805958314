import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';

@Component({
   selector: 'app-validation-info',
   templateUrl: './validation-info.component.html',
   styleUrls: ['./validation-info.component.css'],
})
export class MemberShipValidationInfoComponent implements OnInit {
   currentLang = 'hu';

   constructor(private router: Router, private translate: TranslateService) {}

   ngOnInit(): void {
      this.currentLang = this.translate.currentLang;
      if (!AppComponent.homeFormData?.taxNumber) {
         this.router.navigate(['']);
      }
   }

   onNext() {
      this.router.navigate(['membership-validation']);
   }

   setLang() {
      if (this.currentLang === 'en') {
         this.translate.use('hu');
         this.currentLang = 'hu';
      } else {
         this.translate.use('en');
         this.currentLang = 'en';
      }
   }
}
