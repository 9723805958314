import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from 'src/app/config/routes.config';

@Injectable({
   providedIn: 'root',
})
export class UploadsService {
   private readonly uploadUrl: string;

   private readonly url: string;

   constructor(private httpClient: HttpClient) {
      this.url = routes.documents;
      this.uploadUrl = routes.upload;
   }

   upload(url: string, uuid: string, file: File, type: string): Observable<any> {
      const formData = new FormData();
      formData.append('uploads', file);
      return this.httpClient.put<any>(url + '/' + uuid + '/' + type, formData);
   }

   downloadDoc(url: string, uuid: string): Observable<any> {
      const headers = new HttpHeaders({
         'Content-Type': 'application/json',
         Accept: 'application/json',
      });
      return this.httpClient.get<any>(url + '/' + uuid, {
         headers: headers,
         responseType: 'blob' as 'json',
      });
   }

   deleteDoc(url: string, uuid: string): Observable<any> {
      return this.httpClient.delete<any>(url + '/' + uuid);
   }
}
