import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
   selector: 'app-email-body-dialog',
   templateUrl: './email-body.dialog.html',
   styleUrls: ['./email-body.dialog.css'],
})
export class EmailBodyDialogComponent {
   constructor(
      @Inject(MAT_DIALOG_DATA) public data: { body: string },
      @Optional() private dialogRef: MatDialogRef<EmailBodyDialogComponent> | null,
   ) {}

   dismiss(event: Event) {
      this.dialogRef?.close();
      event.preventDefault();
   }
}
