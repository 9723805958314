import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { routes } from '../config/routes.config';
import { Observable } from 'rxjs';
import { EmailLog } from './emailLog.model';
import { EmailLogFilterDto } from './dto/email-log-filter.dto';

@Injectable({
   providedIn: 'root',
})
export class EmailLogService {
   constructor(private httpClient: HttpClient) {}

   getList(filter: EmailLogFilterDto): Observable<{ emailLogs: EmailLog[]; count: number }> {
      return this.httpClient.post<{ emailLogs: EmailLog[]; count: number }>(
         routes.emailLog + '/list',
         filter,
      );
   }
}
