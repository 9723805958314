import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import {
   MatSnackBar,
   MatSnackBarHorizontalPosition,
   MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { BehaviorSubject, iif, Observable, of, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { RegisterComponent } from '../../register/register.component';
import {
   findAndSetAutosuggestValue,
   getGraduations,
   startSpinner,
} from '../../shared/helper-functions';
import { distinctUntilChangedAndNotify } from '../../shared/operators/distinct-until-changed-and-notify';
import { PersonGrade } from './studies.grades';
import { StudiesService } from './studies.service';

@Component({
   selector: 'app-studies',
   templateUrl: './studies.component.html',
   styleUrls: ['./studies.component.css'],
})
export class StudiesComponent implements OnDestroy, OnInit {
   destroyed$ = new Subject<void>();

   @Input() uuid!: string;

   @Input() stepper!: MatStepper;

   @Input() user!: any;

   @Input() noOfCols!: number;

   @Input() orientation!: StepperOrientation;

   @Input() completed!: BehaviorSubject<boolean>;

   institutions$: Observable<string[]>;

   institutionSpinner$: BehaviorSubject<boolean>;

   faculties$: Observable<string[]>;

   facultySpinner$: BehaviorSubject<boolean>;

   courses$: Observable<{ course_name: string }[]>;

   courseSpinner$: BehaviorSubject<boolean>;

   @ViewChild('languageInput', { read: ElementRef }) languageInput!: ElementRef<HTMLInputElement>;

   languages$: Observable<{ lang_name: string; level: string }[]>;

   languageSpinner$: BehaviorSubject<boolean>;

   // for matchip
   languages = new Set<string>();

   separatorKeysCodes: number[] = [ENTER, COMMA];

   studies!: {
      uuid: string;
      institution: string;
      grade: string;
      graduationDate: string;
      faculty: string;
      course: string;
      languages: string[];
   };

   studiesForm: FormGroup;

   horizontalPosition: MatSnackBarHorizontalPosition = 'center';

   verticalPosition: MatSnackBarVerticalPosition = 'top';

   graduations: string[];

   PersonGrade = PersonGrade;

   grades = Object.keys(PersonGrade) as (keyof typeof PersonGrade)[];

   constructor(private _snackBar: MatSnackBar, private studiesService: StudiesService) {
      this.studiesForm = new FormGroup({
         // hidden input field
         uuid: new FormControl(''),
         institution: new FormControl('', Validators.required),
         grade: new FormControl('', Validators.required),
         graduationDate: new FormControl('', Validators.required),
         faculty: new FormControl(''),
         course: new FormControl(''),
         language: new FormControl(''),
         others: new FormControl(''),
      });

      const formControls = this.studiesForm.controls;

      this.institutionSpinner$ = new BehaviorSubject<boolean>(false);
      this.institutions$ = formControls.institution.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.institutionSpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.institutionSpinner$, false),
         switchMap(name =>
            iif(
               () => name && name.length > 1,
               this.studiesService.getSchool(name, RegisterComponent.formLang),
               of([]),
            ),
         ),
         startSpinner(this.institutionSpinner$, false),
      );

      this.facultySpinner$ = new BehaviorSubject<boolean>(false);
      this.faculties$ = formControls.faculty.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.facultySpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.facultySpinner$, false),
         switchMap(name =>
            iif(
               () => name && name.length > 1,
               this.studiesService.getFaculty(name, RegisterComponent.formLang),
               of([]),
            ),
         ),
         startSpinner(this.facultySpinner$, false),
      );

      this.courseSpinner$ = new BehaviorSubject<boolean>(false);
      this.courses$ = formControls.course.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.courseSpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.courseSpinner$, false),
         switchMap(name =>
            iif(
               () => name && name.length > 1,
               this.studiesService.getCourse(name, RegisterComponent.formLang),
               of([]),
            ),
         ),
         map(courses => courses.map(m => m.course_name)),
         startSpinner(this.courseSpinner$, false),
      );

      this.languageSpinner$ = new BehaviorSubject<boolean>(false);
      this.languages$ = formControls.language.valueChanges.pipe(
         takeUntil(this.destroyed$),
         startSpinner(this.languageSpinner$),
         debounceTime(1000),
         distinctUntilChangedAndNotify(this.languageSpinner$, false),
         switchMap(name =>
            iif(
               () => name && name.length > 1,
               this.studiesService.getLanguage(name, RegisterComponent.formLang),
            ),
         ),
         startSpinner(this.languageSpinner$, false),
      );

      this.graduations = getGraduations();
   }

   ngOnInit(): void {
      this.studies = this.user ? this.user.study : null;
      if (this.studies) {
         this.studiesForm.patchValue(this.studies);
         this.languages = new Set(this.studies.languages);
         if (this.languages.size === 3) {
            this.studiesForm.get('language')?.disable();
         }
      }
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   addLanguage(event: MatChipInputEvent) {
      if (event.value) {
         this.languages.add(event.value);
         event.chipInput?.clear();
      }
   }

   removeLanguage(language: string) {
      this.languages.delete(language);
   }

   languageSelected(event: MatAutocompleteSelectedEvent): void {
      this.languages.add(event.option.viewValue);
      this.languageInput.nativeElement.value = '';
      this.studiesForm.controls.language.setValue(null);
   }

   onBack() {
      this.stepper.previous();
   }

   onSubmit() {
      //if user wouldn't have pressed enter when finished typeing on language input field, then it wouldn't be added to the chiplist so we do it manually
      let langVal: string = this.studiesForm.controls.language.value;
      if (langVal && langVal.length > 0 && !this.languages.has(langVal)) {
         this.languages.add(langVal);
         this.languageInput.nativeElement.value = '';
         this.studiesForm.controls.language.setValue(null);
      }

      if (this.studiesForm.invalid) {
         this.studiesForm.markAllAsTouched();
         this.studiesForm.updateValueAndValidity();
      } else {
         let params = { ...this.studiesForm.value };
         params.language = undefined;
         if (params.faculty === '') {
            params.faculty = '';
         }
         if (params.course === '') {
            params.course = '';
         }
         params = { ...params, languages: [...this.languages] };
         if (params.uuid) {
            this.studiesService.patchStudies(params).subscribe(
               _res => {
                  this.completed.next(true);
                  setTimeout(() => {
                     this.completed.next(false);
                     this.stepper.next();
                  });
               },
               err => {
                  this.completed.next(false);
                  this._snackBar.open(
                     'HTTP Error: ' + err.error.error + ': ' + err.error.message[0],
                     'Ok',
                     {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 5000,
                     },
                  );
               },
            );
         } else {
            params = { ...params, personUuid: this.uuid };
            delete params.uuid;

            this.studiesService.postStudies(params).subscribe(
               res => {
                  this.studiesForm.patchValue({
                     uuid: res.uuid,
                  });
                  this.completed.next(true);
                  setTimeout(() => {
                     this.completed.next(false);
                     this.stepper.next();
                  });
               },
               err => {
                  this.completed.next(false);
                  this._snackBar.open(
                     'HTTP Error: ' + err.error.error + ': ' + err.error.message[0],
                     'Ok',
                     {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 5000,
                     },
                  );
               },
            );
         }
      }
   }

   institutionChanged(event: any) {
      if (event.target) {
         this.studiesService
            .getSchool(event.target.value.trim(), RegisterComponent.formLang)
            .subscribe((schools: string[]) => {
               findAndSetAutosuggestValue(
                  schools,
                  event.target.value.trim(),
                  true,
                  this.studiesForm,
                  'institution',
               );
            });
      }
   }

   facultyChanged(event: any) {
      if (event.target) {
         this.studiesService
            .getFaculty(event.target.value.trim(), RegisterComponent.formLang)
            .subscribe((faculties: string[]) => {
               findAndSetAutosuggestValue(
                  faculties,
                  event.target.value.trim(),
                  true,
                  this.studiesForm,
                  'faculty',
               );
            });
      }
   }

   courseChanged(event: any) {
      if (event.target) {
         this.studiesService
            .getCourse(event.target.value.trim(), RegisterComponent.formLang)
            .subscribe((courses: { course_name: string }[]) => {
               findAndSetAutosuggestValue(
                  courses,
                  event.target.value.trim(),
                  false,
                  this.studiesForm,
                  'course',
                  'course_name',
               );
            });
      }
   }
}
