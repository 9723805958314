/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
   MatSnackBar,
   MatSnackBarHorizontalPosition,
   MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { OthersService } from './others.service';

@Component({
   selector: 'app-others',
   templateUrl: './others.component.html',
   styleUrls: ['./others.component.css'],
})
export class OthersComponent implements OnInit, OnDestroy {
   destroyed$ = new Subject<void>();

   @Input() uuid!: string;

   @Input() stepper!: MatStepper;

   @Input() user!: any;

   @Input() noOfCols!: number;

   @Input() orientation!: StepperOrientation;

   @Input() completed!: BehaviorSubject<boolean>;

   questions: {
      id: number;
      column: number;
      value: string;
      type: string;
      english: string;
   }[] = [];

   answers: {
      id: number;
      value: string;
      answer: boolean | string;
      type: string;
      column: number;
      english: string;
   }[] = [];

   form = new FormGroup({});

   horizontalPosition: MatSnackBarHorizontalPosition = 'center';

   verticalPosition: MatSnackBarVerticalPosition = 'top';

   currentLang = 'hu';

   constructor(
      private service: OthersService,
      private _snackBar: MatSnackBar,
      public translate: TranslateService,
   ) {
      translate.onLangChange
         .pipe(distinctUntilChanged(undefined as any, _ => _.lang))
         .subscribe((event: LangChangeEvent) => {
            this.currentLang = event.lang;
         });
   }

   ngOnInit(): void {
      this.currentLang = this.translate.currentLang;
      this.service
         .getQuestions()
         .pipe(
            takeUntil(this.destroyed$),
            concatMap(questions => {
               this.questions = questions;
               return this.service.getAnswers(this.uuid);
            }),
         )
         .subscribe(res => {
            this.questions.forEach(q => {
               const ans = res[res.findIndex(r => r.question === q.value)];
               if (q.type === 'TEXT') {
                  this.form.addControl(
                     'c' + q.id,
                     new FormControl(ans && ans.answer ? ans.answer : ''),
                  );
                  this.answers.push({
                     id: q.id,
                     value: q.value,
                     column: q.column,
                     type: q.type,
                     answer: ans ? ans.answer : '',
                     english: q.english,
                  });
               } else {
                  this.answers.push({
                     id: q.id,
                     value: q.value,
                     column: q.column,
                     type: q.type,
                     answer: ans && ans.answer === 'true' ? true : false,
                     english: q.english,
                  });
               }
            });
         });
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   onAdd(event: any, id: number, value: string, boolean: boolean) {
      const i = this.answers.findIndex(b => b.id === id);
      if (boolean) {
         if (i > -1) {
            this.answers[i].answer = event;
         }
      } else {
         if (i > -1) {
            this.answers[i].answer = this.form.get(value)?.value;
         }
      }
   }

   onBack() {
      this.stepper.previous();
   }

   onSubmit() {
      let params: {
         personUuid: string;
         questionAnswers: { question: string; answer: string }[];
      } = { personUuid: this.uuid, questionAnswers: [] };
      this.answers.forEach(a => {
         params.questionAnswers.push({
            question: a.value,
            answer: a.type === 'BOOLEAN' ? a.answer.toString() : this.form.get('c' + a.id)?.value,
         });
      });
      this.service
         .postAnswer(params)
         .pipe(takeUntil(this.destroyed$))
         .subscribe(
            res => {
               this.completed.next(true);
               setTimeout(() => {
                  this.completed.next(false);
                  this.stepper.next();
               });
            },
            err => {
               this.completed.next(false);
               this._snackBar.open(
                  'HTTP Error: ' + err.error.error + ': ' + err.error.message[0],
                  'Ok',
                  {
                     horizontalPosition: this.horizontalPosition,
                     verticalPosition: this.verticalPosition,
                     duration: 5000,
                  },
               );
            },
         );
   }
}
