import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { PaymentService } from './payment.service';

@Component({
   selector: 'app-payment',
   templateUrl: './payment.component.html',
   styleUrls: ['./payment.component.css'],
   animations: [
      trigger('enterAnimation', [
         transition(':enter', [
            style({ transform: 'translateY(100%)', opacity: 0 }),
            animate('250ms', style({ transform: 'translateY(0)', opacity: 1 })),
         ]),
      ]),
   ],
})
export class PaymentComponent implements OnDestroy {
   destroyed$ = new Subject<void>();

   @Input() uuid!: string;

   @Input() status: string | undefined;

   @Input() stepper!: MatStepper;

   @Input() noOfCols!: number;

   @Input() animationDone = false;

   @Input() needToPay!: boolean;

   paymentDisabled = false;

   tooltip = `<div class="color-red">Top Template<button style="background: white; color: black">Press me</button></div>`;

   constructor(
      private service: PaymentService,
      private router: Router,
      private snackbar: MatSnackBar,
      private translate: TranslateService,
   ) {}

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   onBack() {
      this.stepper.previous();
   }

   onSubmit() {
      this.paymentDisabled = true;
      if (this.status === 'ACTIVE') {
         // if already paid
         this.translate.get('payment.already-paid').subscribe(message => {
            this.snackbar.open(message, 'x', {
               verticalPosition: 'top',
               horizontalPosition: 'center',
               duration: 10000,
            });
            this.router
               .navigate(['payment/finish-payment'], {
                  queryParams: { suc: true, uuid: this.uuid },
               })
               .finally(() => (this.paymentDisabled = false));
         });
         return;
      } else if (this.status === 'OLD_PAYED') {
         // if already paid
         this.translate.get('payment.already-paid').subscribe(message => {
            this.snackbar.open(message, 'x', {
               verticalPosition: 'top',
               horizontalPosition: 'center',
               duration: 10000,
            });
            this.router
               .navigate(['payment/finish-payment'], {
                  queryParams: { uuid: this.uuid },
               })
               .finally(() => (this.paymentDisabled = false));
         });
         return;
      }
      this.service
         .post(this.uuid)
         .pipe(
            takeUntil(this.destroyed$),
            finalize(() => (this.paymentDisabled = false)),
         )
         .subscribe(res => {
            window.location.href = res.paymentUrl;
         });
   }
}
