<img id="logo" src="../../assets/img/logo-600.png" alt="" />
<div
   [ngClass]="media.orientation === 'horizontal' ? 'web' : 'phone'"
   *ngIf="media$ | async as media;"
>
   <mat-stepper
      [linear]="isLinear"
      [@.disabled]="isAnimationDisabled"
      labelPosition="bottom"
      [orientation]="media.orientation"
      style="background-color: rgba(255, 255, 255, 0)"
      #stepper
      (animationDone)="animationDone()"
      (selectionChange)="selectionChange($event)"
   >
      <ng-template matStepperIcon="number" let-index="index">
         <mat-icon>{{ icons[index] }}</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="edit" let-index="index">
         <mat-icon>{{ icons[index] }}</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done" let-index="index">
         <mat-icon>{{ icons[index] }}</mat-icon>
      </ng-template>

      <mat-step
         label="{{ 'membershipValidation.data' | translate }}"
         [completed]="personalDataCompleted$ | async"
      >
         <app-validation-personal-data
            [uuid]="uuid"
            [stepper]="stepper"
            [noOfCols]="media.noOfCols"
            [completed]="personalDataCompleted$"
         >
         </app-validation-personal-data>
      </mat-step>
      <mat-step
         label="{{ 'membershipValidation.documents' | translate }}"
         [completed]="uploadsCompleted$ | async"
      >
         <app-validation-uploads *ngIf="uuid"
            [uuid]="uuid"
            [stepper]="stepper"
            [noOfCols]="media.noOfCols"
            [orientation]="media.orientation"
            [admin]="false"
            [completed]="uploadsCompleted$"
         ></app-validation-uploads>
      </mat-step>
   </mat-stepper>
</div>
