import { Injectable } from '@angular/core';
import {
   ActivatedRouteSnapshot,
   CanActivate,
   Router,
   RouterStateSnapshot,
   UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class AuthGuard implements CanActivate {
   constructor(private router: Router) {}

   canActivate(
      _route: ActivatedRouteSnapshot,
      _state: RouterStateSnapshot,
   ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (localStorage.getItem('jwt')) {
         return true;
      }
      // return true;
      return this.router.parseUrl('');
   }
}
