import { Component } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
   selector: 'app-contract',
   templateUrl: './contract.component.html',
   styleUrls: ['./contract.component.css'],
})
export class ContractComponent {
   lang = localStorage.getItem('language');

   constructor(public translate: TranslateService) {
      translate.onLangChange
         .pipe(distinctUntilChanged(undefined as any, _ => _.lang))
         .subscribe((event: LangChangeEvent) => {
            this.lang = event.lang;
         });
   }
}
