export function checkStringIsDate($event: any): Date | undefined {
   if ($event.srcElement && $event.srcElement.value && $event.srcElement.value.length >= 6) {
      let year: number;
      let month: number;
      let day: number;
      const input: string = $event.srcElement.value;
      if (input.includes('-') || input.includes('.') || input.includes('/') || input.includes('\\'))
         return;
      try {
         year = Number(input.substring(0, 4));
         if (input.length === 8) {
            month = Number(input.substring(4, 6)) - 1;
            day = Number(input.substring(6));
         } else {
            month = Number(input.substring(4, 5)) - 1;
            day = Number(input.substring(5));
         }
         return new Date(year, month, day);
      } catch (e) {
         return;
      }
   }
   return;
}
