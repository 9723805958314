import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
   providedIn: 'root',
})
export class ServerErrorInterceptor implements HttpInterceptor {
   constructor(
      private snackbar: MatSnackBar,
      private zone: NgZone,
   ) {}

   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      return next.handle(request).pipe(
         catchError(error => {
            let message = 'Error';
            if (error?.error) {
               if (error.error?.message) {
                  message = error.error.message;
               }
               if (error.error?.statusCode) {
                  message = error.error.statusCode + ' ' + message;
               }
            }

            this.zone.run(() => {
               this.snackbar.open(message, 'x', {
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
                  duration: 10000,
               });
            });
            return throwError(error);
         }),
      );
   }
}
