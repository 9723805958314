import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
   selector: 'app-form-errors',
   templateUrl: './form-errors.component.html',
   styleUrls: ['./form-errors.component.css'],
})
export class FormErrorsComponent {
   @Input() form?: FormGroup;

   public Object = Object;

   constructor() {}

   logFormValue() {
      console.log(this.form?.value);
   }
}
