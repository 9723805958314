import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
   selector: 'app-stepper-navigator',
   templateUrl: './stepper-navigator.component.html',
   styleUrls: ['./stepper-navigator.component.css'],
})
export class StepperNavigatorComponent {
   @Input() hasBack = true;

   @Input() hasNext = true;

   @Input() hasPay = false;

   @Input() nextDisabled = false;

   @Input() backDisabled = false;

   @Input() showCancel = false;

   @Output() back = new EventEmitter<void>();

   @Output() next = new EventEmitter<void>();

   @Output() pay = new EventEmitter<void>();

   @Output() cancel = new EventEmitter<void>();

   constructor() {}
}
