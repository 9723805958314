<mat-card
   class="example-card shadow"
   style="margin-top: 15vh; margin-left: 15vw; width: 70vw"
   *ngIf="lang === 'hu'"
>
   <p
      >Mint a MEL&Oacute;-DEP&Oacute; 2000 Iskolai Sz&ouml;vetkezet (2161 Csom&aacute;d, Verebeshegy
      u. 11.) tagja (tov&aacute;bbiakban, mint Tag), &eacute;s m&aacute;sr&eacute;szről a
      MEL&Oacute;-DEP&Oacute; 2000 Iskolai Sz&ouml;vetkezet (2161 Csom&aacute;d, Verebeshegy u. 11.)
      k&ouml;z&ouml;tt az al&aacute;bbi felt&eacute;telekkel:</p
   >
   <ol>
      <li
         >A Tag &aacute;ltal v&aacute;llalt feladatok k&ouml;re: minden olyan jellegű munka mely
         szak&eacute;rtelm&eacute;nek &eacute;s tud&aacute;s&aacute;nak, adotts&aacute;gainak
         megfelelő (jellemzően betan&iacute;tott munka). A meg&aacute;llapod&aacute;s c&eacute;lja
         az iskolasz&ouml;vetkezet &aacute;ltal harmadik szem&eacute;ly r&eacute;sz&eacute;re
         ny&uacute;jtott szolg&aacute;ltat&aacute;s teljes&iacute;t&eacute;se
         &eacute;rdek&eacute;ben a Tag a harmadik szem&eacute;lyn&eacute;l munk&aacute;t
         v&eacute;gezzen. A Tag munkav&eacute;gz&eacute;si k&ouml;telezetts&eacute;ge
         teljes&iacute;t&eacute;s&eacute;ről esetenk&eacute;nt &aacute;llapodnak meg.</li
      >
      <li style="margin-top: 5px"
         >A harmadik szem&eacute;lyn&eacute;l t&ouml;rt&eacute;nő szolg&aacute;ltat&aacute;s
         teljes&iacute;t&eacute;s&eacute;nek időtartam&aacute;ra j&aacute;r&oacute; szem&eacute;lyi
         alapb&eacute;rek legkisebb m&eacute;rt&eacute;ke a mindenkor &eacute;rv&eacute;nyes
         minim&aacute;l &oacute;rab&eacute;r. Egyedi esetben teljes&iacute;tm&eacute;nyb&eacute;r is
         kifizet&eacute;sre ker&uuml;lhet. A d&iacute;jaz&aacute;s kifizet&eacute;se
         banksz&aacute;ml&aacute;ra &aacute;tutal&aacute;ssal t&ouml;rt&eacute;nik a
         t&aacute;rgyh&oacute;t k&ouml;vető h&oacute; kijel&ouml;lt fizet&eacute;si napj&aacute;n. A
         fizet&eacute;si napok &eacute;vente előre ker&uuml;lnek meg&aacute;llap&iacute;t&aacute;sra
         &eacute;s a sz&ouml;vetkezet helyis&eacute;geiben valamint honlapj&aacute;n ker&uuml;l
         kihirdet&eacute;sre. A b&eacute;rjegyz&eacute;k &ndash; azonos&iacute;t&aacute;st
         k&ouml;vetően &ndash; let&ouml;lthető a
         <a href="https://melo-depo.hu">www.melo-depo.hu</a> honlapr&oacute;l, illetve
         &aacute;tvehető az &aacute;tutal&aacute;st k&ouml;vető munkanapt&oacute;l a
         Mel&oacute;-Dep&oacute; 2000 Iskolai Sz&ouml;vetkezet irod&aacute;j&aacute;ban.</li
      >
      <li style="margin-top: 5px"
         >A Tag k&ouml;teles a t&eacute;mavezető &aacute;ltal megjel&ouml;lt helyen &eacute;s időben
         megjelenni, abb&oacute;l a c&eacute;lb&oacute;l, hogy az elv&eacute;gzendő munk&aacute;t
         (tev&eacute;kenys&eacute;get &eacute;s feladatait) a felk&eacute;sz&iacute;t&eacute;sen
         megismerje. A felek meg&aacute;llapodnak, hogy amennyiben a Tag ezen időpontban a
         felk&eacute;sz&iacute;t&eacute;sen nem jelenik meg, a munkav&eacute;gz&eacute;s
         l&eacute;nyeges k&ouml;r&uuml;lm&eacute;nyeit nem ismeri meg, &iacute;gy az adott
         meg&aacute;llapod&aacute;st az Iskolai Sz&ouml;vetkezet jogosult azonnali hat&aacute;llyal
         felmondani.</li
      >
      <li style="margin-top: 5px"
         >A Tag munkav&eacute;gz&eacute;se sor&aacute;n k&ouml;teles
         tev&eacute;kenys&eacute;g&eacute;t a jelen szerződ&eacute;sben r&ouml;gz&iacute;tett
         felt&eacute;teleknek megfelelően &eacute;s harmadik f&eacute;l utas&iacute;t&aacute;sa
         alapj&aacute;n ell&aacute;tni. A Tag feladat&aacute;ba tartoz&oacute;
         tev&eacute;kenys&eacute;gek v&eacute;gz&eacute;se k&ouml;zben polg&aacute;ri
         t&ouml;rv&eacute;nyk&ouml;nyv szerinti felelőss&eacute;ggel tartozik. A hat&aacute;lyos
         jogszab&aacute;lyok alapj&aacute;n a szem&eacute;lyi j&ouml;vedelemad&oacute;t &eacute;s
         egy&eacute;b terheket a sz&ouml;vetkezet levonja. A Tag k&ouml;teles a munk&aacute;ja
         sor&aacute;n tudom&aacute;s&aacute;ra jutott &uuml;zemi (&uuml;zleti) titkot, valamint a
         munk&aacute;ltat&oacute;j&aacute;ra, &eacute;s a partner c&eacute;gekre, illetve a
         tev&eacute;kenys&eacute;g&eacute;re vonatkoz&oacute; inform&aacute;ci&oacute;kat megőrizni.
         Ezen t&uacute;lmenően sem k&ouml;z&ouml;lhet harmadik szem&eacute;llyel olyan adatokat,
         amelyek munkav&eacute;gz&eacute;s&eacute;vel &ouml;sszef&uuml;gg&eacute;sben jutottak
         tudom&aacute;s&aacute;ra.</li
      >
      <li style="margin-top: 5px"
         >Felek kapcsolattart&aacute;sa a Tag munkav&eacute;gz&eacute;si k&ouml;teless&eacute;ge
         sz&uuml;netel&eacute;s&eacute;nek idej&eacute;n e-mailben vagy telefonon kereszt&uuml;l
         t&ouml;rt&eacute;nik.</li
      >
      <li style="margin-top: 5px"
         >A sz&ouml;vetkezet alapszab&aacute;lya &eacute;rtelm&eacute;ben a Tag addig
         v&eacute;gezhet munk&aacute;t a sz&ouml;vetkezetn&eacute;l, m&iacute;g iskolarendszerű
         nappalis k&eacute;pz&eacute;sen vesz r&eacute;szt, &eacute;s ezt a t&eacute;nyt megfelelően
         igazolja. Az igazol&aacute;s elmarad&aacute;sa eset&eacute;n a tags&aacute;gi viszony
         &eacute;s a munkaszerződ&eacute;s is megsz&uuml;ntet&eacute;sre ker&uuml;l. Tag
         v&aacute;llalja, hogy nappali tagozatos st&aacute;tusz&aacute;nak megszűn&eacute;s&eacute;t
         azonnal jelzi az Iskolai Sz&ouml;vetkezet fel&eacute;.</li
      >
   </ol>
</mat-card>

<mat-card
   class="example-card shadow"
   style="margin-top: 15vh; margin-left: 15vw; width: 70vw"
   *ngIf="lang === 'en'"
>
   <p
      >As a member of MEL&Oacute;-DEP&Oacute; 2000 School Cooperative (2161 Csom&aacute;d,
      Verebeshegy u. 11.) (hereinafter referred to as Member), and on the other hand between
      MEL&Oacute;-DEP&Oacute; 2000 School Cooperative (2161 Csom&aacute;d, Verebeshegy u. 11.), with
      the following terms and conditions:
   </p>
   <ol>
      <li
         >Tasks undertaken by the Member: all kinds of work, that are suitable for the Member&#39;s
         knowledge, skills, and abilities (typically being semi-skilled works). The purpose of this
         agreement is to do work for third parties by the Member, during School Cooperative provides
         services to them. The Parties agree to the performance of worker obligations of the Member
         on a case-by-case basis.</li
      >
      <li style="margin-top: 5px"
         >The lowest personal base salary that can be paid for providing services to third parties
         is the current national minimum wage per hour. In a specific case, performance-based pay
         can be disbursed. The salary is going to be transferred to your bank account on the fixed
         payment date of the month following the subject month. The payment days are appointed one
         year ahead, what you can find in the school cooperative&#39;s office and on its website.
         The pay-list – after identity check – can be downloaded from
         <a href="https://melo-depo.hu">www.melo-depo.hu</a> , or can be received on the next
         working day after the bank transfer day in the office of Mel&oacute;-Dep&oacute; 2000
         Iskolai School Cooperative.</li
      >
      <li style="margin-top: 5px"
         >The Member shall appear at the place and time specified by the supervisor in order to get
         to know the work (activities and tasks) in question during the preparation. The Parties
         agree to may be terminated this Agreement with immediate effect by the School Cooperative,
         in case the Member doesn&#39;t appear in the certain time of preparation and miss knowing
         the essential circumstances of work.
      </li>
      <li style="margin-top: 5px"
         >During work, the Member shall carry out the activities in accordance with the terms of
         this agreement and the instructions of third parties. The Member has civil liability, while
         performing activities that are part of his or her duties. The cooperative deducts the
         Personal Income Tax and other encumbrances in compliance with the currently effective
         Hungarian laws. The Member shall maintain confidentiality in relation to operational
         (business) secrets; information about the employer and its partners, as well as its
         activities obtained in the course of his/her work. Moreover, he/she shall not disclose to a
         third party any data got in connection with his/her work.</li
      >
      <li style="margin-top: 5px"
         >The Parties are contacted by email and on the phone, at the time the Member&#39;s work
         duty is interrupted.</li
      >
      <li style="margin-top: 5px"
         >According to the statutes of the cooperative, the Member is permitted to work in the
         cooperative, until receiving full-time school education and this fact being appropriately
         verified. In the case of non-certification, the membership relationship and the employment
         contract will also be terminated. The Member undertakes to inform the School Cooperative
         immediately, when his/her full-time student status comes to the end.</li
      >
   </ol>
</mat-card>
