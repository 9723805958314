<form [formGroup]="studiesForm">
   <input type="hidden" formControlName="uuid" />
   <mat-grid-list cols="{{ noOfCols }}" rowHeight="5rem">
      <mat-grid-tile>
         <mat-form-field class="form-field-half" appearance="fill">
            <mat-label class="label-color">{{ 'studies.name' | translate }}</mat-label>
            <input
               type="text"
               matInput
               [placeholder]="'placeholder.institution' | translate"
               [matAutocomplete]="institution"
               formControlName="institution"
               #institutionInput="matInput"
               (change)="institutionChanged($event)"
            />
            <mat-progress-spinner
               *ngIf="institutionSpinner$ | async"
               matSuffix
               [diameter]="20"
               mode="indeterminate"
            ></mat-progress-spinner>
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="institutionInput"
               [helperContent]="institutionHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #institutionHelper>
               <p>{{ 'studies.institution-helper' | translate }}</p>
            </ng-template>
            <mat-autocomplete autoActiveFirstOption #institution="matAutocomplete">
               <mat-option *ngFor="let institution of institutions$ | async" [value]="institution">
                  {{ institution }}
               </mat-option>
            </mat-autocomplete>
            <mat-hint>{{ 'hint' | translate }}</mat-hint>
            <mat-error>{{ 'fill' | translate }}{{ 'studies.name' | translate }}</mat-error>
         </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile>
         <mat-form-field class="form-field-half" appearance="fill">
            <mat-label>{{ 'studies.year' | translate }}</mat-label>
            <select matNativeControl required formControlName="grade">
               <option [value]="grade" *ngFor="let grade of grades">
                  {{ PersonGrade[grade] }}
               </option>
            </select>
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [helperContent]="gradeHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #gradeHelper>
               <p>{{ 'studies.grade-helper' | translate }}</p>
            </ng-template>
            <mat-error>{{ 'fill' | translate }}{{ 'studies.year' | translate }}</mat-error>
         </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile>
         <mat-form-field class="form-field-half" appearance="fill">
            <mat-label>{{ 'studies.end-date' | translate }}</mat-label>
            <select matNativeControl required formControlName="graduationDate">
               <option [value]="graduation" *ngFor="let graduation of graduations">
                  {{ graduation }}
               </option>
            </select>
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [helperContent]="graduationHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #graduationHelper>
               <p>{{ 'studies.graduation-helper' | translate }}</p>
            </ng-template>
            <mat-error>{{ 'fill' | translate }}{{ 'studies.end-date' | translate }}</mat-error>
         </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile>
         <mat-form-field class="form-field-half" appearance="fill">
            <mat-label class="label-color">{{ 'studies.fac' | translate }}</mat-label>
            <input
               type="text"
               matInput
               [placeholder]="'placeholder.faculty' | translate"
               [matAutocomplete]="faculty"
               formControlName="faculty"
               #facultyInput="matInput"
               (change)="facultyChanged($event)"
            />
            <mat-progress-spinner
               *ngIf="facultySpinner$ | async"
               matSuffix
               [diameter]="20"
               mode="indeterminate"
            ></mat-progress-spinner>
            <button
               mat-icon-button
               matSuffix
               appHelperIcon
               [matInput]="facultyInput"
               [helperContent]="facultyHelper"
               [alwaysVisible]="true"
               ><mat-icon>info_outlined</mat-icon></button
            >
            <ng-template #facultyHelper>
               <p>{{ 'studies.faculty-helper' | translate }}</p>
            </ng-template>
            <mat-autocomplete autoActiveFirstOption #faculty="matAutocomplete">
               <mat-option *ngFor="let faculty of faculties$ | async" [value]="faculty">
                  {{ faculty }}
               </mat-option>
            </mat-autocomplete>
            <mat-hint>{{ 'hint' | translate }}</mat-hint>
         </mat-form-field>
      </mat-grid-tile>
   </mat-grid-list>
   <div
      [ngClass]="{
         column: orientation === 'horizontal',
         row: orientation === 'vertical'
      }"
   >
      <mat-form-field class="form-field-half" appearance="fill">
         <mat-label class="label-color">{{ 'studies.course' | translate }}</mat-label>
         <input
            type="text"
            matInput
            [placeholder]="'placeholder.course' | translate"
            [matAutocomplete]="course"
            formControlName="course"
            #courseInput="matInput"
            (change)="courseChanged($event)"
         />
         <mat-progress-spinner
            *ngIf="courseSpinner$ | async"
            matSuffix
            [diameter]="20"
            mode="indeterminate"
         ></mat-progress-spinner>
         <button
            mat-icon-button
            matSuffix
            appHelperIcon
            [matInput]="courseInput"
            [helperContent]="courseHelper"
            [alwaysVisible]="true"
            ><mat-icon>info_outlined</mat-icon></button
         >
         <ng-template #courseHelper>
            <p>{{ 'studies.course-helper' | translate }}</p>
         </ng-template>
         <mat-autocomplete autoActiveFirstOption #course="matAutocomplete">
            <mat-option *ngFor="let course of courses$ | async" [value]="course">
               {{ course }}
            </mat-option>
         </mat-autocomplete>
         <mat-hint>{{ 'hint' | translate }}</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="fill">
         <mat-label class="label-color">{{ 'studies.language' | translate }}</mat-label>
         <mat-chip-list #studiesChipList class="mat-chip-list-stacked">
            <mat-chip *ngFor="let language of languages" (removed)="removeLanguage(language)"
               >{{ language }}
               <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
               </button>
            </mat-chip>
            <input
               type="text"
               matInput
               [placeholder]="'placeholder.languageLevel' | translate"
               [matAutocomplete]="lang"
               formControlName="language"
               #languageInput="matInput"
               [matChipInputFor]="studiesChipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               (matChipInputTokenEnd)="addLanguage($event)"
               [matChipInputAddOnBlur]="false"
            />
         </mat-chip-list>
         <mat-progress-spinner
            *ngIf="languageSpinner$ | async"
            matSuffix
            [diameter]="20"
            mode="indeterminate"
         ></mat-progress-spinner>
         <button
            mat-icon-button
            matSuffix
            appHelperIcon
            [matInput]="languageInput"
            [helperContent]="languageHelper"
            [alwaysVisible]="true"
            ><mat-icon>info_outlined</mat-icon></button
         >
         <ng-template #languageHelper>
            <p>{{ 'studies.language-helper' | translate }}</p>
         </ng-template>
         <mat-autocomplete
            autoActiveFirstOption
            #lang="matAutocomplete"
            (optionSelected)="languageSelected($event)"
         >
            <mat-option *ngFor="let lang of languages$ | async" [value]="lang">
               {{ lang }}
            </mat-option>
         </mat-autocomplete>
         <mat-hint>{{ 'hint' | translate }}</mat-hint>
      </mat-form-field>
   </div>
   <div
      [ngClass]="{
         column: orientation === 'horizontal',
         row: orientation === 'vertical'
      }"
   >
      <mat-form-field appearance="fill">
         <mat-label class="label-color">{{ 'studies.others' | translate }}</mat-label>
         <input
            type="text"
            matInput
            [placeholder]="'placeholder.otherStudy' | translate"
            #othersInput="matInput"
            formControlName="others"
         />
         <button
            mat-icon-button
            matSuffix
            appHelperIcon
            [helperContent]="othersHelper"
            [alwaysVisible]="true"
            ><mat-icon>info_outlined</mat-icon></button
         >
         <ng-template #othersHelper>
            <p>{{ 'studies.others-helper' | translate }}</p>
         </ng-template>
      </mat-form-field>
      <div></div>
   </div>
</form>

<app-stepper-navigator (back)="onBack()" (next)="onSubmit()"></app-stepper-navigator>
