import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { routes } from '../../config/routes.config';
import { BankModel } from '../../models/bank.model';
import { CreateBankDto } from '../../models/dto/bank/createBank.dto';
import { DeleteBankDto } from '../../models/dto/bank/deleteBank.dto';
import { UpdateBankDto } from '../../models/dto/bank/updateBank.dto';
import { DeleteResult } from '../../models/dto/deleteResult.dto';
import { UpdateResult } from '../../models/dto/updateResult.dto';

@Injectable({
   providedIn: 'root',
})
export class BankService {
   private readonly bankUrl: string;

   constructor(private readonly httpCLient: HttpClient) {
      this.bankUrl = routes.bankAccount;
   }

   create(dto: CreateBankDto) {
      return this.httpCLient.post<BankModel>(this.bankUrl, dto);
   }

   update(dto: UpdateBankDto) {
      return this.httpCLient.patch<UpdateResult>(this.bankUrl, dto);
   }

   delete(dto: DeleteBankDto) {
      return this.httpCLient.delete<DeleteResult>(this.bankUrl, {
         body: dto,
      });
   }
}
