import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from 'src/app/config/routes.config';

@Injectable({
   providedIn: 'root',
})
export class EntryService {
   private readonly url: string;

   // eslint-disable-next-line no-unused-vars
   constructor(private httpClient: HttpClient) {
      this.url = routes.registered;
   }

   register(uuid: string): Observable<any> {
      return this.httpClient.post<any>(this.url + '/' + uuid, {});
   }
}
