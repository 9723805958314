import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { routes } from '../config/routes.config';
import { Observable } from 'rxjs';
import { MembershipValidationData } from './interfaces/membership-validation-data.interface';
import { StudentDetails } from './interfaces/student-details.interface';

@Injectable({
   providedIn: 'root',
})
export class SupplementsService {
   private url: string;

   private studentDetailsUrls: string;

   private syncUrl: string;

   constructor(private httpClient: HttpClient) {
      this.url = routes.membershipValidationList;
      this.studentDetailsUrls = routes.membershipValidationStudentDetails;
      this.syncUrl = routes.membershipSynced;
   }

   public listMembershipValidations(): Observable<MembershipValidationData[]> {
      return this.httpClient.get<MembershipValidationData[]>(this.url);
   }

   public getDetailedList(uuid: string, lang: string) {
      return this.httpClient.get<StudentDetails>(`${this.studentDetailsUrls}`, {
         params: {
            uuid,
            lang,
         },
      });
   }

   public markAsRevised(uuid: string) {
      return this.httpClient.post<any>(`${routes.membershipMarkAsRevised}`, { uuid });
   }

   public markAsTrash(uuid: string) {
      return this.httpClient.post<any>(`${routes.membershipMarkAsTrash}`, { uuid });
   }

   public syncronize(data: any) {
      return this.httpClient.post<any>(`${this.syncUrl}`, data);
   }
}
