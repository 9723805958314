import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { routes } from 'src/app/config/routes.config';
import { removeTrailingSpaceFromObjectProps } from '../../shared/helper-functions';

@Injectable({
   providedIn: 'root',
})
export class ContactService {
   private readonly personUrl: string;

   private readonly cityURL: string;

   private readonly zipcode: string;

   private readonly countryURL: string;

   private readonly streetTypeURL: string;

   private readonly addressURL: string;

   constructor(private httpClient: HttpClient) {
      this.personUrl = routes.person;
      this.cityURL = routes.city;
      this.countryURL = routes.country;
      this.streetTypeURL = routes.streetType;
      this.addressURL = routes.address;
      this.zipcode = routes.zipcode;
   }

   getPerson(uuid: string): Observable<any> {
      const url = `${this.personUrl}/${uuid}`;
      return this.httpClient.get<any>(url, {});
   }

   postAddress(params: Object): Observable<any> {
      removeTrailingSpaceFromObjectProps(params);
      return this.httpClient.post<any>(this.addressURL, params);
   }

   patchAddress(params: Object): Observable<any> {
      removeTrailingSpaceFromObjectProps(params);
      return this.httpClient.patch<any>(this.addressURL, params);
   }

   deleteAddress(uuid: string): Observable<any> {
      return this.httpClient.delete<any>(this.addressURL, {
         body: { uuid: uuid },
      });
   }

   getCity(
      search: string,
      lang: string,
   ): Observable<{ city_name: string; zip_number: string; country_name: string }[]> {
      return this.httpClient.get<any>(this.cityURL, {
         params: { name: search, lang, onlyWithZipCode: true },
      });
   }

   getCountry(search: string): Observable<string[]> {
      return this.httpClient.get<any>(this.countryURL, { params: { name: search } });
   }

   getStreetType(search: string, lang: string): Observable<string[]> {
      return this.httpClient.get<any>(this.streetTypeURL, { params: { name: search, lang } });
   }

   getCityForZip(zipCode: string, lang: string) {
      return this.httpClient.get<any>(this.zipcode, { params: { zipCode, lang } });
   }
}
