import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routes } from '../../config/routes.config';
import { ValidationChecks } from './checks';
import { ValidationUploadsService } from './upload-and-confirm.service';

@Component({
   selector: 'app-validation-uploads',
   templateUrl: './upload-and-confirm.component.html',
   styleUrls: ['./upload-and-confirm.component.css'],
})
export class ValidationUploadsComponent implements OnDestroy, OnInit {
   destroyed$ = new Subject<void>();

   @Input() uuid!: string;

   @Input() stepper!: MatStepper;

   @Input() admin!: boolean;

   @Input() download?: boolean;

   @Input() noOfCols!: number;

   @Input() orientation!: StepperOrientation;

   @Input() completed!: BehaviorSubject<boolean>;

   documents!: { uuid: string; fileName: string }[];

   refresh = new EventEmitter();

   uploadSpinner$ = new Subject<boolean>();

   form = new FormGroup({
      file: new FormControl(),
      type: new FormControl('', Validators.required),
   });

   file?: File;

   downloadUrl = routes.membershipValidationDocuments;

   uploadUrl = routes.membershipValidationUpload;

   displayedColumns: string[] = ['docType', 'fileName'];

   fileTooLarge = false;

   checkboxes: {
      active: boolean;
      correctData: boolean;
      attachments: boolean;
   } = {
      active: false,
      correctData: false,
      attachments: false,
   };

   constructor(private service: ValidationUploadsService, private router: Router) {}

   ngOnInit(): void {
      if (this.admin && this.download) {
         this.displayedColumns.push('download');
      }
      if (!this.admin && !localStorage.getItem('jwt')) {
         this.displayedColumns.push('delete');
      }

      this.refresh.subscribe(() => {
         this.service
            .listDocs(this.uuid)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(documents => {
               this.documents = documents;
            });
      });
   }

   onRefresh() {
      this.service
         .listDocs(this.uuid)
         .pipe(takeUntil(this.destroyed$))
         .subscribe(documents => {
            this.documents = documents;
         });
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   onBack() {
      if (this.stepper) {
         this.stepper.previous();
      }
   }

   onNext() {
      this.service.finishApplication(this.uuid).subscribe(() => {
         this.completed.next(true);
         this.router.navigate(['/membership-validation-finished']);
      });
   }

   onFileSelected() {
      const inputNode: any = document.querySelector('#file');
      this.fileTooLarge = false;
      if (typeof FileReader !== 'undefined') {
         const reader = new FileReader();

         reader.readAsArrayBuffer(inputNode.files[0]);

         if (inputNode.files[0].size < 10 * 1024 * 1024) {
            this.file = inputNode.files[0];
         } else {
            this.fileTooLarge = true;
         }
      }
   }

   onCheck(status: boolean, which: keyof typeof ValidationChecks) {
      this.checkboxes[which] = status;
   }

   onEvaluate(): boolean {
      return (
         (this.checkboxes.active &&
            this.checkboxes.correctData &&
            this.checkboxes.attachments &&
            this.documents &&
            this.documents.length > 0) ||
         this.admin
      );
   }
}
