import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonRedirect } from '../shared/enums/redirectDecision';

@Injectable({
   providedIn: 'root',
})
export class HomeService {
   constructor(private httpClient: HttpClient) {}

   getStudentStatus(taxNumber: string): Observable<PersonRedirect> {
      return this.httpClient.get<any>(`api/person/redirect-decision/${taxNumber}`).pipe(
         map(response => {
            return response.status;
         }),
      );
   }
}
