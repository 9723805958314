import { Component, Inject, Optional, TemplateRef } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
   selector: 'app-helper',
   templateUrl: './helper.component.html',
   styleUrls: ['./helper.component.css'],
})
export class HelperComponent {
   content: TemplateRef<any>;

   constructor(
      @Optional() private bottomSheetRef: MatBottomSheetRef<HelperComponent> | null,
      @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) private sheetData: TemplateRef<any> | null,
      @Optional() private dialogRef: MatDialogRef<HelperComponent> | null,
      @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: TemplateRef<any> | null,
   ) {
      if (sheetData) {
         this.content = sheetData!;
      } else {
         this.content = dialogData!;
      }
   }

   dismiss(event: Event) {
      this.dialogRef?.close();
      this.bottomSheetRef?.dismiss();
      event.preventDefault();
   }
}
