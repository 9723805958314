import { Component } from '@angular/core';

@Component({
   selector: 'app-countryside',
   templateUrl: './countryside.component.html',
   styleUrls: ['./countryside.component.css'],
})
export class CountrysideComponent {
   constructor() {}
}
