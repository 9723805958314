<app-uploads
   [uuid]="uuid"
   [stepper]="stepper"
   [noOfCols]="noOfCols"
   [orientation]="orientation"
   [admin]="admin"
   [documents]="documents"
   [uploadUrl]="uploadUrl"
   [downloadUrl]="downloadUrl"
   (refresh)="onRefresh()"
   [loadingSpinner]="uploadSpinner$"
></app-uploads>
<form style="margin-top: 2vh">
   <h2>{{ 'entry.validation-prefix' | translate }}</h2>
   <ul>
      <li>
         <mat-checkbox #matCheckBox (change)="onCheck($event.checked, 'active')">
            {{ 'entry.validation-active' | translate }}
         </mat-checkbox>
      </li>
      <li>
         <mat-checkbox #matCheckBox (change)="onCheck($event.checked, 'correctData')">
            {{ 'entry.validation-correct-data' | translate }}
         </mat-checkbox>
      </li>
      <li>
         <mat-checkbox #matCheckBox (change)="onCheck($event.checked, 'attachments')">
            {{ 'entry.validation-attachments' | translate }}
         </mat-checkbox>
      </li>
   </ul>
</form>

<app-stepper-navigator
   *ngIf="!admin"
   [nextDisabled]="!onEvaluate()"
   (back)="onBack()"
   (next)="onNext()"
></app-stepper-navigator>
