import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
   selector: 'app-info',
   templateUrl: './info.component.html',
   styleUrls: ['./info.component.css'],
})
export class InfoComponent {
   constructor(private router: Router) {
      if (!AppComponent.homeFormData?.taxNumber) {
         this.router.navigate(['']);
      }
   }

   onNext() {
      this.router.navigate(['register']);
   }
}
