import { Observable, Subject } from 'rxjs';

export function distinctUntilChangedAndNotify<T, K>(subject: Subject<K>, notifyValue: K) {
   return (observable: Observable<T>) =>
      new Observable<T>(subscriber => {
         let first = true;
         let prev: T;
         const subscription = observable.subscribe({
            next(value) {
               // notify subject
               subject.next(notifyValue);

               if (first || prev !== value) {
                  first = false;
                  prev = value;
                  subscriber.next(value);
               }
            },

            error(err) {
               subscriber.error(err);
            },

            complete() {
               subscriber.complete();
            },
         });
         return () => {
            subscription.unsubscribe();
         };
      });
}
