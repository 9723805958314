import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { routes } from 'src/app/config/routes.config';
import { PaymentStatus, PersonModel } from '../models/person.model';

@Injectable({
   providedIn: 'root',
})
export class RegistersService {
   private readonly personUrl: string;

   constructor(private httpClient: HttpClient) {
      this.personUrl = routes.person;
   }

   getPerson(uuid?: string, language?: 'HU' | 'EN'): Observable<any> {
      const url = uuid ? `${this.personUrl}/${uuid}` : `${this.personUrl}`;
      return this.httpClient.get<PersonModel>(url, { params: { language: language || '' } });
   }

   getPaymentStatus(uuid: string): Observable<PaymentStatus> {
      return this.httpClient.get<any>(`api/person/payment-status/${uuid}`).pipe(
         map(response => {
            return response.paymentStatus;
         }),
      );
   }
}
