import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
   MatSnackBar,
   MatSnackBarHorizontalPosition,
   MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
   destroyed$ = new Subject<void>();

   loginForm: FormGroup;

   url = environment.url;

   horizontalPosition: MatSnackBarHorizontalPosition = 'center';

   verticalPosition: MatSnackBarVerticalPosition = 'top';

   constructor(
      private service: LoginService,
      private _snackBar: MatSnackBar,
      private router: Router,
   ) {
      this.loginForm = new FormGroup({
         username: new FormControl('', [Validators.required, Validators.minLength(5)]),
         password: new FormControl('', Validators.required),
      });
   }

   ngOnInit() {
      if (localStorage.getItem('jwt')) {
         this.router.navigateByUrl('admin');
      }
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   onLogin() {
      const params = { ...this.loginForm.value, url: this.url };
      this.service
         .post(params)
         .pipe(takeUntil(this.destroyed$))
         .subscribe(
            res => {
               localStorage.setItem('jwt', res.token);
               localStorage.setItem('resources', res.resources);
               localStorage.setItem(
                  'exp',
                  JSON.parse(atob((res.token as string).split('.')[1])).exp,
               );
               this.router.navigateByUrl('admin');
            },
            err => {
               console.log(err);
               if (err.error.error) {
                  this._snackBar.open(
                     'HTTP Error: ' + err.error.error + ': ' + err.error.message[0],
                     'Ok',
                     {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 5000,
                     },
                  );
               } else {
                  this._snackBar.open('HTTP Error: ' + err.error.message, 'Ok', {
                     horizontalPosition: this.horizontalPosition,
                     verticalPosition: this.verticalPosition,
                     duration: 5000,
                  });
               }
            },
         );
   }
}
