import { Component, OnDestroy, OnInit } from '@angular/core';
import {
   AbstractControl,
   FormControl,
   FormGroup,
   ValidationErrors,
   Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { checkStringIsDate } from '../shared/date-helper';
import { PersonRedirect } from '../shared/enums/redirectDecision';
import { MediaService } from '../shared/services/media.service';
import { HomeService } from './home.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
   destroyed$ = new Subject<void>();

   currentLang = 'hu';

   startDate = new Date('2000.01.01');

   touchUi$ = new BehaviorSubject(false);

   form = new FormGroup({
      taxNumber: new FormControl('', [Validators.required, this.taxnumberValidator.bind(this)]),
      dateOfBirth: new FormControl('', Validators.required),
   });

   constructor(
      private router: Router,
      public translate: TranslateService,
      private mediaService: MediaService,
      private homeService: HomeService,
      private snackBar: MatSnackBar,
   ) {
      this.mediaService.screen$
         .pipe(
            takeUntil(this.destroyed$),
            map(screen => {
               return (
                  screen === 'mobileM' ||
                  screen === 'phoneLandscape' ||
                  screen === 'phonePortrait' ||
                  screen === 'tabletPortrait'
               );
            }),
         )
         .subscribe(this.touchUi$);
   }

   ngOnInit() {
      AppComponent.homeFormData = undefined;
      this.currentLang = this.translate.currentLang;
   }

   ngOnDestroy(): void {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   onNext() {
      AppComponent.homeFormData = this.form.value;
      this.homeService.getStudentStatus(this.form.value.taxNumber).subscribe({
         next: status => {
            if (status === PersonRedirect.REGISTER) {
               this.router.navigate(['info']);
            }  else if (
               status === PersonRedirect.MEMBERSHIP_VALIDATION ||
               status === PersonRedirect.DATA_SUPPLEMENTATION
            ) {
               this.router.navigate(['membership-validation-info']);
            } else {
               this.router.navigate(['countryside']);
            }
         },
         error: () => {
            this.snackBar.open(this.translate.instant('generalError'), 'OK', {
               duration: 5000,
            });
         },
      });
   }

   setLang() {
      if (this.currentLang === 'en') {
         this.translate.use('hu');
         this.currentLang = 'hu';
      } else {
         this.translate.use('en');
         this.currentLang = 'en';
      }
   }

   dateOfBirtOnChange($event: any) {
      const result = checkStringIsDate($event);
      if (result) {
         this.form.controls.dateOfBirth.setValue(result);
      }
   }

   taxnumberValidator(control: AbstractControl): ValidationErrors | null {
      let errors: any = null;

      const value: string | null = control.value;
      if (!value) {
         return errors;
      }

      const length = value.length;

      if (length > 0 && value.charAt(0) !== '8') {
         errors = { ...errors, leadingEight: true };
      }

      if (length > 6) {
         const days = +value.slice(1, 6);
         const bod: Date = new Date(this.form.controls.dateOfBirth.value);
         bod.setHours(0);
         bod.setUTCMinutes(-bod.getTimezoneOffset(), 0, 0);

         const referenceDate = new Date('1867-01-01');
         const diffInDays = (bod.getTime() - referenceDate.getTime()) / 86400000;

         if (diffInDays !== days) {
            errors = { ...errors, badDays: true };
         }
      }

      if (length === 10) {
         const checkSum = [...value.slice(0, -1)].reduce((acc, val, i) => {
            return acc + (i + 1) * +val;
         }, 0);

         if (checkSum % 11 !== +value.charAt(9)) {
            errors = { ...errors, badCheckSum: true };
         }
      } else {
         errors = { ...errors, length: true };
      }

      return errors;
   }
}
